
export const surveyDemo = {
    pages: [{
        name: "demographic",
        elements: [
            {
                name: "Language",
                title: "Language:",
                description: 'Native or fluent English/Spanish/Portuguese speakers',
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
               // defaultValue: "No"
                // showClearButton: true
            },
            {
                name: "Birth Date",
                title: "Birth Date:",
                description: "Date of participant's birth",
                type: "text",   
                inputType: "date", 
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",  
               // defaultValue: "2022-01-01"  
            },
            {
                name: "Sex",
                title: "Sex:",
                description: 'Sex - biological variable',
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Female",
                    "Male",
                ],
                // showClearButton: true
            },
            {
                name: "Education",
                title: "Education:",
                description: 'Level of education as a research variable including vocational',
                type: "dropdown",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "01",
                    "02",
                    "03",
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "09",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20+"
                ],
                optionsCaption: "NN"

            },
            {
                name: "Marital Status",
                title: "Marital Status:",
                description: "Variable that describes a person's relationship with a significant other",
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Single",
                    "Engaged",
                    "Common law Partner",
                    // Needs more choices
                    "..."
                ],
            },
            {
                name: "Number of Children",
                title: "Number of Children:",
                type: "dropdown",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "0",
                    "1",
                    "2",
                    "3",
                    "4",
                    "5"
                ],
            },
            {
                name: "Age of the first child",
                title: "Age of the first child",
                type: "text",    
                inputType: "number",        
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                min: 0,
                max: 99,
                visibleIf: "{Number of Children} > 0",
            },
            {
                name: "Age of the second child",
                title: "Age of the second child",
                type: "text",    
                inputType: "number",        
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                min: 0,
                max: 99,
                visibleIf: "{Number of Children} > 1",
            },
            {
                name: "Age of the third child",
                title: "Age of the third child",
                type: "text",    
                inputType: "number",        
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                min: 0,
                max: 99,
                visibleIf: "{Number of Children} > 2",
            },
            {
                name: "Age of the fourth child",
                title: "Age of the fourth child",
                type: "text",    
                inputType: "number",        
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                min: 0,
                max: 99,
                visibleIf: "{Number of Children} > 3",
            },
            {
                name: "Age of the fifth child",
                title: "Age of the fifth child",
                type: "text",    
                inputType: "number",        
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                min: 0,
                max: 99,
                visibleIf: "{Number of Children} > 4",
            },
            {
                name: "Ocupation",
                title: "Ocupation:",
                description: 'Variable that describes the ocupation of the participant',
                type: "text",                    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
            },
            {
                name: "Caregivers",
                title: "Caregivers:",
                description: 'Caregiver residing with participant, paid caregiver or other caregiver',
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "Employment Status",
                title: "Employment Status:",
                type: "dropdown",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Employed - full-time position",
                    "Employed - part-time position",
                    "Self-employed",
                    "Unemployed",
                    "Retired",
                    "Housewike"
                ],
                optionsCaption: "Select"

            },
        ]
    }], 
    showQuestionNumbers: "on",
    cookieName: "demographic",
}


export const surveyClinical = {
    pages: [{
        name: "clinical",
        elements: [
            {
                name: "Carcer survivor",
                title: "Carcer survivor:",
                description: 'Is participant cancer-free? (no clinical evidence of currently suffering cancer)',
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "Tumoral type and stage",
                title: "Tumoral type and stage:",
                description: 'Diagnosis that applies to the participant. Disease stage refers to AJCC/UICC eighth edition.',
                type: "dropdown",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "HR + HER2 - BC",
                    "HR + HER2 + BC",
                    "HR - HER2 + BC",
                    "Stages I-IIIA",
                    "Stages I-IIIB"
                ],
                optionsCaption: "Select"
            },
            {
                name: "Stage",
                title: "Stage:",
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Stage I",
                    "Stage II",
                    "Stage III",
                ],
                visibleIf: "{Tumoral type and stage} notempty",
            },
            {
                name: "Specific Treatment",
                title: "Specific Treatment:",
                description: 'Disease-specific treatments, to reduce the minimal risk of recurrence or relapse, are accepted according to each cancer subtype',
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Chemotherapy",
                    "Hormonal therapy",
                    "Immunotherapy",
                ],

            },
            {
                name: "Completed Treatment",
                title: "Completed Treatment:",
                description: 'Has the participant completed treatment with curative intent (chemotherapy, radiotherapy or surgery with curative purposes treatment) and in follow up arter first year of treatments and less than 5 years( i. e., between the second and fifth year of follow-up)?',
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "Performance Status (PS)",
                title: "Performance Status (PS):",
                description: 'Performance status as measured by the ECOG performance status skill',
                type: "dropdown",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "NN",
                    "1",
                    "2",
                    "3",
                    "4",
                ],
                optionsCaption: "NN"
            },
            {
                name: "Presence of distant metastases",
                title: "Presence of distant metastases:",
                description: 'Has the participant ever been diagnosed with distant metastases?',
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "Presence of inflammatory breast cancer",
                title: "Presence of inflammatory breast cancer:",
                description: 'Has the participant ever been diagnosed with inflammatory breast cancer?',
                type: "radiogroup",                    clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "Previous invasive malignacies",
                title: "Previous invasive malignacies:",
                description: 'Prior invasive malignance whose treatment was completed within 5 years before study entry.',
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "Acute illness",
                title: "Acute illness:",
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "Concomitant diseases",
                title: "Concomitant diseases:",
                description: 'Pattient affected by any known or underlying medical conditions that, could adversely affect the ability of the participating subject to comply with the study? serious other diagnosed concomitant diseases such as clinically significant (i.e. active) cardiac disease (e.g. congestive heart failure, symptomatic coronary artery disease or cardiac arrhythmia not well controlled with medication) or myocardial infarction within the last 12 months.',
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "Pregnancy or breastfeeding",
                title: "Pregnancy or breastfeeding:",
                description: 'Pregnancy or breastfeeding at time of recruitment.',
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "Psychiatric comorbidities",
                title: "Psychiatric comorbidities:",
                description: 'Any psychiatric comorbidity according to the M.I.N.I. (diagnosis) or HAM-D (severity) present at baseline. For example, the diagnosis of a moderate to severe major depressive episode.',
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "Previous hypomanic/manic",
                title: "Previous hypomanic/manic:",
                description: 'Current or previous hypomanic or manic episode, current or previous psychotic disorder or current mood disorder with psychotic symptoms as screened by the M.I.N.I..',
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "Psychiatric disorders",
                title: "Psychiatric disorders:",
                description: 'Any psychiatric disorder, other than the ones screened by MINI, requiring urgent care or hospitalization at baseline.',
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "Substance dependence",
                title: "Substance dependence:",
                description: 'Substance abuse or dependence in the last 12 months as screened by the M.I.N.I..',
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "Dementia",
                title: "Dementia:",
                description: 'Dementia or other active neurodegenerative disease.',
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "Central nervous system structural lesion",
                title: "Central nervous system structural lesion:",
                description: 'Previously known structural lesion of the central nervous system.',
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "Developmental Disorders",
                title: "Developmental Disorders:",
                description: 'Developmental disorders with low intelligence quotient or any other form of cognitive impairment.',
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "Baseline height",
                title: "Baseline height:",
                description: 'Between 0,5 m and 2,3 m (error if less than 0,5 m or more than 2,3 m).',
                type: "text",    
                inputType: "number",        
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                min: 0.5,
                max: 2.3
            },
            {
                name: "Baseline weight",
                title: "Baseline weight:",
                description: 'Between 25 Kg and 200 Kg (error if less than 25 kg or more than 200 kg).',
                type: "text",    
                inputType: "number",        
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                min: 25,
                max: 200
            },
            {
                name: "Baseline BMI",
                title: "Baseline BMI: {fullname}",
                description: 'The body mass index (BMI) is a measure that uses the height and weight to work out if a person’s weight is healthy. The BMI calculation divides an adult\'s weight in kilograms by their height in metres squared. The BMI ranges are: ?  BMI < 18.5 – Underweight ?  18.5 = BMI < 25 – Normal weight ?  25 = BMI < 30 – Overweight.',
                type: "text",    
                //inputType: "number",        
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                min: 0,
    	        defaultValue: "{fullname}",
                visibleIf: "{Baseline weight} > 0 and {Baseline height} > 0"

            },
            {
                name: "Smoking status",
                title: "Smoking status:",
                description: 'Status for smoking as categorical variable.',
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Current",
                    "Former",
                    "Never",
                    "Unknown"
                ],
            },
            {
                name: "Smoking habits",
                title: "Smoking habits:",
                description: 'Type of smoking behaviour.',
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Cigarettes",
                    "Cigar/ pipes",
                    "Betel quid",
                    "Smokeless (spit) Tocacco"
                ],
                visibleIf: "{Smoking status} = 'Current' or {Smoking status} = 'Former'"
            },
            {
                name: "Cigarettes a day",
                title: "Cigarettes a day:",
                description: 'Number of cigarrets /packages of cigarrets/related consumed on one day. 1 pack = numbers per Day/20 for cigarettes or /4 for cigars.',
                type: "text",    
                inputType: "number",        
                min: 0,
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                visibleIf: "{Smoking status} = 'Current' or {Smoking status} = 'Former'"
            },
            {
                name: "Years as a smoker",
                title: "Years as a smoker:",
                description: 'Total number of years since starting smoking.',
                type: "text",    
                inputType: "number",        
                min: 0,
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                visibleIf: "{Smoking status} = 'Current' or {Smoking status} = 'Former'",
            },
            {
                name: "Plans on quitting smoking",
                title: "Plans on quitting smoking:",
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{Smoking status} = 'Current' or {Smoking status} = 'Former'"
            },
            {
                name: "Alcohol consumption",
                title: "Alcohol consumption:",
                description: 'An indication of a person\'s current alcohol consumption as well as some indication of alcohol consumption history.',
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Current",
                    "Former",
                    "Never",
                    "Unknown"
                ],
            },
            {
                name: "Amount of alcohol consumed",
                title: "Amount of alcohol consumed:",
                description: 'Refers to the standard units(liters) of alcohol consumed in a week.',
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Known",
                    "Unknown",
                ],
                visibleIf: "{Alcohol consumption} = 'Current'"

            },
            {
                name: "Amount",
                title: "Amount:",
                type: "text",    
                inputType: "number",        
                min: 0,
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                visibleIf: "{Amount of alcohol consumed} = 'Known'"
            },
            {
                name: "Date of diagnosis",
                title: "Date of diagnosis:",
                description: 'Date of diagnosis. It refers to the very first point in participant\'s history.',
                type: "text",    
                inputType: "date",        
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
            },
            {
                name: "Age of diagnosis",
                title: "Age of diagnosis:",
                description: "Age of the participant when he/she was diagnosed with lung/breast cancer.",
                type: "text",    
                inputType: "number",        
                min: 0,
                max: 200,
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
            },
            {
                name: "Date of first treatment",
                title: "Date of first treatment:",
                description: 'Date of diagnosis in which first treatment for first pathological procedure started. It refers to the very first point in participant’s history.',
                type: "text",    
                inputType: "date",        
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
            },
            {
                name: "Node afectation",
                title: "Node afectation:",
                description: 'Node afectation: SLNB PRE CHEMO Details. Done/pos=1; Done/neg=2; Done/No value=3; Not Done =4;   Not Done/PAAF-BAG negative= 5; Not Done/PAAF-BAG positive= 6;No available=N/A).',
                type: "dropdown",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "N/A",
                ],
                optionsCaption: "Select"

            },
            {
                name: "Neoadjuvant (NAD) treatment",
                title: "Neoadjuvant (NAD) treatment:",
                description: 'Neoadjuvant (NAD) Cancer treatment: Chemotherapy, Hormone therapy.',
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Chemotherapy",
                    "Hormone therapy",
                ],
            },
            {
                name: "Date of last NAD treatment",
                title: "Date of last NAD treatment:",
                type: "text",    
                inputType: "date",        
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
            },
            {
                name: "Surgery",
                title: "Surgery:",
                description: 'Type of surgery if any.',
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Breast",
                    "Lung",
                    "N/A",
                ],
            },
            {
                name: "Breast surgery",
                title: "Breast surgery:",
                type: "dropdown",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Segmentectomy",
                    "Mastectomy",
                    "NON",
                ],
                optionsCaption: "Select",
                visibleIf: "{Surgery} = 'Breast'"
            },
            {
                name: "Lung surgery",
                title: "Lung surgery:",
                type: "dropdown",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Lobectomy with mediastinal lymph node dissection",
                    "Pneumonectomy with mediastinal lymph node dissection",
                ],
                optionsCaption: "Select",
                visibleIf: "{Surgery} = 'Lung'"
            },
            {
                name: "Type of axillary surgery",
                title: "Type of axillary surgery:",
                description: "Type of axillary surgery if any. 1=SLNB only, 2=SLNB+ALND, 3=ALND only, 4=no axillary surgery.",
                type: "dropdown",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "N/A",
                ],
                optionsCaption: "NN",
            },
            {
                name: "Date of surgery",
                title: "Date of surgery:",
                type: "text",    
                inputType: "date",        
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                visibleIf: "{Surgery} anyof ['Breast', 'Lung'] or {Type of axillary surgery} anyof ['1', '2', '3', '4']"
            },
            {
                name: "Tumor regression after last treatment",
                title: "Tumor regression after last treatment:",
                description: "This is the measure for response to the treatment (Residual Cancer Burden).",
                type: "text",    
                inputType: "number",        
                min: 0,
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
            },
            {
                name: "Adjuvant Cancer treatment",
                title: "Adjuvant Cancer treatment:",
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Chemotherapy",
                    "Hormone therapy",
                    "Radiotherapy",
                ],
            },
            {
                name: "Date last treatment",
                title: "Date last treatment:",
                description: "Last date treatment completion for lung / Last AD treatment.",
                type: "text",    
                inputType: "date",        
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
            },
            {
                name: "Date of last treatment outcome",
                title: "Date of last treatment outcome:",
                description: "Date when the participant received the last treatment outcome.",
                type: "text",    
                inputType: "date",        
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
            },
            {
                name: "Concomitant medications",
                title: "Concomitant medications:",
                type: "dropdown",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Level 1",
                    "Level 2",
                    "Level 3",
                    "Level 4",
                    "Level 5",
                    "Other"
                ],
                hasComment: true,
                optionsCaption: "-",
            },
            {
                name: "Cancer family history",
                title: "Cancer family history:",
                type: "checkbox",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Type 1",
                    "Type 2",
                    "Type 3",
                    "Other",
                ],
                hasComment: true,
                optionsCaption: "-",
            },
            {
                name: "Comorbidities",
                title: "Comorbidities:",
                description: "This variable will include general comorbilities like some chronic diseases diabetes, hypertension, Accute illness, Concommitant diseasees) and inlcude acute diseases.",
                type: "checkbox",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Type 1",
                    "Type 2",
                    "Type 3",
                    "Other",
                ],
                hasComment: true,
                optionsCaption: "-",
            },
            {
                name: "Physical activity level",
                title: "Physical activity level:",
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Sedentary (little or no exercise)",
                    "Lightly active (light exercise/ sports 1-2 days/ week)",
                    "Moderately active (moderate exercise/ sports 3-5 days/ week)",
                    "Very active (hard exercise/ sports 6-7 days/ week)",
                ],
            },
            {
                name: "Minimal weight in adult life",
                title: "Minimal weight in adult life:",
                description: "Excluded minimal weight achieved during cancer treatments.",
                type: "text",    
                inputType: "number",        
                min: 0,
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
            },
            {
                name: "Maximal weight in adult life",
                title: "Maximal weight in adult life:",
                description: "Excluding pregnancy.",
                type: "text",    
                inputType: "number",        
                min: 0,
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
            },
            {
                name: "Insomnia",
                title: "Insomnia:",
                description: "Frequency with wich the patient suffers from insomnia.",
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Never",
                    "Hardly ever",
                    "Sometimes",
                    "Frequently",
                    "Always",
                ],
            },
            {
                name: "Food intolerances",
                title: "Food intolerances:",
                description: "Diagnosed food intolerances.",
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "Which food intolerances",
                title: "Which food intolerances:",
                type: "checkbox",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Lactose intolerance",
                    "Gluten intolerance/ sensitivity",
                    "Histamine intolerance",
                    "Other",
                ],
                hasComment: true,
                visibleIf: "{Food intolerances} = 'Yes'"
            },
            {
                name: "Planned and unplanned contacts",
                title: "Planned and unplanned contacts:",
                description: "It refers to a planned/unplanned contacts with the participant.",
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Phone call",
                    "Visit",
                    "Unplanned",
                ],
            },
            {
                name: "Date of follow-up contact",
                title: "Date of follow-up contact:",
                description: "Date of follow-up contact (phone calls / unplanned visits / etc).",
                type: "text",    
                inputType: "date",        
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
            },
            {
                name: "Recurrence",
                title: "Recurrence:",
                description: "Confirmed recurrence by unequivocal radiological confirmation or histological confirmation; no clinical suspicion.",
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "Date of recurrence",
                title: "Date of recurrence:",
                description: "Date of radiological or histological confirmation.",
                type: "text",    
                inputType: "date",        
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                visibleIf: "{Recurrence} = 'Yes'"
            },
            {
                name: "Type of recurrence",
                title: "Type of recurrence:",
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Local recurrence",
                    "Distant recurrence",
                    "Second cancer",
                    "Combination",
                ],
                visibleIf: "{Recurrence} = 'Yes'"
            },
            {
                name: "Non-cancer-related new medical event",
                title: "Non-cancer-related new medical event:",
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                    "Other"
                ],
                hasComment: true
            },
            {
                name: "Type of new non-cancer-related medical event",
                title: "Type of new non-cancer-related medical event:",
                type: "checkbox",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Type 1",
                    "Type 2",
                    "Type 3",
                    "Other",
                ],
                hasComment: true,
                visibleIf: "{Non-cancer-related new medical event} = 'Yes'"
            },
            {
                name: "Grade adverse event",
                title: "Grade adverse event:",
                description: "Grade according to CTCAE.",
                type: "dropdown",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "G1",
                    "G2",
                    "G3",
                    "G4",
                    "G5",
                ],
                optionsCaption: "-",
                visibleIf: "{Non-cancer-related new medical event} = 'Yes'"
            },
            {
                name: "Date of onset of new non-cancer-related medical event",
                title: "Date of onset of new non-cancer-related medical event:",
                type: "text",    
                inputType: "date",        
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                visibleIf: "{Non-cancer-related new medical event} = 'Yes'"
            },
            {
                name: "Date of resolution of New non-cancer-related medical event",
                title: "Date of resolution of New non-cancer-related medical event:",
                type: "text",    
                inputType: "date",        
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                visibleIf: "{Non-cancer-related new medical event} = 'Yes'"
            },
            {
                name: "Nutrition therapy",
                title: "Nutrition therapy:",
                description: "Nutrition therapy. Currently are you following a specific dietary intervention prescribed by a dietician?",
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "Which nutrition therapy",
                title: "Which nutrition therapy:",
                type: "checkbox",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Low-calorie diet",
                    "Low-calorie and low fat diet",
                    "Low-calorie and low carbohydrate diet",
                    "High protein and high fat diet",
                    "Low Fiber Diet/ Salt-restricted (low sodium) diet",
                    "Low cholesterol diet",
                    "Vegetarian diet",
                    "High calorie diet (for weight gain)",
                    "Other",
                ],
                hasComment: true,
                visibleIf: "{Nutrition therapy} = 'Yes'"
            },
            {
                name: "Diseases associated with Overweight & Obesity",
                title: "Diseases associated with Overweight & Obesity:",
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "Which disease(s)",
                title: "Which disease(s):",
                type: "checkbox",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Type 2 diabetes",
                    "Hypertension",
                    "High blood pressure",
                    "Heart disease",
                    "Sleep apnea",
                    "Fatty liver diseases (nonalcoholic fatty liver disease (NAFLD) or nonalcoholic steatohepatitis (NASH))",
                    "Osteoarthritis",
                    "Gallbladder diseases (gallstones or  cholecystitis)",
                    "Kidney disease",
                    "Other",
                    
                ],
                hasComment: true,
                visibleIf: "{Diseases associated with Overweight & Obesity} = 'Yes'"
            },
            {
                name: "Osteoporosis",
                title: "Osteoporosis:",
                description: "Diagnosed Osteoporosis.",
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "Pain",
                title: "Pain:",
                description: "Experience Pain more than usual",
                type: "dropdown",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Never",
                    "Hardly ever",
                    "Sometimes",
                    "Frequently",
                    "Always",
                ],
                optionsCaption: "-",
            },
            {
                name: "Hot flushes",
                title: "Hot flushes:",
                description: "Experience Hot flushes more than usual",
                type: "dropdown",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Never",
                    "Hardly ever",
                    "Sometimes",
                    "Frequently",
                    "Always",
                ],
                optionsCaption: "-",
            },
            {
                name: "Snoring",
                title: "Snoring:",
                description: "Snoring frequency.",
                type: "dropdown",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Never",
                    "Hardly ever",
                    "Sometimes",
                    "Frequently",
                    "Always",
                ],
                optionsCaption: "-",
            },
            {
                name: "Sweating",
                title: "Sweating:",
                description: "Experience sweating more than usual",
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                ],
            },
        ],
        calculatedValues: [
            {
            name: "fullname",
            expression: "'t' {Baseline weight}"
            }
        ]
    }], 
    showQuestionNumbers: "on",
    cookieName: "clinical",
}

export const surveyMiniA = {
    pages: [{
        name: "surveyMiniA",
        elements: [
            {
                name: "A1-a",
                title: "Where you ever depressed or down, or did you feel sad, empty or hopeless, most of the day, nearly everyday, for two weeks?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "A1-b",
                title: "For the past two weeks, were you depressed or down, or did you feel sad, empty or hopeless, most of the day, nearly everyday?",
                type: "radiogroup",               
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{A1-a} = 'Yes'"
            },
            {
                name: "A2-a",
                title: "Were you ever much less interested in most things or much less able to enjoy the things you used to enjoy most of the time, for two weeks?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "A2-b",
                title: "In the past two weeks, were you much less interested in most things or much less able to enjoy the things you used to enjoy most of the time?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{A2-a} = 'Yes'"
            },
            {
                name: "A3-a",
                title: "Was your appetite decreased or increased nearly every day? Did your weight decrease or increase without trying intentionally (i.e., by ±5% of body weight or ±8 lb or ± 3.5 kg, for a 160 lb/70 kg person in a month)?",
                description: "Explore the current and the most symptomatic past episode, over that two-week period, when you felt depressed or uninterested.",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{A1-b} = 'Yes' or {A2-b} = 'Yes'"
            },
            {
                name: "A3-a",
                title: "Was your appetite decreased or increased nearly every day? Did your weight decrease or increase without trying intentionally (i.e., by ±5% of body weight or ±8 lb or ± 3.5 kg, for a 160 lb/70 kg person in a month)?",
                description: "Explore only the most symptomatic past episode, over that two-week period, when you felt depressed or uninterested.",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{A1-b} = 'No' and {A2-b} = 'No'"
            },
            {
                name: "A3-b",
                title: "Did you have trouble sleeping nearly every night (difficulty falling asleep, waking up in the middle of the night, early morning wakening or sleeping excessively)?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "({A1-b} = 'No' and {A2-b} = 'No') or ({A1-b} = 'Yes' or {A2-b} = 'Yes')"
            },
            {
                name: "A3-c",
                title: "Did you talk or move more slowly than normal or were you fidgety, restless or having trouble sitting still almost every day? Did anyone notice this?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "({A1-b} = 'No' and {A2-b} = 'No') or ({A1-b} = 'Yes' or {A2-b} = 'Yes')"
            },
            {
                name: "A3-d",
                title: "Did you feel tired or without energy almost every day?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "({A1-b} = 'No' and {A2-b} = 'No') or ({A1-b} = 'Yes' or {A2-b} = 'Yes')"
            },
            {
                name: "A3-e",
                title: "Did you feel worthless or guilty almost every day?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "({A1-b} = 'No' and {A2-b} = 'No') or ({A1-b} = 'Yes' or {A2-b} = 'Yes')"
            },
            {
                name: "A3-e1",
                title: "Give some examples regarding the last question.",
                description: "Look for delusions of failure, od inadequacy, of ruin or of guilt, or of needing punishment or delusions od disease por death or nihilistic or somatic delusions.",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Current episode",
                    "Past episode",
                ],
                hasComment: true,
                commentText: "Examples",
                visibleIf: "({A3-e} = 'Yes'"
            },
            {
                name: "A3-f",
                title: "Did you have difficulty concentrating, thinking or making decisions almost every day?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "({A1-b} = 'No' and {A2-b} = 'No') or ({A1-b} = 'Yes' or {A2-b} = 'Yes')"
            },
          
            {
                name: "A3-g",
                title: "Did you repeatedly think about death or have any thoughts of killing yourself, or have any intent or plan to kill yourself? Did you attempt suicide?",
                description: "Fear of dying does not count here. If yes to either, code yes.",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "({A1-b} = 'No' and {A2-b} = 'No') or ({A1-b} = 'Yes' or {A2-b} = 'Yes')"
            },
            {
                name: "A4",
                title: "Did these symptoms cause significant distress or problems at home, at work, at school, socially, in your relationships, or in some other important way, and are they a change from your previous functioning?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "({A1-b} = 'No' and {A2-b} = 'No') or ({A1-b} = 'Yes' or {A2-b} = 'Yes')"
            },
            {
                name: "A5",
                title: "In between 2 episodes of depression, did you ever have an interval of at least 2 months, without any significant depression or any significant loss of interest?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                    "N/A",
                ],
                visibleIf: "({A1-b} = 'No' and {A2-b} = 'No') or ({A1-b} = 'Yes' or {A2-b} = 'Yes')"
            },
            // {
            //     name: "A6",
            //     title: "Major depressive episode:",
            //     type: "radiogroup",    
            //     choices: [
            //         "Current",
            //         "Past",
            //         "Recurrent",
            //     ],                
            //     clearIfInvisible: "onHidden",
            //     isRequired: false,
            //     requiredErrorText: "*this is required.",
            //     visibleIf: "({A1-b} = 'No' and {A2-b} = 'No') or ({A1-b} = 'Yes' or {A2-b} = 'Yes')"
            // },
            {
                name: "A6",
                title: "How many episodes of depression did you have in your lifetime?",
                description: "Between each episode there must be at least 2 months without any significant depression.",
                type: "text",    
                inputType: "number", 
                min: "0",                  
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                visibleIf: "({A1-b} = 'No' and {A2-b} = 'No') or ({A1-b} = 'Yes' or {A2-b} = 'Yes')"
            },    
            // Diagnostic box
        
        ]
    }], 
    showQuestionNumbers: "on",
    cookieName: "a",
}


export const surveyMiniB = {
    pages: [{
        name: "surveyMiniB",
        elements: [
            {
                name: "B1",
                title: "Have any accident? This includes taking too much of your medication accidentally.",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "B1-a",
                title: "Plan or intend to hurt yourself in any accident, either by not avoiding a risk or by causing the accident on purpose?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{B1} = 'Yes'"
            },
            {
                name: "B1-b",
                title: "Intend to die as a result of any accident?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{B1-a} = 'Yes'"
            },
            {
                name: "B2",
                title: "Think (even momentarily) that you would be better off dead or wish you were dead or needed to be dead?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "B3",
                title: " Think (even momentarily) about harming or of hurting or of injuring yourself: - with at least some intent or awareness that you might die as a result; - or think about suicide (i.e. about killing yourself)?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "B3-a",
                title: "What is the frequency of these thoughts?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Occasionally",
                    "Often",
                    "Very often",
                ],
                visibleIf: "{B2} = 'Yes' or {B3} = 'Yes'"
            },
            {
                name: "B3-b",
                title: "What is the intensity of these thoughts?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Mild",
                    "Moderate",
                    "Severe",
                ],
                visibleIf: "{B2} = 'Yes' or {B3} = 'Yes'"
            },
            {
                name: "B4",
                title: "Hear a voice or voices telling you to kill yourself or have a dream with any suicidal content?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "B4-a",
                title: "Specify, regarding to the previous answer?",
                type: "checkbox",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "A voice/ voices",
                    "A dream",
                ],
                visibleIf: "{B4} = 'Yes'"
            },
            {
                name: "B5",
                title: "Have a suicide method in mind?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                hasComment: true,
                commentText: "Specify (i.e. how)",
            },
            {
                name: "B6",
                title: "Have a suicide means in mind?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                hasComment: true,
                commentText: "Specify (i.e. with what)",
            },
            {
                name: "B7",
                title: "Have any place in mind to attempt suicide?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                hasComment: true,
                commentText: "Specify (i.e. where)",
            },
            {
                name: "B8",
                title: "Have any date/timeframe in mind to attempt suicide?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                hasComment: true,
                commentText: "Specify (i.e. when)",
            },
            {
                name: "B9",
                title: "Think about any task you would like to complete before trying to kill yourself?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                hasComment: true,
                commentText: "Specify (e.g. writing a suicide note)",
            },
            {
                name: "B10",
                title: "Intend to act on thoughts of killing yourself?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "B10-a",
                title: "When did the participant intend to act?",
                type: "checkbox",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "At the time",
                    "At some time in the future",
                ],
                visibleIf: "{B10} = 'Yes'"
            },
            {
                name: "B11",
                title: "Intend to die as a result of a suicidal act?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "B11-a",
                title: "When did the participant intend to die by suicide?",
                type: "checkbox",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "At the time",
                    "At some time in the future",
                ],
                visibleIf: "{B11} = 'Yes'"
            },
            {
                name: "B12",
                title: "Feel the need or impulse to kill yourself or to plan to kill yourself sooner rather than later? ",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "B12-a",
                title: "What was the impulse?",
                type: "checkbox",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Kill yourself",
                    "Plan to kill yourself",
                ],
                visibleIf: "{B12} = 'Yes'"
            },
            {
                name: "B12-a",
                title: "What this impulse provoked?",
                type: "checkbox",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Provoked",
                    "Largely inprovoked",
                ],
                visibleIf: "{B12} = 'Yes'"
            },
            {
                name: "B13",
                title: "Have difficulty resisting these impulses?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{B12} = 'Yes'"
            },
            {
                name: "B14",
                title: "Take any active steps to prepare for a suicide attempt in which you expected or intended to die (include anything done or purposely not done that put you closer to making a suicide attempt)? This includes times when you were going to kill yourself, but were interrupted or stopped yourself, before harming yourself.",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "B14-a",
                title: "Take active steps to prepare to kill yourself, but you did not start the suicide attempt?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{B14} = 'Yes'"
            },
            {
                name: "B14-b",
                title: "Take active steps to prepare to kill yourself, but then you stopped yourself just before harming yourself (“aborted”)?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{B14} = 'Yes'"
            },
            {
                name: "B14-c",
                title: "Take active steps to prepare to kill yourself, but then someone or something stopped you just before harming yourself (“interrupted”)?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{B14} = 'Yes'"
            },
            {
                name: "B15",
                title: "Injure yourself on purpose without intending to kill yourself?",
                description: "Does not count as suicidal behavior.",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "B16",
                title: "Attempt suicide (to kill yourself)?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "B16-a",
                title: "Start a suicide attempt (to kill yourself), but then you decided to stop and did not finish the attempt?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{B16} = 'Yes'"
            },
            {
                name: "B16-b",
                title: "Start a suicide attempt (to kill yourself), but then you were interrupted and did not finish the attempt?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{B16} = 'Yes'"
            },
            {
                name: "B16-c",
                title: "Go through with a suicide attempt (to kill yourself), completely as you meant to? A suicide attempt means you did something where you could possibly be injured, with at least a slight intent to die.",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{B16} = 'Yes'"
            },
            {
                name: "B16-c1",
                title: "What do you hoped/ expected?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Hope to be rescued / survive",
                    "Expected / intended to die",
                ],
                visibleIf: "{B16-c} = 'Yes'"
            },
            {
                name: "B17-a",
                title: "Usual time spent per day with any suicidal impulses, thoughts or actions:",
                type: "text",
                inputType: "time",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
            },
            {
                name: "B17-b",
                title: "Least amount of time spent in any day with suicidal impulses, thoughts or actions: ",
                type: "text",
                inputType: "time",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
            },
            {
                name: "B17-c",
                title: "Most amount of time spent in any day with suicidal impulses, thoughts or actions: ",
                type: "text",
                inputType: "time",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
            },
            {
                name: "B18",
                title: "In your lifetime, did you ever make a suicide attempt (try to kill yourself)?",
                description: "“A suicide attempt is any self-injurious behavior, with at least some intent (> 0) to die as a result of the act. Evidence that the individual intended to kill him-or herself, at least to some degree, can be explicit or inferred from the behavior or circumstance. For example, it is defined as a suicide attempt if it is clearly not an accident or if the individual thinks the act could be lethal, even though denying intent.” (FDA Guidance for Industry Suicidal Ideation and Behavior Document 2012 and C-CASA definition). Posner K et al. Am J Psychiatry 2007; 164 (7): 1035-1043 & http://www.fda.gov/Drugs/GuidanceComplianceRegulatoryInformation/Guidances/default.htm/",
                type: "radiogroup",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "B18-a",
                title: "How many times did you attempt?",
                type: "text",
                inputType: "number", 
                min: "0",       
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                visibleIf: "{B18} = 'Yes'"
            },
            {
                name: "B18-b",
                title: "When was the last suicide attempt?",
                type: "radiogroup",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Current: within the past 12 months",
                    "In early remission: between 12 and 24 months ago",
                    "In remission: more than 24 months ago",
                ],
                visibleIf: "{B18} = 'Yes'"
            },
            {
                name: "B19",
                title: "How likely are you to try to kill yourself within the next 3 months on a scale of 0-100%?",
                description: "Any likelihood > 0%, code 'Yes'.",
                type: "radiogroup",
                choices: [
                    "Yes",
                    "No",
                ],
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
            },
            // Diagnostic box

        ]
    }], 
    showQuestionNumbers: "on",
    cookieName: "b",

}

export const surveyMiniC = {
    pages: [{
        name: "surveyMiniC",
        elements: [
            {
                name: "C1-a",
                title: "Have you ever had a period of time when you were feeling 'up' or 'high' or ‘hyper’ and so active or full of energy or full of yourself that you got into trouble, - or that other people thought you were not your usual self? (Do not consider times when you were intoxicated on drugs or alcohol.)",
                description: "By 'up' or 'high' or ‘hyper’ I mean: having elated mood; increased energy or increased activity; needing less sleep; having rapid thoughts; being full of ideas; having an increase in productivity, motivation, creativity, or impulsive behavior; phoning or working excessively or spending more money.",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "C1-b",
                title: "Are you currently feeling ‘up’ or ‘high’ or ‘hyper’ or full of energy?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{C1-a} = 'Yes'"
            },
            {
                name: "C2-a",
                title: "Have you ever been persistently irritable, for several days, so that you had arguments or verbal or physical fights, or shouted at people outside your family? Have you or others noticed that you have been more irritable or over reacted, compared to other people, even in situations that you felt were justified?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "C2-b",
                title: "Are you currently feeling persistently irritable?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{C2-a} = 'Yes'"
            },
            {
                name: "C3-a",
                title: "Over the past few days including today, when you felt high and full of energy or irritable, did you feel that you could do things others couldn't do, or that you were an especially important person?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{C1-b} = 'Yes' or {C2-b} = 'Yes'"
            },
            {
                name: "C3-a",
                title: "Over a period of a few days in the past, when you felt most high and most full of energy or most irritable, did you feel that you could do things others couldn't do, or that you were an especially important person?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{C1-b} = 'No' and {C2-b} = 'No'"
            },
            {
                name: "C3-a1",
                xtitle: "Give some examples.",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Current episode",
                    "Past episode",
                ],
                hasComment: true,
                commentText: "Examples",
                visibleIf: "{C3-1} = 'Yes'"
            },
            {
                name: "C3-b",
                title: "Over the past few days including today, when you felt high and full of energy or irritable, did you need less sleep (for example, feel rested after only a few hours sleep)?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{C1-b} = 'Yes' or {C2-b} = 'Yes'"
            },
            {
                name: "C3-b",
                title: "Over a period of a few days in the past, when you felt most high and most full of energy or most irritable, did you need less sleep (for example, feel rested after only a few hours sleep)?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{C1-b} = 'No' and {C2-b} = 'No'"
            },
            {
                name: "C3-c",
                title: "Over the past few days including today, when you felt high and full of energy or irritable, did you  talk too much without stopping, or felt a pressure to keep talking?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{C1-b} = 'Yes' or {C2-b} = 'Yes'"
            },
            {
                name: "C3-c",
                title: "Over a period of a few days in the past, when you felt most high and most full of energy or most irritable, did you talk too much without stopping, or felt a pressure to keep talking?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{C1-b} = 'No' and {C2-b} = 'No'"
            },
            {
                name: "C3-d",
                title: "Over the past few days including today, when you felt high and full of energy or irritable, did you  notice your thoughts going very fast or running together or racing or moving very quickly from one subject to another?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{C1-b} = 'Yes' or {C2-b} = 'Yes'"
            },
            {
                name: "C3-d",
                title: "Over a period of a few days in the past, when you felt most high and most full of energy or most irritable, did you notice your thoughts going very fast or running together or racing or moving very quickly from one subject to another?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{C1-b} = 'No' and {C2-b} = 'No'"
            },
            {
                name: "C3-e",
                title: "Over the past few days including today, when you felt high and full of energy or irritable, did you  become easily distracted so that any little interruption could distract you?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{C1-b} = 'Yes' or {C2-b} = 'Yes'"
            },
            {
                name: "C3-e",
                title: "Over a period of a few days in the past, when you felt most high and most full of energy or most irritable, did you become easily distracted so that any little interruption could distract you?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{C1-b} = 'No' and {C2-b} = 'No'"
            },
            {
                name: "C3-f",
                title: "Over the past few days including today, when you felt high and full of energy or irritable, did you have a significant increase in your activity or drive, at work, at school, socially or sexually or did you become physically or mentally restless? This increase in activity may be with or without a purpose.",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{C1-b} = 'Yes' or {C2-b} = 'Yes'"
            },
            {
                name: "C3-f",
                title: "Over a period of a few days in the past, when you felt most high and most full of energy or most irritable, did you have a significant increase in your activity or drive, at work, at school, socially or sexually or did you become physically or mentally restless? This increase in activity may be with or without a purpose.",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{C1-b} = 'No' and {C2-b} = 'No'"
            },
            {
                name: "C3-g",
                title: "Over the past few days including today, when you felt high and full of energy or irritable, did you want so much to engage in pleasurable activities that you ignored the risks or consequences (for example, spending sprees, reckless driving, or sexual indiscretions)?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{C1-b} = 'Yes' or {C2-b} = 'Yes'"
            },
            {
                name: "C3-g",
                title: "Over a period of a few days in the past, when you felt most high and most full of energy or most irritable, did you want so much to engage in pleasurable activities that you ignored the risks or consequences (for example, spending sprees, reckless driving, or sexual indiscretions)?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{C1-b} = 'No' and {C2-b} = 'No'"
            },
            {
                name: "C4",
                title: "What is the longest time these symptoms lasted (most of the day nearly every day)?",
                description: "Assess this duration from the very start to the very end of symptoms, not just the peak.",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "3 consecutive days or less",
                    "4, 5, or 6 consecutive days, or more",
                    "7 consecutive days or more",
                ],
                visibleIf: "({C1-b} = 'No' and {C2-b} = 'No') or ({C1-b} = 'Yes' or {C2-b} = 'Yes')"
            },
            {
                name: "C5",
                title: "Were you hospitalized for these problems?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "({C1-b} = 'No' and {C2-b} = 'No') or ({C1-b} = 'Yes' or {C2-b} = 'Yes')"
            },
            {
                name: "C6",
                title: "Did these symptoms cause significant problems at home, at work, socially, in your relationships, at school or in some other important way?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{C5} = 'No'"
            },
            {
                name: "C7",
                title: "Were these symptoms associated with a clear change in the way that you previously functioned and that was different from the way that you usually are?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "(({C1-b} = 'No' and {C2-b} = 'No') or ({C1-b} = 'Yes' or {C2-b} = 'Yes')) and {C5} = 'Yes'"
            },
            // C8 - special
            // {
            //     name: "C8-a",
            //     title: "Did you have 2 or more of these (manic) episodes lasting 7 days or more ( C4c ) in your lifetime (including the current episode if present)?",
            //     description: "If manic episode is positive either current or past.",
            //     type: "radiogroup",            
            //     clearIfInvisible: "onHidden",
            //     isRequired: false,
            //     requiredErrorText: "*this is required.",
            //     choices: [
            //         "Yes",
            //         "No",
            //     ],
            //     visibleIf: "({C1-b} = 'No' and {C2-b} = 'No') or ({C1-b} = 'Yes' or {C2-b} = 'Yes')"
            // },
            // {
            //     name: "C8-b",
            //     title: "Did you have 2 or more of these (hypomanic) episodes lasting 4 days or more ( C4b ) in your lifetime (including the current episode)?",
            //     description: "If manic or hypomanic episode is positive either current or past.",
            //     type: "radiogroup",            
            //     clearIfInvisible: "onHidden",
            //     isRequired: false,
            //     requiredErrorText: "*this is required.",
            //     choices: [
            //         "Yes",
            //         "No",
            //     ],
            //     visibleIf: "({C1-b} = 'No' and {C2-b} = 'No') or ({C1-b} = 'Yes' or {C2-b} = 'Yes')"
            // },
            // {
            //     name: "C8-c",
            //     title: "Did you have these hypomanic symptoms lasting only 1 to 3 days ( C4a ) 2 or more times in your lifetime, (including the current episode if present)?",
            //     description: "If the past 'hypomanic symptoms' category is coded positive.",
            //     type: "radiogroup",            
            //     clearIfInvisible: "onHidden",
            //     isRequired: false,
            //     requiredErrorText: "*this is required.",
            //     choices: [
            //         "Yes",
            //         "No",
            //     ],
            //     visibleIf: "({C1-b} = 'No' and {C2-b} = 'No') or ({C1-b} = 'Yes' or {C2-b} = 'Yes')"
            // },            

            // Diagnostic box
        ]
    }], 
    showQuestionNumbers: "on",
    cookieName: "c",
}

export const surveyMiniD = {
    pages: [{
        name: "surveyMiniD",
        elements: [
            {
                name: "D1-a",
                title: "Have you, on more than one occasion, had spells or attacks when you suddenly felt anxious, very frightened, uncomfortable or uneasy, even in situations where most people would not feel that way?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },            
            {
                name: "D1-b",
                title: "Did the spells surge to a peak within 10 minutes of starting?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{D1-a} = 'Yes'"
            },            
            {
                name: "D2",
                title: "At any time in the past, did any of those spells or attacks come on unexpectedly or occur in an unpredictable or unprovoked manner?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{D1-b} = 'Yes'"
            },            
            {
                name: "D3",
                title: "Have you ever had one such attack followed by a month or more of persistent concern about having another attack, or worries about the consequences of the attack - or did you make any significant change in your behavior because of the attacks (e.g., avoiding unfamiliar situations, or avoiding leaving your house or shopping alone, or doing things to avoid having a panic attack or visiting your doctor or the emergency room more frequently)?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{D2} = 'Yes'"
            },            
            {
                name: "D4-a",
                title: "During the worst attack that you can remember did you have skipping, racing or pounding of your heart?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{D2} = 'Yes'"
            },            
            {
                name: "D4-b",
                title: "During the worst attack that you can remember did you have sweating or clammy hands?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{D2} = 'Yes'"
            },            
            {
                name: "D4-c",
                title: "During the worst attack that you can remember were you trembling or shaking?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{D2} = 'Yes'"
            },            
            {
                name: "D4-d",
                title: "During the worst attack that you can remember did you have shortness of breath or difficulty breathing or a smothering sensation?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{D2} = 'Yes'"
            },            
            {
                name: "D4-e",
                title: "During the worst attack that you can remember did you have a choking sensation or a lump in your throat?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{D2} = 'Yes'"
            },            
            {
                name: "D4-f",
                title: "During the worst attack that you can remember did you have chest pain, pressure or discomfort?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{D2} = 'Yes'"
            },            
            {
                name: "D4-g",
                title: "During the worst attack that you can remember did you have nausea, stomach problems or sudden diarrhea?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{D2} = 'Yes'"
            },            
            {
                name: "D4-h",
                title: "During the worst attack that you can remember did you feel dizzy, unsteady, lightheaded or feel faint?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{D2} = 'Yes'"
            },            
            {
                name: "D4-i",
                title: "During the worst attack that you can remember did you have hot flushes or chills?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{D2} = 'Yes'"
            },            
            {
                name: "D4-j",
                title: "During the worst attack that you can remember did you have tingling or numbness in parts of your body?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{D2} = 'Yes'"
            },            
            {
                name: "D4-k",
                title: "During the worst attack that you can remember did things around you feel strange, unreal, detached or unfamiliar, or did you feel outside of or detached from part or all of your body?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{D2} = 'Yes'"
            },            
            {
                name: "D4-l",
                title: "During the worst attack that you can remember did you fear that you were losing control or going crazy?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{D2} = 'Yes'"
            },            
            {
                name: "D4-m",
                title: "During the worst attack that you can remember did you fear that you were dying?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{D2} = 'Yes'"
            },         
            // D5 - special
            {
                name: "D6",
                title: " In the past month did you have persistent concern about having another attack, or worry about the consequences of the attacks, PANIC DISORDER or did you change your behavior in any way because of the attacks?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{D2} = 'Yes'"
            }, 
            // Diagnostic box

        ]
    }], 
    showQuestionNumbers: "on",
    cookieName: "d",
}
export const surveyMiniE = {
    pages: [{
        name: "surveyMiniE",
        elements: [
            {
                name: "E1",
                title: "Do you feel anxious or uneasy in places or situations where help might not be available or escape might be difficult if you had a panic attack or panic-like or embarrassing symptoms, like: being in a crowd, or standing in a line (queue), being in an open space or when crossing a bridge, being in an enclosed space, when you are alone away from home, or alone at home, ! or traveling in a bus, train or car or using public transportation?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },            
            {
                name: "E1-a",
                title: "Are two or more of the above situations real?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{E1} = 'Yes'"
            },            
            {
                name: "E2",
                title: "Do these situations almost always bring on fear or anxiety?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{E1-a} = 'Yes'"
            },            
            {
                name: "E3",
                title: "Do you fear these situations so much that you avoid them, or suffer through them, or need a companion to face them?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{E2} = 'Yes'"
            },            
            {
                name: "E4",
                title: "Is this fear or anxiety excessive or out of proportion to the real danger in the situation?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{E3} = 'Yes'"
            },            
            {
                name: "E5",
                title: "Did this avoidance, fear or anxiety persist for at least 6 months?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{E4} = 'Yes'"
            },            
            {
                name: "E6",
                title: "Did these symptoms cause significant distress or problems at home, at work, socially, at school or in some other important way?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{E5} = 'Yes'"
            },            
            // Diagnostic box
        ]
    }], 
    showQuestionNumbers: "on",
    cookieName: "e",
}

export const surveyMiniF = {
    pages: [{
        name: "surveyMiniF",
        elements: [
            {
                name: "F1",
                title: "In the past month, did you have persistent fear and significant anxiety at being watched, being the focus of attention, or of being humiliated or embarrassed or rejected? This includes things like speaking in public, eating in public or with others, writing while someone watches, performing in front of others or being in social situations.",
                description: "Please specify if the subject’s fears are restricted to speaking or performing in public.",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },            
            {
                name: "F2",
                title: "Do these social situations almost always bring on fear or anxiety?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{F1} = 'Yes'"
            },            
            {
                name: "F3",
                title: "Do you fear these social situations so much that you avoid them, or suffer through them, or need a companion to face them?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{F2} = 'Yes'"
            },            
            {
                name: "F4",
                title: "Is this social fear or anxiety excessive or unreasonable in these social situations?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{F3} = 'Yes'"
            },            
            {
                name: "F5",
                title: "Did this social avoidance, fear or anxiety persist for at least 6 months?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{F4} = 'Yes'"
            },            
            {
                name: "F6",
                title: "Did these social fears cause significant distress or interfere with your ability to function at work, at school or socially or in your relationships or in some other important way?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{F5} = 'Yes'"
            },            
            // Diagnostic box
        ]
    }], 
    showQuestionNumbers: "on",
    cookieName: "f",
}

export const surveyMiniG = {
    pages: [{
        name: "surveyMiniG",
        elements: [
            {
                name: "G1-a",
                title: "In the past month, have you been bothered by recurrent thoughts, impulses, or images that were unwanted, distasteful, inappropriate, intrusive, or distressing?",
                description: "For example, the idea that you were dirty, contaminated or had germs, or fear of a contaminating others, or fear of harming someone even though it disturbs or distresses you, or fear you would act on some impulse, or fear or superstitions that you would be responsible for things going wrong, or obsessions with sexual thoughts, images or impulses, or religious obsessions.",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },            
            {
                name: "G1-b",
                title: "In the past month, did you try to suppress these thoughts, impulses, or images or to neutralize or to reduce them with some other thought or action?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{G1-a} = 'Yes'"
            },            
            {
                name: "G2",
                title: "Did they keep coming back into your mind even when you tried to ignore or NO YES get rid of them?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{G1-b} = 'Yes'"
            },            
            {
                name: "G3-a",
                title: "In the past month, did you feel driven to do something repeatedly in response to an obsession or in response to a rigid rule, like washing or cleaning excessively, counting or ̄ checking things over and over, or repeating or arranging things, or other superstitious rituals?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },            
            {
                name: "G3-b",
                title: "Are these rituals done to prevent or reduce anxiety or distress or to prevent something bad from happening and are they excessive or unreasonable?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{G3-a} = 'Yes'"
            },   
            {
                name: "G4-a",
                title: "Are these rituals done to prevent or reduce anxiety or distress or to prevent something bad from happening and are they excessive or unreasonable?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                defaultValue: [
                    "Yes"
                ],
                visibleIf: "({G1-a} = 'Yes' and {G1-b}='Yes' and {G2}='Yes') or ({G3-a}='Yes' and {G3-b}='Yes')"
            },    
            {
                name: "G4-b",
                title: "Are these rituals done to prevent or reduce anxiety or distress or to prevent something bad from happening and are they excessive or unreasonable?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                defaultValue: [
                    "No"
                ],
                visibleIf: "({G1-a}='Yes' and {G1-b}='No' and {G2}='No') or ({G1-a}='Yes' and {G1-b}='Yes' and {G2}='No') or ({G3-a}='Yes' and {G3-b}='No')"
            },    
            {
                name: "G5",
                title: "In the past month, did these obsessive thoughts and/or compulsive behaviors cause significant distress, or interfere with your ability to function at home, at work, at school or socially or in your relationships or in some other important way or did they take more than one hour a day?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "({G1-a} = 'Yes' and {G1-b}='Yes' and {G2}='Yes') or ({G3-a}='Yes' and {G3-b}='Yes')"
            },    
            // {
            //     name: "Diagnostic test",
            //     title: "Test",
            //     type: "radiogroup",            
            //     clearIfInvisible: "onHidden",
            //     isRequired: false,
            //     requiredErrorText: "*this is required.",
            //     choices: [
            //         "Yes",
            //         "No",
            //     ],
            //     visibleIf: "{G4}='No'"
            // },    
           
            // Diagnostic box
        ]
    }], 
    showQuestionNumbers: "on",
    cookieName: "g",
}

export const surveyMiniH = {
    pages: [{
        name: "surveyMiniH",
        elements: [
            {
                name: "H1",
                title: "Have you ever experienced or witnessed or had to deal with an extremely traumatic event that included actual or threatened death or serious injury or sexual violence to you or someone else?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },            
            {
                name: "H2",
                title: "Starting after the traumatic event, did you repeatedly re-experience the event in an unwanted mentally distressing way, (such as in recurrent dreams related to the event, intense recollections or memories, or flashbacks or as if the event was recurring) or did you have intense physical or psychological reactions when you were reminded about the event or exposed to a similar event?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{H1}='Yes'"
            },            
            {
                name: "H3-a",
                title: "In the past month did you persistently try to avoid thinking about or remembering distressing details or feelings related to the event?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{H2}='Yes'"
            },            
            {
                name: "H3-b",
                title: "Did you persistently try to avoid people, conversations, places, situations, activities or things that bring back distressing recollections of the event?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{H2}='Yes'"
            },            
            {
                name: "H3-c1",
                title: "Are one or more H3 answers coded 'Yes'?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                defaultValue: [
                    "Yes"
                ],
                visibleIf: "{H3-a}='Yes' or {H3-b}='Yes'"
            },            
            {
                name: "H3-c2",
                title: "Are one or more H3 answers coded 'Yes'?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                defaultValue: [
                    "No"
                ],
                visibleIf: "{H3-a}='No' and {H3-b}='No'"
            },            
            {
                name: "H4-a",
                title: "Did you have trouble recalling some important part of the trauma? (but not because of or related to head trauma, alcohol or drugs).",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{H3-a}='Yes' or {H3-b}='Yes'"
            },            
            {
                name: "H4-b",
                title: "Were you constantly and unreasonably negative about yourself or others or the world?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{H3-a}='Yes' or {H3-b}='Yes'"
            },            
            {
                name: "H4-c",
                title: "Did you constantly blame yourself or others in unreasonable ways for the trauma?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{H3-a}='Yes' or {H3-b}='Yes'"
            },            
            {
                name: "H4-d",
                title: "Were your feelings always negative (such as fear, horror, anger, guilt or shame)?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{H3-a}='Yes' or {H3-b}='Yes'"
            },            
            {
                name: "H4-e",
                title: "Have you become much less interested in participating in activities that were meaningful to you before?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{H3-a}='Yes' or {H3-b}='Yes'"
            },            
            {
                name: "H4-f",
                title: "Did you feel detached or estranged from others?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{H3-a}='Yes' or {H3-b}='Yes'"
            },            
            {
                name: "H4-g",
                title: "Were you unable to experience any good feelings (such as happiness, satisfaction or loving feelings)?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{H3-a}='Yes' or {H3-b}='Yes'"
            },            
            {
                name: "H4-h1",
                title: "Are 2 or more H4 answers coded 'Yes'?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                defaultValue: [
                    "Yes"
                ],
                visibleIf: "({H4-a}='Yes' and {H4-b}='Yes') or ({H4-a}='Yes' and {H4-c}='Yes') or ({H4-a}='Yes' and {H4-d}='Yes') or ({H4-a}='Yes' and {H4-e}='Yes') or ({H4-a}='Yes' and {H4-f}='Yes') or ({H4-a}='Yes' and {H4-g}='Yes') or ({H4-b}='Yes' and {H4-c}='Yes') or ({H4-b}='Yes' and {H4-d}='Yes') or ({H4-b}='Yes' and {H4-e}='Yes') or ({H4-b}='Yes' and {H4-f}='Yes') or ({H4-b}='Yes' and {H4-g}='Yes') or ({H4-c}='Yes' and {H4-d}='Yes') or ({H4-c}='Yes' and {H4-e}='Yes') or ({H4-c}='Yes' and {H4-f}='Yes') or ({H4-c}='Yes' and {H4-g}='Yes') or ({H4-d}='Yes' and {H4-e}='Yes') or ({H4-d}='Yes' and {H4-f}='Yes') or ({H4-d}='Yes' and {H4-g}='Yes') or ({H4-e}='Yes' and {H4-f}='Yes') or ({H4-e}='Yes' and {H4-g}='Yes') or ({H4-f}='Yes' and {H4-g}='Yes')"
            },            
            {
                name: "H4-h2",
                title: "Are 2 or more H4 answers coded 'Yes'?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                defaultValue: [
                    "No"
                ],
                visibleIf: "({H4-a}='No' and {H4-b}='No' and {H4-c}='No' and {H4-d}='No' and {H4-e}='No' and {H4-f}='No') or ({H4-a}='No' and {H4-b}='No' and {H4-c}='No' and {H4-d}='No' and {H4-e}='No' and {H4-g}='No') or ({H4-a}='No' and {H4-b}='No' and {H4-c}='No' and {H4-d}='No' and {H4-f}='No' and {H4-g}='No') or ({H4-a}='No' and {H4-b}='No' and {H4-c}='No' and {H4-e}='No' and {H4-f}='No' and {H4-g}='No') or ({H4-a}='No' and {H4-b}='No' and {H4-d}='No' and {H4-e}='No' and {H4-f}='No' and {H4-g}='No') or ({H4-a}='No' and {H4-c}='No' and {H4-d}='No' and {H4-e}='No' and {H4-f}='No' and {H4-g}='No') or ({H4-b}='No' and {H4-c}='No' and {H4-d}='No' and {H4-e}='No' and {H4-f}='No' and {H4-g}='No')"
            },            
            {
                name: "H5-a",
                title: "In the past month were you especially irritable or did you have outbursts of anger with little or no provocation?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "({H4-a}='Yes' and {H4-b}='Yes') or ({H4-a}='Yes' and {H4-c}='Yes') or ({H4-a}='Yes' and {H4-d}='Yes') or ({H4-a}='Yes' and {H4-e}='Yes') or ({H4-a}='Yes' and {H4-f}='Yes') or ({H4-a}='Yes' and {H4-g}='Yes') or ({H4-b}='Yes' and {H4-c}='Yes') or ({H4-b}='Yes' and {H4-d}='Yes') or ({H4-b}='Yes' and {H4-e}='Yes') or ({H4-b}='Yes' and {H4-f}='Yes') or ({H4-b}='Yes' and {H4-g}='Yes') or ({H4-c}='Yes' and {H4-d}='Yes') or ({H4-c}='Yes' and {H4-e}='Yes') or ({H4-c}='Yes' and {H4-f}='Yes') or ({H4-c}='Yes' and {H4-g}='Yes') or ({H4-d}='Yes' and {H4-e}='Yes') or ({H4-d}='Yes' and {H4-f}='Yes') or ({H4-d}='Yes' and {H4-g}='Yes') or ({H4-e}='Yes' and {H4-f}='Yes') or ({H4-e}='Yes' and {H4-g}='Yes') or ({H4-f}='Yes' and {H4-g}='Yes')"
            },            
            {
                name: "H5-b",
                title: "In the past month were you more reckless or more self-destructive?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "({H4-a}='Yes' and {H4-b}='Yes') or ({H4-a}='Yes' and {H4-c}='Yes') or ({H4-a}='Yes' and {H4-d}='Yes') or ({H4-a}='Yes' and {H4-e}='Yes') or ({H4-a}='Yes' and {H4-f}='Yes') or ({H4-a}='Yes' and {H4-g}='Yes') or ({H4-b}='Yes' and {H4-c}='Yes') or ({H4-b}='Yes' and {H4-d}='Yes') or ({H4-b}='Yes' and {H4-e}='Yes') or ({H4-b}='Yes' and {H4-f}='Yes') or ({H4-b}='Yes' and {H4-g}='Yes') or ({H4-c}='Yes' and {H4-d}='Yes') or ({H4-c}='Yes' and {H4-e}='Yes') or ({H4-c}='Yes' and {H4-f}='Yes') or ({H4-c}='Yes' and {H4-g}='Yes') or ({H4-d}='Yes' and {H4-e}='Yes') or ({H4-d}='Yes' and {H4-f}='Yes') or ({H4-d}='Yes' and {H4-g}='Yes') or ({H4-e}='Yes' and {H4-f}='Yes') or ({H4-e}='Yes' and {H4-g}='Yes') or ({H4-f}='Yes' and {H4-g}='Yes')"
            },            
            {
                name: "H5-c",
                title: "In the past month were you more nervous or constantly on your guard?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "({H4-a}='Yes' and {H4-b}='Yes') or ({H4-a}='Yes' and {H4-c}='Yes') or ({H4-a}='Yes' and {H4-d}='Yes') or ({H4-a}='Yes' and {H4-e}='Yes') or ({H4-a}='Yes' and {H4-f}='Yes') or ({H4-a}='Yes' and {H4-g}='Yes') or ({H4-b}='Yes' and {H4-c}='Yes') or ({H4-b}='Yes' and {H4-d}='Yes') or ({H4-b}='Yes' and {H4-e}='Yes') or ({H4-b}='Yes' and {H4-f}='Yes') or ({H4-b}='Yes' and {H4-g}='Yes') or ({H4-c}='Yes' and {H4-d}='Yes') or ({H4-c}='Yes' and {H4-e}='Yes') or ({H4-c}='Yes' and {H4-f}='Yes') or ({H4-c}='Yes' and {H4-g}='Yes') or ({H4-d}='Yes' and {H4-e}='Yes') or ({H4-d}='Yes' and {H4-f}='Yes') or ({H4-d}='Yes' and {H4-g}='Yes') or ({H4-e}='Yes' and {H4-f}='Yes') or ({H4-e}='Yes' and {H4-g}='Yes') or ({H4-f}='Yes' and {H4-g}='Yes')"
            },            
            {
                name: "H5-d",
                title: "In the past month were you more easily startled?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "({H4-a}='Yes' and {H4-b}='Yes') or ({H4-a}='Yes' and {H4-c}='Yes') or ({H4-a}='Yes' and {H4-d}='Yes') or ({H4-a}='Yes' and {H4-e}='Yes') or ({H4-a}='Yes' and {H4-f}='Yes') or ({H4-a}='Yes' and {H4-g}='Yes') or ({H4-b}='Yes' and {H4-c}='Yes') or ({H4-b}='Yes' and {H4-d}='Yes') or ({H4-b}='Yes' and {H4-e}='Yes') or ({H4-b}='Yes' and {H4-f}='Yes') or ({H4-b}='Yes' and {H4-g}='Yes') or ({H4-c}='Yes' and {H4-d}='Yes') or ({H4-c}='Yes' and {H4-e}='Yes') or ({H4-c}='Yes' and {H4-f}='Yes') or ({H4-c}='Yes' and {H4-g}='Yes') or ({H4-d}='Yes' and {H4-e}='Yes') or ({H4-d}='Yes' and {H4-f}='Yes') or ({H4-d}='Yes' and {H4-g}='Yes') or ({H4-e}='Yes' and {H4-f}='Yes') or ({H4-e}='Yes' and {H4-g}='Yes') or ({H4-f}='Yes' and {H4-g}='Yes')"
            },            
            {
                name: "H5-e",
                title: "In the past month did you have more difficulty concentrating?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "({H4-a}='Yes' and {H4-b}='Yes') or ({H4-a}='Yes' and {H4-c}='Yes') or ({H4-a}='Yes' and {H4-d}='Yes') or ({H4-a}='Yes' and {H4-e}='Yes') or ({H4-a}='Yes' and {H4-f}='Yes') or ({H4-a}='Yes' and {H4-g}='Yes') or ({H4-b}='Yes' and {H4-c}='Yes') or ({H4-b}='Yes' and {H4-d}='Yes') or ({H4-b}='Yes' and {H4-e}='Yes') or ({H4-b}='Yes' and {H4-f}='Yes') or ({H4-b}='Yes' and {H4-g}='Yes') or ({H4-c}='Yes' and {H4-d}='Yes') or ({H4-c}='Yes' and {H4-e}='Yes') or ({H4-c}='Yes' and {H4-f}='Yes') or ({H4-c}='Yes' and {H4-g}='Yes') or ({H4-d}='Yes' and {H4-e}='Yes') or ({H4-d}='Yes' and {H4-f}='Yes') or ({H4-d}='Yes' and {H4-g}='Yes') or ({H4-e}='Yes' and {H4-f}='Yes') or ({H4-e}='Yes' and {H4-g}='Yes') or ({H4-f}='Yes' and {H4-g}='Yes')"
            },            
            {
                name: "H5-f",
                title: "In the past month did you have more difficulty sleeping?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "({H4-a}='Yes' and {H4-b}='Yes') or ({H4-a}='Yes' and {H4-c}='Yes') or ({H4-a}='Yes' and {H4-d}='Yes') or ({H4-a}='Yes' and {H4-e}='Yes') or ({H4-a}='Yes' and {H4-f}='Yes') or ({H4-a}='Yes' and {H4-g}='Yes') or ({H4-b}='Yes' and {H4-c}='Yes') or ({H4-b}='Yes' and {H4-d}='Yes') or ({H4-b}='Yes' and {H4-e}='Yes') or ({H4-b}='Yes' and {H4-f}='Yes') or ({H4-b}='Yes' and {H4-g}='Yes') or ({H4-c}='Yes' and {H4-d}='Yes') or ({H4-c}='Yes' and {H4-e}='Yes') or ({H4-c}='Yes' and {H4-f}='Yes') or ({H4-c}='Yes' and {H4-g}='Yes') or ({H4-d}='Yes' and {H4-e}='Yes') or ({H4-d}='Yes' and {H4-f}='Yes') or ({H4-d}='Yes' and {H4-g}='Yes') or ({H4-e}='Yes' and {H4-f}='Yes') or ({H4-e}='Yes' and {H4-g}='Yes') or ({H4-f}='Yes' and {H4-g}='Yes')"
            },            
            {
                name: "H5-g1",
                title: "Are 2 or more H5 answers coded 'Yes'?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                defaultValue: [
                    "Yes"
                ],
                visibleIf: "({H5-a}='Yes' and {H5-b}='Yes') or ({H5-a}='Yes' and {H5-c}='Yes') or ({H5-a}='Yes' and {H5-d}='Yes') or ({H5-a}='Yes' and {H5-e}='Yes') or ({H5-a}='Yes' and {H5-f}='Yes') or ({H5-b}='Yes' and {H5-c}='Yes') or ({H5-b}='Yes' and {H5-d}='Yes') or ({H5-b}='Yes' and {H5-e}='Yes') or ({H5-b}='Yes' and {H5-f}='Yes') or ({H5-c}='Yes' and {H5-d}='Yes') or ({H5-c}='Yes' and {H5-e}='Yes') or ({H5-c}='Yes' and {H5-f}='Yes') or ({H5-d}='Yes' and {H5-e}='Yes') or ({H5-d}='Yes' and {H5-f}='Yes') or ({H5-e}='Yes' and {H5-f}='Yes')"
            },            
            {
                name: "H5-g2",
                title: "Are 2 or more H5 answers coded 'Yes'?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                defaultValue: [
                    "No"
                ],
                visibleIf: "({H5-a}='No' and {H5-b}='No' and {H5-c}='No' and {H5-d}='No' and {H5-e}='No') or ({H5-a}='No' and {H5-b}='No' and {H5-c}='No' and {H5-d}='No' and {H5-e}='No') or ({H5-a}='No' and {H5-b}='No' and {H5-c}='No' and {H5-d}='No' and {H5-f}='No') or ({H5-a}='No' and {H5-b}='No' and {H5-c}='No' and {H5-e}='No' and {H5-f}='No') or ({H5-a}='No' and {H5-b}='No' and {H5-d}='No' and {H5-e}='No' and {H5-f}='No') or ({H5-a}='No' and {H5-c}='No' and {H5-d}='No' and {H5-e}='No' and {H5-f}='No') or ({H5-b}='No' and {H5-c}='No' and {H5-d}='No' and {H5-e}='No' and {H5-f}='No')"
            },           
            {
                name: "H6",
                title: "Did all these problems start after the traumatic event and last for more than one month?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "({H5-a}='Yes' and {H5-b}='Yes') or ({H5-a}='Yes' and {H5-c}='Yes') or ({H5-a}='Yes' and {H5-d}='Yes') or ({H5-a}='Yes' and {H5-e}='Yes') or ({H5-a}='Yes' and {H5-f}='Yes') or ({H5-b}='Yes' and {H5-c}='Yes') or ({H5-b}='Yes' and {H5-d}='Yes') or ({H5-b}='Yes' and {H5-e}='Yes') or ({H5-b}='Yes' and {H5-f}='Yes') or ({H5-c}='Yes' and {H5-d}='Yes') or ({H5-c}='Yes' and {H5-e}='Yes') or ({H5-c}='Yes' and {H5-f}='Yes') or ({H5-d}='Yes' and {H5-e}='Yes') or ({H5-d}='Yes' and {H5-f}='Yes') or ({H5-e}='Yes' and {H5-f}='Yes')"
            },   
            {
                name: "H7",
                title: "During the past month, did these problems cause significant distress, or interfere with your ability to function at home, at work, at school or socially or in your relationships or in some other important way?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{H6}='Yes'"
            },   
            
            // Diagnostic box
        ]
    }], 
    showQuestionNumbers: "on",
    cookieName: "h",
}
export const surveyMiniI = {
    pages: [{
        name: "surveyMiniI",
        elements: [
            {
                name: "I1",
                title: "In the past 12 months, have you had 3 or more alcoholic drinks, - within a 3-hour period, - on 3 or more occasions?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },            
            {
                name: "I2-a",
                title: "In the past 12 months, during the times when you drank alcohol, did you end up drinking more than you planned when you started?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{I1}='Yes'"
            },            
            {
                name: "I2-b",
                title: "In the past 12 months did you repeatedly want to reduce or control your alcohol use? Did you try to cut down or control your alcohol use, but failed?",
                description: "If yes to either, code 'Yes'.",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{I1}='Yes'"
            },            
            {
                name: "I2-c",
                title: "In the past 12 months on the days that you drank, did you spend substantial time obtaining alcohol, drinking, or recovering from the effects of alcohol?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{I1}='Yes'"
            },            
            {
                name: "I2-d",
                title: "In the past 12 months did you crave or have a strong desire or urge to use alcohol?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{I1}='Yes'"
            },            
            {
                name: "I2-e",
                title: "In the past 12 months did you spend less time meeting your responsibilities at work, at school, or at home, because of your repeated drinking?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{I1}='Yes'"
            },            
            {
                name: "I2-f",
                title: "In the past 12 months if your drinking caused problems with your family or other people, did you still keep on drinking?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{I1}='Yes'"
            },            
            {
                name: "I2-g",
                title: "In the past 12 months were you intoxicated more than once in any situation where you or others were physically at risk, for example, driving a car, riding a motorbike, using machinery, boating, etc.?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{I1}='Yes'"
            },            
            {
                name: "I2-h",
                title: "In the past 12 months did you continue to use alcohol, even though it was clear that the alcohol had caused or worsened psychological or physical problems?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{I1}='Yes'"
            },            
            {
                name: "I2-i",
                title: "In the past 12 months did you reduce or give up important work, social or recreational activities because of your drinking?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{I1}='Yes'"
            },            
            {
                name: "I2-j",
                title: "In the past 12 months did you need to drink a lot more in order to get the same effect that you got when you first NO YES started drinking or did you get much less effect with continued use of the same amount?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{I1}='Yes'"
            },            
            {
                name: "I2-k1",
                title: "When you cut down on heavy or prolonged drinking did you have any of the following: increased sweating or increased heart rate, hand tremor or 'the shakes', trouble sleeping, nausea or vomiting, hearing or seeing things other people could not see or hear or having sensations in your skin for no apparent reason, agitation, anxiety, seizures.",
                description: "If yes to 2 or more of the above 8, code 'Yes'.",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{I1}='Yes'"
            },            
            {
                name: "I2-k2",
                title: "Did you drink alcohol to reduce or avoid withdrawal symptoms or to avoid a hangover?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{I1}='Yes'"
            },            
           
            // Diagnostic box
        ]
    }], 
    showQuestionNumbers: "on",
    cookieName: "i",
}

export const surveyMiniJ = {
    pages: [{
        name: "surveyMiniJ",
        elements: [
            {
                name: "J1-a",
                title: "In the past 12 months, did you take any drug, or any drug like it, more than once, to get high, to feel elated, to get 'a buzz' or to change your mood?",
                description: "Read through the drug list before answering this question.",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },             
            {
                name: "J1-b",
                title: "Did you use any other substances that I didn’t mention and that you think may be relevant?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{J1-a}='Yes'"
            },             
            {
                name: "J1-c",
                title: "Specify the most used drug(s):",
                type: "comment",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                visibleIf: "{J1-a}='Yes'"
            },             
            {
                name: "J1-d",
                title: "Which drug(s) cause the biggest problems?",
                type: "comment",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                visibleIf: "{J1-a}='Yes'"
            },             
            {
                name: "J2-a",
                title: "Considering your use of ( NAME OF DRUG / DRUG CLASS SELECTED ), in the past 12 months, during the times when you used the drug, did you end up using more than you planned when you started?",
                type: "radiogroup",     
                choices: [
                    "Yes",
                    "No",
                ],       
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                visibleIf: "{J1-a}='Yes'"
            },             
            {
                name: "J2-b",
                title: "Considering your use of ( NAME OF DRUG / DRUG CLASS SELECTED ), in the past 12 months, did you repeatedly want to reduce or control your ( NAME OF DRUG / DRUG CLASS SELECTED ) use? Did you try to cut down or control your ( NAME OF DRUG / DRUG CLASS SELECTED ) use, but failed?",
                description: "If yes to either, code 'Yes'.",
                type: "radiogroup",     
                choices: [
                    "Yes",
                    "No",
                ],       
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                visibleIf: "{J1-a}='Yes'"
            },             
            {
                name: "J2-c",
                title: "Considering your use of ( NAME OF DRUG / DRUG CLASS SELECTED ), in the past 12 months, on the days that you used more ( NAME OF DRUG / DRUG CLASS SELECTED ), did you spend substantial NO YES time obtaining ( NAME OF DRUG / DRUG CLASS SELECTED ), using it, or recovering from its effects?",
                type: "radiogroup",     
                choices: [
                    "Yes",
                    "No",
                ],       
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                visibleIf: "{J1-a}='Yes'"
            },             
            {
                name: "J2-d",
                title: "Considering your use of ( NAME OF DRUG / DRUG CLASS SELECTED ), in the past 12 months, did you crave or have a strong desire or urge to use ( NAME OF DRUG / DRUG CLASS SELECTED)?",
                type: "radiogroup",     
                choices: [
                    "Yes",
                    "No",
                ],       
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                visibleIf: "{J1-a}='Yes'"
            },             
            {
                name: "J2-e",
                title: "Considering your use of ( NAME OF DRUG / DRUG CLASS SELECTED ), in the past 12 months, did you spend less time meeting your responsibilities at work, at school, or at home, because of your repeated ( NAME OF DRUG / DRUG CLASS SELECTED ) use?",
                type: "radiogroup",     
                choices: [
                    "Yes",
                    "No",
                ],       
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                visibleIf: "{J1-a}='Yes'"
            },             
            {
                name: "J2-f",
                title: "Considering your use of ( NAME OF DRUG / DRUG CLASS SELECTED ), in the past 12 months, if your ( NAME OF DRUG / DRUG CLASS SELECTED ) use caused problems with your family or other people, did you still keep on using it?",
                type: "radiogroup",     
                choices: [
                    "Yes",
                    "No",
                ],       
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                visibleIf: "{J1-a}='Yes'"
            },             
            {
                name: "J2-g",
                title: "Did you use the drug more than once in any situation where you or others were physically at risk, for example, driving a car, riding a motorbike, using machinery, boating, etc.?",
                type: "radiogroup",     
                choices: [
                    "Yes",
                    "No",
                ],       
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                visibleIf: "{J1-a}='Yes'"
            },             
            {
                name: "J2-h",
                title: "Did you continue to use ( NAME OF DRUG / DRUG CLASS SELECTED ), even though it was clear that the ( NAME OF DRUG / DRUG CLASS SELECTED ) had caused or worsened psychological or physical problems?",     
                type: "radiogroup",     
                choices: [
                    "Yes",
                    "No",
                ],       
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                visibleIf: "{J1-a}='Yes'"
            },             
            {
                name: "J2-i",
                title: "Did you reduce or give up important work, social or recreational activities because of your ( NAME OF DRUG / DRUG CLASS SELECTED ) use?", 
                type: "radiogroup",         
                choices: [
                    "Yes",
                    "No",
                ],       
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                visibleIf: "{J1-a}='Yes'"
            },             
            {
                name: "J2-j1",
                title: "Did you need to use ( NAME OF DRUG / DRUG CLASS SELECTED ) a lot more in order to get the same effect that you got when you first started using it or did you get much less effect with continued use of the same amount?", 
                description: "This criterion is coded 'No' if the medication is prescribed and used under appropriate medical supervision.",    
                type: "radiogroup",     
                choices: [
                    "Yes",
                    "No",
                ],       
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                visibleIf: "{J1-a}='Yes'"
            },             
            {
                name: "J2-j2",
                title: "Is the drug class currently being explored hallucinogens, or dissociative drugs, or inhalants, or 'Miscellaneous' drugs of abuse?", 
                type: "radiogroup",     
                choices: [
                    "Yes",
                    "No",
                ],       
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                visibleIf: "{J1-a}='Yes'"
            },             
            {
                name: "J2-k1(a)",
                title: "When you cut down on heavy or prolonged use of the drug did you have any of the following withdrawal symptoms:", 
                description: "Sedatives, Hypnotics, or Anxiolytics (2 or more withdrawal symptoms)",
                type: "checkbox",     
                choices: [
                    "1. increased sweating or increased heart rate",
                    "2. hand tremor or 'the shakes'",
                    "3. trouble sleeping",
                    "4. nausea or vomiting",
                    "5. hearing or seeing things other people could not see or hear or having sensations in your skin for no apparent reason",
                    "6. agitation",
                    "7. anxiety",
                    "8. seizures",
                    "None"
                ],       
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                visibleIf: "{J2-j2}='No'"
            },             
            {
                name: "J2-k1(b)",
                title: "When you cut down on heavy or prolonged use of the drug did you have any of the following withdrawal symptoms:", 
                description: "Opiates / Opioids (3 or more withdrawal symptoms)",
                type: "checkbox",     
                choices: [
                    "1. feeling depressed",
                    "2. nausea or vomiting",
                    "3. muscle aches",
                    "4. runny nose or teary eyes",
                    "5. dilated pupils, goose bumps or hair standing on end or sweating",
                    "6. diarrhea",
                    "7. yawning",
                    "8. hot flashes",
                    "9. trouble sleeping",
                    "None"
                ],       
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                visibleIf: "{J2-j2}='No'"
            },             
            {
                name: "J2-k1(c)",
                title: "When you cut down on heavy or prolonged use of the drug did you have any of the following withdrawal symptoms:", 
                description: "Stimulants and Cocaine (2 or more withdrawal symptoms)",
                type: "checkbox",     
                choices: [
                    "1. fatigue",
                    "2. vivid or unpleasant dreams",
                    "3. difficulty sleeping or sleeping too much",
                    "4. increased appetite",
                    "5. feeling or looking physically or mentally slowed down",
                    "None"
                ],       
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                visibleIf: "{J2-j2}='No'"
            },             
            {
                name: "J2-k1(d)",
                title: "When you cut down on heavy or prolonged use of the drug did you have any of the following withdrawal symptoms:", 
                description: "Cannabis (3 or more withdrawal symptoms)",
                type: "checkbox",     
                choices: [
                    "1. irritability, anger or aggression",
                    "2. nervousness or anxiety",
                    "3. trouble sleeping",
                    "4. appetite or weight loss",
                    "5. restlessness",
                    "6. feeling depressed",
                    "7. significant discomfort from one of the following: “stomach pain”, tremors or “shakes”, sweating, hot flashes, chills, headaches.",
                    "None"
                ],       
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                visibleIf: "{J2-j2}='No'"
            },             
            {
                name: "J2-k1",
                title: "If 'YES' to the required number of withdrawal symptoms for each class, code 'Yes'.", 
                description: "This criterion is coded 'No' if the medication is prescribed and used under appropriate medical supervision.",
                type: "radiogroup",     
                choices: [
                    "Yes",
                    "No",
                ],       
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                visibleIf: "{J2-k1(a)} notempty and {J2-k1(b)} notempty and {J2-k1(c)} notempty and {J2-k1(d)} notempty"
            },             
            {
                name: "J2-k2",
                title: "Did you use ( NAME OF DRUG / DRUG CLASS SELECTED ) to reduce or avoid withdrawal symptoms?", 
                type: "radiogroup",     
                choices: [
                    "Yes",
                    "No",
                ],       
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                visibleIf: "{J2-j2}='No'"
            },             
           
            // Diagnostic box
        ]
    }], 
    showQuestionNumbers: "on",
    cookieName: "j",
}

export const surveyMiniK = {
    pages: [{
        name: "surveyMiniK",
        elements: [
            {
                name: "K1-a",
                title: "Have you ever believed that people were spying on you, or that someone was plotting against you, or trying to hurt you?",
                description: "Ask for examples to rule out actual stalking.",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                hasComment: true,
                commentText: "Examples",
            },             
            {
                name: "K1-b",
                title: "Regarding the last question, do you currently believe these things?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{k1-a}='Yes'"
            },             
            {
                name: "K2-a",
                title: "Have you ever believed that someone was reading your mind or could hear your thoughts, or that you could actually read someone’s mind or hear what another person was thinking?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                hasComment: true,
                commentText: "Examples",
            },             
            {
                name: "K2-b",
                title: "Regarding the last question, do you currently believe these things?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{k2-a}='Yes'"
            },             
            {
                name: "K3-a",
                title: "Have you ever believed that someone or some force outside of yourself put thoughts in your mind that were not your own, or made you act in a way that was not your usual self? Have you ever felt that you were possessed?",
                description: "Ask for examples and discount any that are not psychotic.",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                hasComment: true,
                commentText: "Examples",
            },             
            {
                name: "K3-b",
                title: "Regarding the last question, do you currently believe these things?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{k3-a}='Yes'"
            },             
            {
                name: "K4-a",
                title: "Have you ever believed that you were being sent special messages through the TV, radio, internet, newspapers, books, or magazines or that a person you did not personally know was particularly interested in you?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                hasComment: true,
                commentText: "Examples",
            },             
            {
                name: "K4-b",
                title: "Regarding the last question, do you currently believe these things?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{k4-a}='Yes'"
            },             
            {
                name: "K5-a",
                title: "Have your relatives or friends ever considered any of your beliefs odd or unusual?",
                description: "Ask for examples, only code 'Yes' if the examples are clearly delusional ideas not explored in the prior questions. For example: religion, death, disease or somatic delusions, delusions of grandiosity, jealousy or guilt, or of failure, inadequacy, ruin, or destitution, or nihilistic delusions.",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                hasComment: true,
                commentText: "Examples",
            },             
            {
                name: "K5-b",
                title: "Regarding the last question, do they currently consider your beliefs strange or unusual?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{k5-a}='Yes'"
            },             
            {
                name: "K6-a",
                title: "Have you ever heard things other people couldn't hear, such as voices?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                hasComment: true,
                commentText: "Examples",
            },             
            {
                name: "K6-a1",
                title: "Regarding the last question, was the voice commenting on your thoughts or behavior or did you hear two or more voices talking to each other?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{k6-a}='Yes'"
            },             
            {
                name: "K6-b",
                title: "Have you heard sounds / voices in the past month?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{k6-a}='Yes'"
            },             
            {
                name: "K6-b1",
                title: "Regarding the last question, was the voice commenting on your thoughts or behavior or did you hear two or more voices talking to each other?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{k6-b}='Yes'"
            },             
            {
                name: "K7-a",
                title: "Have you ever had visions when you were awake or have you ever seen things other people couldn't see?",
                description: "Check to see if these are culturally inappropriate.",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                hasComment: true,
                commentText: "Examples",
            },             
            {
                name: "K7-b",
                title: "Regarding the last question, have you seen these things in the past month?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{k7-a}='Yes'"
            },             
            {
                name: "K8-a",
                title: "Did the patient ever in the past exhibit disorganized, incoherent or derailed speech, or marked loosening of associations?",
                description: "CLINICIAN'S JUDGMENT",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },             
            {
                name: "K8-b",
                title: "Is the patient currently exhibiting incoherence, disorganized or derailed speech, or marked loosening of associations?",
                description: "CLINICIAN'S JUDGMENT",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },             
            {
                name: "K9-a",
                title: "Did the patient ever in the past exhibit disorganized or catatonic behavior?",
                description: "CLINICIAN'S JUDGMENT",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },             
            {
                name: "K9-b",
                title: "Is the patient currently exhibiting disorganized or catatonic behavior?",
                description: "CLINICIAN'S JUDGMENT",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },             
            {
                name: "K10-a",
                title: "Did the patient ever in the past have negative symptoms of schizophrenia, e.G. Significant reduction of emotional expression or affective flattening, poverty of speech (alogia) or an inability to initiate or persist in goal - directed activities (avolition)?",
                description: "CLINICIAN'S JUDGMENT",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },             
            {
                name: "K10-b",
                title: "Are negative symptoms of schizophrenia, e.G. Significant reduction of emotional expression or affective flattening, poverty of speech (alogia) or an inability to initiate or persist in goal - directed activities (avolition), prominent during the interview?",
                description: "CLINICIAN'S JUDGMENT",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },             
           
           
            // K11 - K14 and Diagnostic box
        ]
    }], 
    showQuestionNumbers: "on",
    cookieName: "k",
}

export const surveyMiniL = {
    pages: [{
        name: "surveyMiniL",
        elements: [
            {
                name: "L1-a",
                title: "How tall are you (cm)?",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                type: "text",    
                inputType: "number", 
            },             
            {
                name: "L1-b",
                title: "What was your lowest weight in the past 3 months (kg)?",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                type: "text",    
                inputType: "number", 
            },             
            {
                name: "L1-c",
                title: "Is patient’s weight equal to or below the threshold corresponding to no yes his/ her height?",
                description: "See height / weight table.",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
            },             
            {
                name: "L2",
                title: "In the past 3 months, in spite of this low weight, have you tried not to gain weight or to restrict your food intake?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{L1-c}='Yes'"
            },             
            {
                name: "L3",
                title: "In the past 3 months, have you intensely feared gaining weight or becoming fat, even though you were under-weight?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{L2}='Yes'"
            },             
            {
                name: "L4-a",
                title: "In the past 3 months, have you considered yourself too big / fat or that part of your body was too big / fat?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{L3}='Yes'"
            },             
            {
                name: "L4-b",
                title: "In the past 3 months, has your body weight or shape greatly influenced how you felt about yourself?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{L3}='Yes'"
            },             
            {
                name: "L4-c",
                title: "In the past 3 months, have you thought that your current low body weight was normal or excessive?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{L3}='Yes'"
            },             
            {
                name: "L5-a",
                title: "Are 1 or more items from questions 6, 7 and 8 coded 'Yes'?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                defaultValue: [
                    "Yes"
                ],
                visibleIf: "{L4-a}='Yes' or {L4-b}='Yes' or {L4-c}='Yes'"
            },             
            {
                name: "L5-b",
                title: "Are 1 or more items from questions 6, 7 and 8 coded 'Yes'?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
                defaultValue: [
                    "No"
                ],
                visibleIf: "{L4-a}='No' and {L4-b}='No' and {L4-c}='No'"
            },             
           
            // Diagnostic box
        ]
    }], 
    showQuestionNumbers: "on",
    cookieName: "l",
}

export const surveyMiniM = {
    pages: [{
        name: "surveyMiniM",
        elements: [
            {
                name: "M1",
                title: "In the past three months, did you have eating binges or times when you ate a very large amount of food within a 2-hour period?",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
            },             
            {
                name: "M2",
                title: "During these binges, did you feel that your eating was out of control?",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{M1}='Yes'"
            },             
            {
                name: "M3",
                title: "In the past 3 months, did you have eating binges as often as once a week?",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{M2}='Yes'"
            },             
            {
                name: "M4",
                title: "Did you do anything to compensate for, or to prevent a weight gain, like vomiting, fasting, exercising or taking laxatives, enemas, diuretics (fluid pills), or other medications? Did you do this as often as once a week?",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{M3}='Yes'"
            },             
            {
                name: "M4-a1",
                title: "Number of Episodes of Inappropriate Compensatory Behaviors per Week?",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                type: "text",    
                inputType: "number", 
                min: 0,
                visibleIf: "{M4}='Yes'"
            },             
            {
                name: "M4-a2",
                title: "Number of Days of Inappropriate Compensatory Behaviors per Week?",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                type: "text",    
                inputType: "number", 
                min: 0,
                visibleIf: "{M4}='Yes'"
            },            
            {
                name: "M5",
                title: "Did your body weight or shape greatly influence how you felt about yourself?",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{M4}='Yes'"
            },   
            {
                name: "M6",
                title: "Do the participant's symptoms meet criteria for anorexia nervosa?",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{M5}='Yes'"
            },   
            {
                name: "M7",
                title: "Did these binges occur only when you were under (______kg)?",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{M6}='Yes'"
            },   

            // M8 and Diagnostic box         

            {
                name: "MB2",
                title: "Do the participant's symptoms meet criteria for bulimia nervosa?",
                description: "Binge eating disorder.",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                indent: 2,
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{M1}='No' or {M1}='Yes'"
            },   
            {
                name: "MB3",
                title: "Is M2 coded 'Yes'?",
                description: "Binge eating disorder.",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                indent: 2,
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                defaultValue: [
                    "Yes"
                ],
                visibleIf: "{M2}='Yes' and {MB2}='No'"
            },   
            {
                name: "MB4",
                title: "Is M3 coded 'Yes'?",
                description: "Binge eating disorder.",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                indent: 2,
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                defaultValue: [
                    "Yes"
                ],
                visibleIf: "{M3}='Yes' and {MB3}='Yes'"
            },   
            {
                name: "MB5",
                title: "Is M4 coded 'Yes'?",
                description: "Binge eating disorder.",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                indent: 2,
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                defaultValue: [
                    "Yes"
                ],
                visibleIf: "{M4}='Yes' and {MB4}='Yes'"
            },   
            {
                name: "MB6-a",
                title: "In the last 3 months during the binging did you eat more rapidly than normal?",
                description: "Binge eating disorder.",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                indent: 2,
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{MB5}='No'"
            },    
            {
                name: "MB6-b",
                title: "In the last 3 months during the binging did you eat until you felt uncomfortably full?",
                description: "Binge eating disorder.",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                indent: 2,
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{M4}='No'"
            },   
            {
                name: "MB6-c",
                title: "In the last 3 months during the binging did you eat large amounts of food when you were not hungry?",
                description: "Binge eating disorder.",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                indent: 2,
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{M4}='No'"
            },   
            {
                name: "MB6-d",
                title: "In the last 3 months during the binging did you eat alone because you felt embarassed about how much you were eating?",
                description: "Binge eating disorder.",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                indent: 2,
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{M4}='No'"
            },   
            {
                name: "MB6-e",
                title: "In the last 3 months during the binging did you feel guilty, depressed or disgusted with yourself after binging?",
                description: "Binge eating disorder.",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                indent: 2,
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{M4}='No'"
            },   
            {
                name: "MB6-f",
                title: "Are 3 or more MB6 questions coded 'Yes'?",
                description: "Binge eating disorder.",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                indent: 2,
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
            },   
            {
                name: "MB7",
                title: "Does your binging distress you a lot?",
                description: "Binge eating disorder.",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                indent: 2,
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{MB6-f}='Yes'"
            },   
            {
                name: "MB8-a",
                title: "Number of Binge Eating Episodes per Week?",
                description: "Binge eating disorder.",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                indent: 2,
                type: "text",    
                inputType: "number", 
                min: 0,
                visibleIf: "{MB7}='Yes'"
            },   
            {
                name: "MB8-a",
                title: "Number of Binge Eating Days per Week?",
                description: "Binge eating disorder.",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                indent: 2,
                type: "text",    
                inputType: "number", 
                min: 0,
                visibleIf: "{MB7}='Yes'"
            },   
            
            // Diagnostic box         

        ]
    }], 
    showQuestionNumbers: "on",
    cookieName: "m",
}

export const surveyMiniN = {
    pages: [{
        name: "surveyMiniN",
        elements: [
            {
                name: "N1-a",
                title: "Were you excessively anxious or worried about several routine things, over the past 6 months?",
                description: " IN ENGLISH, IF THE PATIENT IS UNCLEAR ABOUT WHAT YOU MEAN, PROBE BY ASKING (Do others think that you are a worrier or a 'worry wart'?) AND GET EXAMPLES.",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
            },
            {
                name: "N1-b",
                title: "Are these anxieties and worries present most days?",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{N1-a}='Yes'"
            },
            {
                name: "N2",
                title: "Do you find it difficult to control the worries?",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{N1-b}='Yes'"
            },
            {
                name: "N3-a",
                title: "When you were anxious over the past 6 months, did you, most of the time feel restless, keyed up, or on edge?",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{N2}='Yes'"
            },
            {
                name: "N3-b",
                title: "When you were anxious over the past 6 months, did you, most of the time have muscle tension?",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{N2}='Yes'"
            },
            {
                name: "N3-c",
                title: "When you were anxious over the past 6 months, did you, most of the time feel tired, weak, or exhausted easily?",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{N2}='Yes'"
            },
            {
                name: "N3-d",
                title: "When you were anxious over the past 6 months, did you, most of the time have difficulty concentrating or find your mind going blank?",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{N2}='Yes'"
            },
            {
                name: "N3-e",
                title: "When you were anxious over the past 6 months, did you, most of the time feel irritable?",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{N2}='Yes'"
            },
            {
                name: "N3-f",
                title: "When you were anxious over the past 6 months, did you, most of the time have difficulty sleeping (difficulty falling asleep, waking up in the middle NO YES of the night, early morning wakening, or sleeping excessively)?",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{N2}='Yes'"
            },
            {
                name: "N3-g",
                title: "Are 3 or more N3 answers coded 'Yes'?",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{N2}='Yes'"
            },
            {
                name: "N4",
                title: "Do these anxieties and worries significantly disrupt your ability to work, to function socially, or in your relationships, or in other important areas of your life, or cause you significant distress?",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{N3-g}='Yes'"
            },
            {
                name: "N5",
                title: "Are the participant's anxiety symptoms in N3, or the worries, restricted exclusively to: a depressive disorder; a bipolar disorder; a psycotic disorder?",
                description: "If 'Yes' to any, code 'Yes', if not code 'No'.",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{N4}='Yes'"
            },
            {
                name: "N6",
                title: "Is the anxiety or worry itself sufficiently severe to require special attention beyond the standard treatment for the depressive, bipolar, or psychotic disorder?",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{N5}='Yes'"
            },
            {
                name: "N7",
                title: "Are the patient’s anxiety symptoms in N3, or the worries, restricted exclusively to, or better explained by any psychiatric disorder?",
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                type: "radiogroup",            
                choices: [
                    "Yes",
                    "No",
                ],
                visibleIf: "{N6}='Yes'"
            },

            // Diagnostic box         

        ]
    }], 
    showQuestionNumbers: "on",
    cookieName: "n",
}


export const surveybr23 = {
    pages: [{
        name: "QoL-BR23",
        elements: [
            {
                name: "BR-1",
                title: "Did you have a dry mouth?",
                description: 'During the past week: ',
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "BR-2",
                title: "Did food and drink taste different than usual?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "BR-3",
                title: "Were your eyes painful, irritated or watery?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },  
            {
                name: "BR-4",
                title: "Have you lost any hair?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },  
            {
                name: "BR-5",
                title: "Were you upset by the loss of your hair?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },  
            {
                name: "BR-6",
                title: "Did you feel ill or unwell?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },  
            {
                name: "BR-7",
                title: "Did you have hot flushes?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },  
            {
                name: "BR-8",
                title: "Did you have hot flushes?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },  
            {
                name: "BR-9",
                title: "Have you felt physically less attractive as a result of your disease or treatment?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "BR-10",
                title: "Have you been feeling less feminine as a result of your disease or treatment?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "BR-11",
                title: "Did you find it difficult to look at yourself naked?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "BR-12",
                title: "Have you been dissatisfied with your body?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "BR-13",
                title: "Were you worried about your health in the future?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "BR-14",
                title: "To what extent were you interested in sex?",
                description: 'During the past four weeks: ',
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "BR-15",
                title: "To what extent were you sexually active? (with or without intercourse)?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "BR-16",
                title: "Answer this question only if you have been sexually active: To what extent was sex enjoyable for you?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "BR-17",
                title: "Did you have any pain in your arm or shoulder?",
                description: 'During the past week:',
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "BR-18",
                title: "Did you have a swollen arm or hand?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "BR-19",
                title: "Was it difficult to raise your arm or to move it sideways?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "BR-20",
                title: "Have you had any pain in the area of your affected breast?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "BR-21",
                title: "Was the area of your affected breast swollen?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "BR-22",
                title: "Was the area of your affected breast oversensitive?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "BR-23",
                title: "Have you had skin problems on or in the area of your affected breast (e.g., itchy, dry, flaky)?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            }
        ]
    }], 
    showQuestionNumbers: "on",
    cookieName: "QoL-BR23",
}
export const surveylc29 = {
    pages: [{
        name: "QoL-LC29",
        elements: [
            {
                name: "LC-1",
                title: "Have you coughed?",
                description: 'During the past week: ',
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-2",
                title: "Have you coughed up blood?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-3",
                title: "Have you been short of breath when you rested?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },  
            {
                name: "LC-4",
                title: "Have you been short of breath when you walked?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },  
            {
                name: "LC-5",
                title: "Have you been short of breath when you climbed stairs?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },  
            {
                name: "LC-6",
                title: "Have you had a sore mouth or tongue?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },  
            {
                name: "LC-7",
                title: "Have you had problems swallowing?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },  
            {
                name: "LC-8",
                title: "Have you had tingling hands or feet?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },  
            {
                name: "LC-9",
                title: "Have you had hair loss?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-10",
                title: "Have you had pain in your chest?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-11",
                title: "Have you had pain in your arm or shoulder?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-12",
                title: "Have you had pain in other parts of your body?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-13",
                title: "Have you had allergic reactions?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-14",
                title: "Have you had burning or sore eyes?",
                description: 'During the past four weeks: ',
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-15",
                title: "Have you been dizzy?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-16",
                title: "Have you been dizzy",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-17",
                title: "Have you had skin problems (e.g. itchy, dry)?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-18",
                title: "Have you had problems speaking?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-19",
                title: "Have you been afraid of tumor progression?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-20",
                title: "Have you had thin or lifeless hair as a result of your disease or treatment?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-21",
                title: "Have you worried about your health in the future?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-22",
                title: "Have you had a dry cough?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-23",
                title: "Have you experienced a decrease in your physical capabilities?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-24",
                title: "Has weight loss been a problem for you?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-25",
                title: "Have you had pain in the area of surgery?",
                description: "Please answer the following questions only if you had surgery for lung cancer:",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-26",
                title: "Has the area of your wound been oversensitive?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-27",
                title: "Have you been restricted in your performance due to the extent of surgery?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-28",
                title: "Have you had any difficulty using your arm or shoulder on the side of the chest operation?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-29",
                title: "Has your scar pain interfered with your daily activities?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            }
        ]
    }], 
    showQuestionNumbers: "on",
    cookieName: "QoL-LC29",
}
export const surveyc30 = {
    pages: [{
        name: "C30",
        elements: [
             {
                name: "LC-1",
                title: "Do you have any trouble doing strenuous activities, like carrying a heavy shopping bag or a suitcase?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-2",
                title: "Do you have any trouble taking a long walk?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-3",
                title: "Do you have any trouble taking a short walk outside of the house?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-4",
                title: "Do you need to stay in bed or a chair during the day?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-5",
                title: "Do you need help with eating, dressing, washing yourself or using the toilet?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-6",
                title: "Were you limited in doing either your work or other daily activities?",
                description: 'During the past week: ',
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-7",
                title: "Were you limited in doing either your work or other daily activities?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-8",
                title: "Were you short of breathd?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-9",
                title: "Have you had pain?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-10",
                title: "Did you need to rest?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-11",
                title: "Have you had trouble sleeping?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-12",
                title: "Have you felt weak?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-13",
                title: "Have you lacked appetite?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-14",
                title: "Have you felt nauseated?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-15",
                title: "Have you vomited?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-16",
                title: "Have you been constipated?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-17",
                title: "Have you had diarrhea?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-18",
                title: "Were you tired?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-19",
                title: "Did pain interfere with your daily activities?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-20",
                title: "Have you had difficulty in concentrating on things, like reading a newspaper or watching television?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-21",
                title: "Did you feel tense?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-22",
                title: "Did you worry?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-23",
                title: "Did you feel irritable?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-24",
                title: "Did you feel depressed?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-25",
                title: "Have you had difficulty remembering things?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-26",
                title: "Has your physical condition or medical treatment interfered with your family life?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-27",
                title: "Has your physical condition or medical treatment interfered with your social activities?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-28",
                title: "Has your physical condition or medical treatment caused you financial difficulties?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-29",
                title: "How would you rate your overall health during the past week?",
                description: "For the following questions please circle the number between 1 and 7 that best applies to you",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            },
            {
                name: "LC-30",
                title: "How would you rate your overall quality of life during the past week?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: false,
                requiredErrorText: "*this is required.",
                choices: [
                    "Not at All",
                    "A little",
                    "Quite a bit",
                    "Very much"
                ]
            }
            
        ]
    }], 
    showQuestionNumbers: "on",
    cookieName: "C30",
}

export const surveyConsent = {
    pages: [{
        name: "consent",
        elements: [
            {
                name: "Consent",
                title: "Has the patient signed the informed consent? Acceptance to be followed-up for up to 1 year answering questionnaires and data from device? Acceptance to use and install apps in the smartphone and internet? Acceptance to provide data after project to be re-used for research purposes?",
                type: "radiogroup",            
                clearIfInvisible: "onHidden",
                isRequired: true,
                requiredErrorText: "*this is required.",
                choices: [
                    "Yes",
                    "No",
                ],
               // defaultValue: "No"
                // showClearButton: true
            },
            {
                name: "Date",
                title: "Date of signed informed consent:",
                type: "text",   
                inputType: "date", 
                clearIfInvisible: "onHidden",
                isRequired: true,
                requiredErrorText: "*this is required.",  
               // defaultValue: "2022-01-01"  
            },
            {
                name: "DPR",
                title: "General Data Protection Regulation. Consented to use their data?",
                description: 'Sex - biological variable',
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: true,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                ],
                // showClearButton: true
            },
            {
                name: "Illiteracy",
                title: "Illiteracy or otherwise not understanding the study's instructions?",
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: true,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                ]
            },
            {
                name: "DigitalLiteracy",
                title: "Digital Literacy (able to find, evaluate, gather or compose clear information in digital devices (medical devices, smartphones, etc)?",
                type: "radiogroup",    
                clearIfInvisible: "onHidden",
                isRequired: true,
                requiredErrorText: "*this is required.",    
                choices: [
                    "Yes",
                    "No",
                ]
            }
        ]
    }], 
    showQuestionNumbers: "on",
    cookieName: "consent",
}