import React, { useEffect, useState } from "react";
import { useParams }from 'react-router-dom';
import axios from "axios";
import { CategoryTypesDatabase } from "../../../Assets/enums";
var moment = require('moment');
const awsAPI = require('../../../config.json').awsParticipantsEndpoints;
const debug = require('../../../config.json').debug;

const PassiveMonitoring = (props) => {
  const {patientTrialStartDate} = props;
  const { participantID } = useParams();
  const [DataSleep, setDataSleep] = useState();
  const [DataActivity, setDataActivity] = useState();

  const [threshold, setThreshold] = useState(10)
  let sleep_month = 0;
  let activity_month = 0;

  useEffect(() => {
    const processPassiveMonitoringData = (data, datatype) => {
      let passiveMonitoringDataStructure = [
        {
          month: 0,
          firstHalf: 0,
          secondHalf: 0,
          available: true
        },
        {
          month: 1,
          firstHalf: 0,
          secondHalf: 0,
          available: true
        },
        {
          month: 2,
          firstHalf: 0,
          secondHalf: 0,
          available: true
        },
        {
          month: 3,
          firstHalf: 0,
          secondHalf: 0,
          available: true
        },
        {
          month: 4,
          firstHalf: 0,
          secondHalf: 0,
          available: true
        },
        {
          month: 5,
          firstHalf: 0,
          secondHalf: 0,
          available: true
        },
        {
          month: 6,
          firstHalf: 0,
          secondHalf: 0,
          available: true
        },
        {
          month: 7,
          firstHalf: 0,
          secondHalf: 0,
          available: true
        },
        {
          month: 8,
          firstHalf: 0,
          secondHalf: 0,
          available: true
        },
        {
          month: 9,
          firstHalf: 0,
          secondHalf: 0,
          available: true
        },
        {
          month: 10,
          firstHalf: 0,
          secondHalf: 0,
          available: true
        },
        {
          month: 11,
          firstHalf: 0,
          secondHalf: 0,
          available: true
        },
        {
          month: 12,
          firstHalf: 0,
          secondHalf: 0,
          available: true
        },
      ];

      if (data.length > 0){
          // Fill the months that should have data enries
          const currentMonth = moment().diff(moment(patientTrialStartDate, 'DD/MM/YYYY'), 'months').toString();

          for (var i = Number(currentMonth)+1; i <= 12; i++){
            passiveMonitoringDataStructure[i].available = false;
          }

          data.forEach((entry) => {
            const entryDate = CategoryTypesDatabase.Sleep === datatype ? entry.date : entry.record_date;               // the passive monitoring has its dates on different variables

            const diffMonths = moment(entryDate, 'YYYY/MM/DD').diff(moment(patientTrialStartDate, 'DD/MM/YYYY'), 'months').toString();

            const startCurrentMonth = moment(patientTrialStartDate, 'DD/MM/YYYY').add(diffMonths, 'M').format('YYYY/MM/DD').toString();
            const diffDaysOfMonth = moment(entryDate, 'YYYY/MM/DD').diff(moment(startCurrentMonth, 'YYYY/MM/DD'), 'days').toString();
          
            // Data handling - insert into the passive monitoring data structure
            if (diffMonths <= 12){
              if (diffDaysOfMonth < 15){
                passiveMonitoringDataStructure[diffMonths].firstHalf++;
              } else {
                passiveMonitoringDataStructure[diffMonths].secondHalf++;
              }
            }

            /*
            Testing logs
            console.log("diffMonths ", diffMonths)
            console.log("x ", x)

            console.log("difference of days of the month: ", as)

            console.log("month number: ", startCurrentMonth)
            */
          })
      }

      return passiveMonitoringDataStructure;
    }

    const fetchDataSleep = async () => {
      try {
        const url = awsAPI.awsClinitianInterfaceAPI+"/"+awsAPI.participantsList+"/"+participantID+"/"+awsAPI.participantCategory.Sleep;

        const response = await axios.get(url);

        setDataSleep(processPassiveMonitoringData(response.data, CategoryTypesDatabase.Sleep));
      } catch (error) {
        console.log('Error fetching sleep data:', error);
      }
    }

    const fetchDataActivity = async () => {
      try {
        const url = awsAPI.awsClinitianInterfaceAPI+"/"+awsAPI.participantsList+"/"+participantID+"/"+awsAPI.participantCategory.Activity;

        const response = await axios.get(url);

        setDataActivity(processPassiveMonitoringData(response.data, CategoryTypesDatabase.Activity));
      } catch (error) {
        console.log('Error fetching data:', error);
      }
    }

    fetchDataSleep();
    fetchDataActivity();
  }, []);
  
  return (
    <>
    <div style={{ flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ textAlign: 'left', fontSize: '21px' }}>Passive monitoring: </div>

        <div style={{ textAlign: 'right', display: 'inline-block' }}>
          <span
            style={{
              width: '15px',
              height: '15px',
              background: '#8AC926',
              borderRadius: '5px',
              display: 'inline-block',
              marginRight: '5px',
            }}
          ></span>
          <span style={{ marginRight: '5px' }}>Complete ({threshold+1} - 15)</span>
          <span
            style={{
              width: '15px',
              height: '15px',
              background: '#FFCA3A',
              borderRadius: '5px',
              display: 'inline-block',
              marginLeft: '15px',
              marginRight: '5px',
            }}
          ></span>
          <span style={{ marginRight: '5px' }}>Incomplete (1 - {threshold})</span>
          <span
            style={{
              width: '15px',
              height: '15px',
              background: '#FF595E',
              borderRadius: '5px',
              display: 'inline-block',
              marginLeft: '15px',
              marginRight: '5px',
            }}
          ></span>
          <span style={{ marginRight: '5px' }}>Not submitted (0)</span>
        </div>
      </div> 

      <div className="Group">
        <div className="Name">Sleep</div>
        <div className="Meses">
        <>
          {
              DataSleep && DataSleep.map((month, idxHeader) => (
                <div className="Mes" key={"Header1"+idxHeader} id={"A"+idxHeader}>
                  <div className="Mes_Name">M{sleep_month++}</div>
                  <div className="Month_group">
                    {(() => {
                      if (month.available){
                        if (month.firstHalf > 0) {
                          if (month.firstHalf > threshold) {
                            return (
                              <div className="half_month_data" />
                            )
                          } else {
                            return (
                              <div className="half_month_pending" />
                            )
                          }
                        } else {
                          return (
                            <div className="half_month_missing" />
                          )
                        }
                      } else {
                           return (
                            <div className="half_month" />
                          )
                      }


                    })()}
                    {(() => {
                      if (month.available){
                        if (month.secondHalf > 0) {
                          if (month.secondHalf > threshold) {
                            return (
                              <div className="half_month_data" />
                            )
                          } else {
                            return (
                              <div className="half_month_pending" />
                            )
                          }
                        } else {
                          return (
                            <div className="half_month_missing" />
                          )
                        }
                      } else {
                        return (
                            <div className="half_month" />
                        )
                      }
                    })()}
                  </div>
                </div>
              ))
              }
        </>
        </div>
      </div>

      <div className="Group">
        <div className="Name">Activity</div>
        <div className="Meses">
        <>
          {
              DataActivity && DataActivity.map((month, idxHeader) => (
                <div className="Mes" key={"Header2"+idxHeader} id={"B"+idxHeader}>
                  <div className="Mes_Name">M{activity_month++}</div>
                  <div className="Month_group">
                    {(() => {
                      if (month.available){
                          if (month.firstHalf > 0) {
                            if (month.firstHalf > threshold) {
                              return (
                                <div className="half_month_data" />
                              )
                            } else {
                              return (
                                <div className="half_month_pending" />
                              )
                            }
                          } else {
                            return (
                              <div className="half_month_missing" />
                            )
                          }
                      } else {
                        return (
                            <div className="half_month" />
                        )
                      }
                    })()}
                    {(() => {
                      if (month.available){
                      if (month.secondHalf > 0) {
                        if (month.secondHalf > threshold) {
                          return (
                            <div className="half_month_data" />
                          )
                        } else {
                          return (
                            <div className="half_month_pending" />
                          )
                        }
                      } else {
                        return (
                          <div className="half_month_missing" />
                        )
                      }
                    } else {
                        return (
                            <div className="half_month" />
                        ) 
                    }
                    })()}
                  </div>
                </div>
              ))}
        </>
        </div>
      </div>
    </div>


    <style>{`
      .PassiveMonitoring{
        // background: white;
        // border-radius: 0.5rem;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .Group{
        display: flex;
        margin-top: 10px;
      }
      .Name{
        display: flex;
        align-items: center;
        width: 80px;
        padding-top: 15px;
        font-weight: bold;
      }
      .Meses{
        display: flex;
        width: 100%;
      }
      .Mes{
        margin: auto;
      }
      .Mes_Name{
        text-align: center;
      }
      .Month_group{
        display: flex;
      }
      .half_month {
        border-radius: 5px;
        background-color: var(--neutral-grey-200, #d9d9d9);
        display: flex;
        height: 42px;
        width: 27px;
        flex-direction: column;
        flex: 1;
        margin: 2px;
      }
      .half_month_data {
        border-radius: 5px;
        background-color: rgb(138, 201, 38);
        display: flex;
        height: 42px;
        width: 27px;
        flex-direction: column;
        flex: 1;
        margin: 2px;
      }
      .half_month_pending {
        border-radius: 5px;
        background-color: rgb(255, 202, 58);
        display: flex;
        height: 42px;
        width: 27px;
        flex-direction: column;
        flex: 1;
        margin: 2px;
      }
      .half_month_missing {
        border-radius: 5px;
        background-color: rgb(255, 89, 94);
        display: flex;
        height: 42px;
        width: 27px;
        flex-direction: column;
        flex: 1;
        margin: 2px;
      }
    `}</style>
    </>
  )
}

export default PassiveMonitoring;