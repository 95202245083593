import React, { useEffect, useState } from "react";
import { fetchData } from "../../Utilities/utils";
import moment from "moment";
import ActivityChart from "./ActivityChart";

const awsAPI = require('../../../config.json').awsParticipantsEndpoints;

const ActivityInfo = ({participantID}) => {
  const oneMonth = 30;
  const threeMonths = 90;
  const oneYear = 365;
  const periodOptions = [{"One month": oneMonth}, {"Three months": threeMonths}, {"One year": oneYear}]
  const [patientActivityData, setPatientActivityData] = useState();
  const [dataGraph, setDataGraph] = useState();
  const [threshold, setThreshold] = useState(oneYear)
  const [lastdata, setLastData] = useState();

  useEffect(() => {
    const fetchDataActivity = async () => {
      try {
        setPatientActivityData(await fetchData(`${awsAPI.awsClinitianInterfaceAPI}/${awsAPI.participantsList}/${participantID}/${awsAPI.participantCategory.Activity}`));
      } catch (error) {
          console.log('Error fetching data:', error);
      }
    }

    fetchDataActivity();
  },[])

  useEffect(() => {
    if(patientActivityData && patientActivityData.length>0){
        console.log(patientActivityData)
        let graph = [];

        const startDateThreshold = new moment(patientActivityData[0].record_date).subtract(threshold, "days").valueOf();

        setLastData(patientActivityData[0]);

        patientActivityData.forEach((activityEntry) => {
            const activityEntryDate = new moment(activityEntry.record_date).valueOf();

            if(activityEntryDate > startDateThreshold){
                graph.push({
                 date: activityEntry.record_date,
                 steps: activityEntry.steps,
                });
            }
        });

        setDataGraph(graph);
    }
  }, [patientActivityData && threshold])

  // Function to handle activity month selection
  const handleOptionClick = (option) => {
    setThreshold(option);
  };

  return (
    <div>
    {patientActivityData && patientActivityData.length>0 ? 
      <div style={{flexGrow: 1, display: "flex", flexDirection: "column"}}>
        <div style={{flexDirection: "colum"}}>
            <div className="table-intro" style={{justifyContent: 'space-between'}}>
              <strong><p>Last {threshold} days of activity</p></strong>
              <select onChange={(e) => handleOptionClick(e.target.value)} value={threshold}>
                  <option value="">Select an option</option>
                  {periodOptions.map((option) => (
                    <option key={Object.keys(option)} value={Object.values(option)}>{Object.keys(option)}</option>
                  ))}
              </select>
            </div>
            <div style={{flexGrow: 1, display: "flex", flexDirection: "colum", overflowY: "hidden"}}>
              <ActivityChart data={dataGraph}/>
            </div>
        </div>
      </div>
    : <></>
    }
    </div>
  )
}

export default ActivityInfo;