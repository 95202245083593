import App from './App';
import './index.css';
import React from 'react';
import { Authenticator} from '@aws-amplify/ui-react';
import { createRoot } from 'react-dom/client';
const domNode = document.getElementById('root');
const root = createRoot(domNode);

root.render(
  //<React.StrictMode> Enable StrickMode for debug or development
    <Authenticator.Provider>
      <App />
    </Authenticator.Provider>
  //</React.StrictMode> 
);