import React from 'react';
import Header from '../Header/Header';
import ProgressBar from '../Header/ProgressBar';
import Alert from '../Header/Alert';
import RemotePatients from '../Patients/Patients';

const Dashboard = () => {
  return (
    <div>
      <Header/>
      <ProgressBar /> 
      {/* <Alert title='Conduct interviews and HAM-D' description='Upcoming interview in the week 13 - 17 April.' button_text='ADD HAM+D' /> */}
      <RemotePatients/>
    </div>
  )
};

export default Dashboard;
