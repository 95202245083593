import React from 'react';
import { Routes, Route, Navigate  }from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import PatientsContainer from '../Patients/PatientsContainer';
import Data from '../Dashboard/Data/Data';
import Crf from '../Patients/Questionnaires/Crf/Crf';
import QuestionnairesClinitianInterface from '../../components/Patients/Questionnaires/QuestionnairesClinitianInterface';
import RemotePatients from '../Patients/Patients';
import Overview from '../Overview/Overview';
import Alarms from '../Patients/Alarms';


const Container = () => {
  return (
        <div className='container'>
            <Routes>
                <Route path="/" element={<Navigate to="/dashboard" />}/>
                <Route exact path="/dashboard" element={<Dashboard />} />                 {/* All Participants with latest interaction with the app, for all categories*/}
                               
                <Route path="/remote" element={<RemotePatients />} />

                {/* PATIENTS */}
                <Route path="/patient/:participantID" element={<PatientsContainer />} />
                <Route path="/patient/:participantID/:sub" element={<PatientsContainer />} />

                <Route exact path="/patient/:participantID/questionnaires" element={<QuestionnairesClinitianInterface/>} /> {/* CRFs and Questionnaires */ }

                <Route path="/patient/:participantID/questionnaires/crf" element={<Crf />} />
                <Route path="/patient/:participantID/questionnaires/hdrs" element={<Crf />} />

                <Route path='/overview' element={<Overview/>}/>
                <Route path='/alarms' element={<Alarms/>}/>
                { /* <Route path="/dashboard/patient/qol" element={<QoL />} /> */}

                {/* Depricated/Not-going-to-be-used. To erase in the Future when they are not necessary */}
                {/*
                    <Route path="/dashboard/dashboard-data" element={<Data />} />             General overview of the project
                */}
            </Routes>
        </div>
  )
};
export default Container;