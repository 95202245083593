import React from 'react';
import ProgressBar from '../../Header/ProgressBar';
import {
    CircularProgressbar,
    buildStyles
  } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ArrowDown from '../../../Assets/Icons/arrow-down-main.svg';
import RedBub from '../../../Assets/img/red-bub.svg';
import LightBub from '../../../Assets/img/light-bub.svg';
import InterviewsHADS from './InterviewsHADS';

const Data = () => {
    const percentage = 66;

  return (
    <div>
        <ProgressBar type={'day'}/>
        <div className='flex-bet mt'>
          <div className='mt-3'>
            <h1 className='title dark-blue-txt'>1st September 2021</h1>
            <p className='x-small-txt dark-blue-txt mb'>Thursday, 30th</p>
          </div>
        </div>
        <div className='flex-bet'> 
            <div className='middle-container p-1 mt-1' style={{width: '20vw', boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 3px 0px'}}>
                <h4 className='medium-bold-txt'>Nutrition</h4>
                <p className='x-small blue-txt mb'>Nr of self assessed questionnaires</p>
                <div className='flex-around center p-0'>
                    <div style={{padding: '0 8px'}}>
                        <CircularProgressbar
                        value={percentage}
                        circleRatio={0.75}
                        styles={buildStyles({
                            rotation: 1 / 2 + 1 / 8,
                            textColor: '#CCCCCC',
                            pathColor: "#00BEAA",
                            trailColor: "#C9CED4"

                        })}
                        />
                        <h5 className='xx-small-txt'>PG-GSA </h5>
                        <p className='x-small-bold-txt'><span className='main-txt'>57</span> /100</p>
                    </div>
                    <div style={{backgroundColor:'#E5E5E5', width: '1px'}}></div>
                    <div style={{padding: '0 8px'}}>
                        <CircularProgressbar
                            value={percentage}
                            circleRatio={0.75}
                            styles={buildStyles({
                                rotation: 1 / 2 + 1 / 8,
                                textColor: '#CCCCCC',
                                pathColor: "#00BEAA",
                                trailColor: "#C9CED4"
                        })}
                        />
                        <h5 className='xx-small-txt'>FFQ</h5>
                        <p className='x-small-bold-txt'><span className='main-txt'>88</span> / 100</p>
                    </div>
                    <div style={{backgroundColor:'#E5E5E5', width: '1px'}}></div>
                    <div style={{padding: '0 8px'}}>
                        <CircularProgressbar
                            value={percentage}
                            circleRatio={0.75}
                            styles={buildStyles({
                                rotation: 1 / 2 + 1 / 8,
                                textColor: '#CCCCCC',
                                pathColor: "#00BEAA",
                                trailColor: "#C9CED4"
                        })}
                        />
                        <h5 className='xx-small-txt'>DEB</h5>
                        <p className='x-small-bold-txt'><span className='main-txt'>91</span> /100</p>
                    </div>
                </div>
            </div>
            <div className='middle-container p-1 mt-1' style={{width: '17vw', boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 3px 0px'}}>
                <h4 className='medium-bold-txt'>Outlook</h4>
                <p className='x-small blue-txt mb'>Nr of self assessed questionnaires</p>
                <div className='flex-around center p-0'>
                    <div style={{padding: '0 20px'}}>
                        <CircularProgressbar
                        value={percentage}
                        circleRatio={0.75}
                        styles={buildStyles({
                            rotation: 1 / 2 + 1 / 8,
                            textColor: '#CCCCCC',
                            pathColor: "#54BBFF",
                            trailColor: "#C9CED4"

                        })}
                        />
                        <h5 className='xx-small-txt'>HA </h5>
                        <p className='x-small-bold-txt'><span className='main-txt'>91</span> /100</p>
                    </div>
                    <div style={{backgroundColor:'#E5E5E5', width: '1px'}}></div>
                    <div style={{padding: '0 20px'}}>
                        <CircularProgressbar
                            value={percentage}
                            circleRatio={0.75}
                            styles={buildStyles({
                                rotation: 1 / 2 + 1 / 8,
                                textColor: '#CCCCCC',
                                pathColor: "#54BBFF",
                                trailColor: "#C9CED4"
                        })}
                        />
                        <h5 className='xx-small-txt'>QLQ</h5>
                        <p className='x-small-bold-txt'><span className='main-txt'>60</span> / 100</p>
                    </div>
                </div>
            </div>
            <div className='middle-container p-1 mt-1' style={{width: '16.5vw', boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 3px 0px'}}>
                <h4 className='medium-bold-txt'>Sleep</h4>
                <p className='x-small blue-txt mb'>Nr of self assessed questionnaires</p>
                <div className='flex-around center p-0'>
                    <div style={{padding: '0 70px'}}>
                        <CircularProgressbar
                        value={percentage}
                        text={`${percentage}%`}
                        circleRatio={0.75}
                        styles={buildStyles({
                            rotation: 1 / 2 + 1 / 8,
                            textColor: '#CCCCCC',
                            pathColor: "#FE93D3",
                            trailColor: "#C9CED4"

                        })}
                        />
                        <h5 className='xx-small-txt'>PGQI </h5>
                        <p className='x-small-bold-txt'><span className='main-txt'>90</span> /100</p>
                    </div>
                </div>
            </div>
            <div className='middle-container p-1 mt-1' style={{width: '20vw', boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 3px 0px'}}>
                <h4 className='medium-bold-txt'>Voice</h4>
                <p className='x-small blue-txt mb'>Records provide by workers.</p>
                <div className='flex-around center p-0'>
                    <div style={{padding: '0 70px', height:'110px', width: '110px', border: '2px solid #4FC3C3', borderRadius: '100%'}}>
                        <h5 className='x-large bold' style={{position: 'relative', top: '20%'}}>13 </h5>
                        <p className='x-small' style={{position: 'relative', top: '17%'}}>New Vocal Records</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='middle-container p-1 mt-2' style={{width: '100%', boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 3px 0px'}}>
            <div>
                <p className='xx-large dark-blue-txt'>Live Monitoring</p>
                <p className='small blue-txt mb'>Data collected through continuous background monitoring from Faith App</p>
            </div>
            <div className='flex-bet' style={{width: '90%'}}>
                <div >
                    <h4 className='medium-bold-txt'>Activity</h4>
                    <p className='x-small blue-txt mb'>Today <img className='x-small-icon ms-0' src={ArrowDown}/></p>
                    <div className='flex-bet'>
                        <div className='flex-bet' style={{width: '100%'}}>
                            <div>
                                <div className='flex-center'><div style={{height: '13px', width: '13px', backgroundColor: '#EA6061', borderRadius: '100%', marginRight: '10px'}}></div><p>87 Activities collected</p></div>
                                <div className='flex-center'><div style={{height: '13px', width: '13px', backgroundColor: '#D8D8D8', borderRadius: '100%', marginRight: '10px'}}></div><p>13 Missing users</p></div>
                            </div>
                            <img src={RedBub} style={{width: '214px', marginLeft: '50px'}}/>
                        </div>
                    </div>
                </div>
                <div style={{borderLeft: '1px solid #CCCCCC', paddingLeft: '20px'}}>
                    <h4 className='medium-bold-txt'>Sleep</h4>
                    <p className='x-small blue-txt mb'>Today <img className='x-small-icon ms-0' src={ArrowDown}/></p>
                    <div className='flex-bet'>
                        <div className='flex-bet' style={{width: '100%'}}>
                            <div>
                                <div className='flex-center'><div style={{height: '13px', width: '13px', backgroundColor: '#B3DAFE', borderRadius: '100%', marginRight: '10px'}}></div><p>87 Activities collected</p></div>
                                <div className='flex-center'><div style={{height: '13px', width: '13px', backgroundColor: '#D8D8D8', borderRadius: '100%', marginRight: '10px'}}></div><p>13 Missing users</p></div>
                            </div>
                            <img src={LightBub} style={{width: '214px', marginLeft: '50px'}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <InterviewsHADS/>
    </div>
  )
}


export default Data