import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {fetchData} from '../../components/Utilities/utils';
import DataTable from 'react-data-table-component';
import moment from 'moment';
const awsClinitianInterfaceAPI = require('../../config.json').awsParticipantsEndpoints.awsClinitianInterfaceAPI;
const participantsListUrl = require('../../config.json').awsParticipantsEndpoints.participantsList;
const CategoryTypesDatabase = require ("../../Assets/enums").CategoryTypesDatabase;
const questionnaireFrequency = require('../../Assets/questionnaire_frequency.json');

//converts epoc timestampo into DD-MM-YYYY
const dateToDisplayString = (timestamp) => {
    return timestamp ? new Date(timestamp).toLocaleDateString() : '-';
}

//some FFQ timestamp is in seconds and others are in mimilliseconds
const dateFFQToDisplayString = (timestamp) => {
    if (timestamp) {
        const epochTime = new Date(timestamp);

        if (epochTime.getYear() < 100){                           //epoch value is in seconds
            const epochSeconds = new Date(timestamp * 1000);

            return epochSeconds.toLocaleDateString();
        } else {
            return epochTime.toLocaleDateString();
        }
    } else {
        return '-'
    }
}

export const frequencyQuestionnaire = (questionnaireTpe) => {
    switch (questionnaireTpe) {
        case CategoryTypesDatabase['Basal']:
            return questionnaireFrequency.BASAL_QUESTIONNAIRE;
        case CategoryTypesDatabase['DEBQ%20Questionnaire']:
            return (questionnaireFrequency.DEBQ_QUESTIONNAIRE);
        case CategoryTypesDatabase['FFQ%20Questionnaire']:
            return (questionnaireFrequency.FFQ);
        case CategoryTypesDatabase['HADS%20Questionnaire']:
            return (questionnaireFrequency.HADS_QUESTIONNAIRE);
        case CategoryTypesDatabase['PGSGA%20Questionnaire']:
            return (questionnaireFrequency.PGSGA);
        case CategoryTypesDatabase['PSQI%20Questionnaire']:
            return (questionnaireFrequency.PSQI_QUESTIONNAIRE);
        case CategoryTypesDatabase['QLQ_C30%20Questionnaire']:
            return (questionnaireFrequency.QLQ_C30_QUESTIONNAIRE);
        case CategoryTypesDatabase['QLQ_BR23%20Questionnaire']:
            return (questionnaireFrequency.QLQ_BR23_QUESTIONNAIRE);
        case CategoryTypesDatabase['QLQ_LC29%20Questionnaire']:
            return (questionnaireFrequency.QLQ_LC29_QUESTIONNAIRE );
        case CategoryTypesDatabase['Voice']:
            return questionnaireFrequency.QUICK_CHAT_FREQUENCY;
        case CategoryTypesDatabase['Weight']:
            return questionnaireFrequency.WEIGHT_QUESTION;
        default:
            return null;
    }
}

//consider the end of the trial for each patient in order to put in black color
//TODO Should include the 7 day period which the questionnaire is available?
//TODO condition of QLQ type questionnaires
//TODO consider the incompleted questionnaires
const hasMissingQuestionnaire = (date, questionnaireTpe) => {
    const questionnaireFrequencyDays = frequencyQuestionnaire(questionnaireTpe);
    const diff_days = moment().diff(moment(date, "DD/MM/YYYY"), 'days').toLocaleString();

    if(diff_days < questionnaireFrequencyDays || date === null){
        return false;
    } else {
        return true;
    }
}

const columns = [
	{
		name: <div style={{textAlign: 'center'}}>{'Email'}</div>,
        cell: row => (
                <Link to = {`/patient/${row.participantID}`} state={{email: row.email}} >
                    <span style={{'wordBreak':'normal', 'textTransform': 'lowercase'}} className='blue-txt pointer'>{row.email}</span>
               </Link>
		),
        width:"200px",
	},
    {
		center: "true",
        name: <div style={{textAlign: 'center'}}>{[CategoryTypesDatabase['Activity']]}</div>,
        selector: row => <div style={{textAlign:"right"}}>{dateToDisplayString(row.categoryLastInteraction?.[CategoryTypesDatabase['Activity']])}</div>,
    },
    {
        center: "true",
		name: <div style={{textAlign: 'center'}}>{[CategoryTypesDatabase['Basal']]}</div>,
        selector: row => <div style={{color: hasMissingQuestionnaire(dateToDisplayString(row.categoryLastInteraction?.Basal), CategoryTypesDatabase['Basal']) ? 'red': 'black'}}>{dateToDisplayString(row.categoryLastInteraction?.Basal)}</div>,
    },
    {
        center: "true",
		name: <div style={{textAlign: 'center'}}>{[CategoryTypesDatabase['DEBQ%20Questionnaire']]}</div>,
        selector: row => <div style={{color: hasMissingQuestionnaire(dateToDisplayString(row.categoryLastInteraction?.[CategoryTypesDatabase['DEBQ%20Questionnaire']]), CategoryTypesDatabase['DEBQ%20Questionnaire'], row.email) ? 'red': 'black'}}>{dateToDisplayString(row.categoryLastInteraction?.[CategoryTypesDatabase['DEBQ%20Questionnaire']])}</div>,
    },
    {
        center: "true",
		name: <div style={{textAlign: 'center'}}>{[CategoryTypesDatabase['FFQ%20Questionnaire']]}</div>,
        selector: row => <div style={{color: hasMissingQuestionnaire(dateToDisplayString(row.categoryLastInteraction?.[CategoryTypesDatabase['FFQ%20Questionnaire']]), CategoryTypesDatabase['FFQ%20Questionnaire'], row.email) ? 'red': 'black'}}>{dateFFQToDisplayString(row.categoryLastInteraction?.[CategoryTypesDatabase['FFQ%20Questionnaire']])}</div>,
    },
    {
        center: "true",
		name: <div style={{textAlign: 'center'}}>{[CategoryTypesDatabase['HADS%20Questionnaire']]}</div>,
        selector: row => <div style={{color: hasMissingQuestionnaire(dateToDisplayString(row.categoryLastInteraction?.[CategoryTypesDatabase['HADS%20Questionnaire']]), CategoryTypesDatabase['HADS%20Questionnaire'], row.email) ? 'red': 'black'}}>{dateToDisplayString(row.categoryLastInteraction?.[CategoryTypesDatabase['HADS%20Questionnaire']])}</div>,
    },
    {
        center: "true",
		name: <div style={{textAlign: 'center'}}>{[CategoryTypesDatabase['PGSGA%20Questionnaire']]}</div>,
        selector: row => <div style={{color: hasMissingQuestionnaire(dateToDisplayString(row.categoryLastInteraction?.[CategoryTypesDatabase['PGSGA%20Questionnaire']]), CategoryTypesDatabase['PGSGA%20Questionnaire'], row.email) ? 'red': 'black'}}>{dateToDisplayString(row.categoryLastInteraction?.[CategoryTypesDatabase['PGSGA%20Questionnaire']])}</div>,
    },
    {
        center: "true",
		name: <div style={{textAlign: 'center'}}>{[CategoryTypesDatabase['PSQI%20Questionnaire']]}</div>,
        selector: row => <div style={{color: hasMissingQuestionnaire(dateToDisplayString(row.categoryLastInteraction?.[CategoryTypesDatabase['PSQI%20Questionnaire']]), CategoryTypesDatabase['PSQI%20Questionnaire'], row.email) ? 'red': 'black'}}>{dateToDisplayString(row.categoryLastInteraction?.[CategoryTypesDatabase['PSQI%20Questionnaire']])}</div>,
    },
    {
        center: "true",
		name: <div style={{textAlign: 'center'}}>{[CategoryTypesDatabase['QLQ_C30%20Questionnaire']]}</div>,
        selector: row => <div style={{color: hasMissingQuestionnaire(dateToDisplayString(row.categoryLastInteraction?.[CategoryTypesDatabase['QLQ_C30%20Questionnaire']]), CategoryTypesDatabase['QLQ_C30%20Questionnaire'], row.email) ? 'red': 'black'}}>{dateToDisplayString(row.categoryLastInteraction?.[CategoryTypesDatabase['QLQ_C30%20Questionnaire']])}</div>,
    },
    {
        center: "true",
		name: <div style={{textAlign: 'center'}}>{[CategoryTypesDatabase['QLQ_BR23%20Questionnaire']]}</div>,
        selector: row => <div style={{color: hasMissingQuestionnaire(dateToDisplayString(row.categoryLastInteraction?.[CategoryTypesDatabase['QLQ_BR23%20Questionnaire']]), CategoryTypesDatabase['QLQ_BR23%20Questionnaire'], row.email) ? 'red': 'black'}}>{dateToDisplayString(row.categoryLastInteraction?.[CategoryTypesDatabase['QLQ_BR23%20Questionnaire']])}</div>,
    },
    {
        center: "true",
		name: <div style={{textAlign: 'center'}}>{[CategoryTypesDatabase['QLQ_LC29%20Questionnaire']]}</div>,
        selector: row => <div style={{color: hasMissingQuestionnaire(dateToDisplayString(row.categoryLastInteraction?.[CategoryTypesDatabase['QLQ_LC29%20Questionnaire']]), CategoryTypesDatabase['QLQ_LC29%20Questionnaire'], row.email) ? 'red': 'black'}}>{dateToDisplayString(row.categoryLastInteraction?.[CategoryTypesDatabase['QLQ_LC29%20Questionnaire']])}</div>,
    },
    {
        center: "true",
		name: <div style={{textAlign: 'center'}}>{[CategoryTypesDatabase['Sleep']]}</div>,
        selector: row => <div style={{}}>{dateToDisplayString(row.categoryLastInteraction?.[CategoryTypesDatabase['Sleep']])}</div>,
    },
    {
        center: "true",
		name: <div style={{textAlign: 'center'}}>{[CategoryTypesDatabase['Sleep%20Initialization']]}</div>,
        selector: row => <div style={{}}>{dateToDisplayString(row.categoryLastInteraction?.[CategoryTypesDatabase['Sleep%20Initialization']])}</div>,
        
    },
    {
        center: "true",
		name: <div style={{textAlign: 'center'}}>{[CategoryTypesDatabase['Voice']]}</div>,
        selector: row => <div style={{color: hasMissingQuestionnaire(dateToDisplayString(row.categoryLastInteraction?.[CategoryTypesDatabase['Voice']]), CategoryTypesDatabase['Voice'], row.email) ? 'red': 'black'}}>{dateToDisplayString(row.categoryLastInteraction?.[CategoryTypesDatabase['Voice']])}</div>,
    },
    {
        center: "true",
		name: <div style={{textAlign: 'center'}}>{[CategoryTypesDatabase['Water%20intake']]}</div>,
        selector: row => <div style={{}}>{dateToDisplayString(row.categoryLastInteraction?.[CategoryTypesDatabase['Water%20intake']])}</div>,
    },
    {
        center: "true",
		name: <div style={{textAlign: 'center'}}>{[CategoryTypesDatabase['Weight']]}</div>,
        selector: row => <div style={{color: hasMissingQuestionnaire(dateToDisplayString(row.categoryLastInteraction?.[CategoryTypesDatabase['Weight']]), CategoryTypesDatabase['Weight'], row.email) ? 'red': 'black'}}>{dateToDisplayString(row.categoryLastInteraction?.[CategoryTypesDatabase['Weight']])}</div>,
    },
];

const RemotePatients = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);

	useEffect(() => {
        async function getStartupPage(){
            const total = await fetchData(`${awsClinitianInterfaceAPI}/numberParticipants`);
            setTotalRows(total);
            fetchParticipants(1);
        }

        getStartupPage();
	}, []);

    const fetchParticipants = async page => {
        setLoading(true);
        const response = await fetchData(`${awsClinitianInterfaceAPI}/${participantsListUrl}?pagenumber=${page}&size=${perPage}`);
        setData(response);
        setLoading(false);
  };

	const handlePageChange = page => {
        fetchParticipants(page);
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		setLoading(true);

		const response = await fetchData(`${awsClinitianInterfaceAPI}/${participantsListUrl}?pagenumber=${page}&size=${newPerPage}`);
		setData(response);
		setPerPage(newPerPage);
		setLoading(false);
	};

	return (
        <div>
            <div className='flex-bet mt mb'>
                {/* <div>
                    <h2 className='title dark-blue-txt'>Patients List</h2>
                </div> */}
            </div>
            <div style={{padding: '10px', 'border': '1px solid #D9D9D9', 'background': 'rgb(229, 229, 229)', 'borderRadius': '5px'}}>
                <div className='flex-bet py' style={{width: '90%'}}>
                    <div>
                        <h3>All patients</h3>
                    </div>
                </div>
            </div>
                <div style={{padding: '10px', 'border': '1px solid #D9D9D9', 'background': 'white', 'borderRadius': '5px'}}>
                    <DataTable
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        noDataComponent="No data to be displayed"
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        customStyles={customStyles}
                    />
                </div>
        </div>
	);
};

export default RemotePatients;

const customStyles = {
    rows: {
        style: {
        },
    },
    headCells: {
        style: {
           
        },
    },
    cells: {
        style: {
            width:"120px",
            sortable: false,
        },
    },
};