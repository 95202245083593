import React, { useState, useEffect } from 'react';
import MiniA from './MiniA';
import MiniB from './MiniB';
import MiniC from './MiniC';
import MiniD from './MiniD';
import MiniE from './MiniE';
import MiniF from './MiniF';
import MiniG from './MiniG';
import MiniH from './MiniH';
import MiniI from './MiniI';
import MiniJ from './MiniJ';
import MiniK from './MiniK';
import MiniL from './MiniL';
import MiniM from './MiniM';
import MiniN from './MiniN';


const Mini = ({participantID})  => {
  const [ activeTab, setActiveTab ] = useState(1);

  const tabsEn = [
    {
        title: 'A',
        id: 1
    },
    {
        title: 'B',
        id: 2
    },
    {
        title: 'C',
        id: 3
    },
    {
        title: 'D',
        id: 4
    },
    {
        title: 'E',
        id: 5
    },
    {
        title: 'F',
        id: 6
    },
    {
        title: 'G',
        id: 7
    },
    {
        title: 'H',
        id: 8
    },
    {
        title: 'I',
        id: 9
    },
    {
        title: 'J',
        id: 10
    },
    {
        title: 'K',
        id:11
    },
    {
        title: 'L',
        id: 12
    },
    {
        title: 'M',
        id: 13
    },
    {
        title: 'N',
        id: 14
    }
]

  const nextSection = (data) => {
    setActiveTab(data); 
  }

  const handleClick = (e) => {

    nextSection(parseInt(e.target.id))
  }


  return (
    <div>
      <div style={{paddingBottom: '10px', position: 'absolute', left: '-100px'}} >
            <ul className='flex-3 mt-2' style={{maxWidth: '600px'}} >
                {tabsEn.map((item, index) => {
                    return(
                        <li id={item.id} className={item.id === activeTab ? 'medium x-bold pointer' : 'medium x-bold pointer light-grey-txt tab-hover'} key={index} onClick={handleClick} >{item.title}</li>
                    )
                })}
            </ul>   
        </div>
        {activeTab === 1 ?
        <div style={{position: 'relative'}}>
            <MiniA participantID={participantID} nextSection={nextSection}/>
        </div>
        :activeTab === 2 ?
        <div style={{position: 'relative'}}>
          <MiniB nextSection={nextSection}/>
        </div>
        :activeTab === 3 ?
        <div style={{position: 'relative'}}>
          <MiniC nextSection={nextSection}/>
        </div>
        :activeTab === 4 ?
        <div style={{position: 'relative'}}>
          <MiniD nextSection={nextSection}/>
        </div>
        :activeTab === 5 ?
        <div style={{position: 'relative'}}>
          <MiniE nextSection={nextSection}/>
        </div>
        :activeTab === 6 ?
        <div style={{position: 'relative'}}>
          <MiniF nextSection={nextSection}/>
        </div>
        :activeTab === 7 ?
        <div style={{position: 'relative'}}>
          <MiniG nextSection={nextSection}/>
        </div>
        :activeTab === 8 ?
        <div style={{position: 'relative'}}>
          <MiniH nextSection={nextSection}/>
        </div>
        :activeTab === 9 ?
        <div style={{position: 'relative'}}>
          <MiniI nextSection={nextSection}/>
        </div>
        :activeTab === 10 ?
        <div style={{position: 'relative'}}>
          <MiniJ nextSection={nextSection}/>
        </div>
        :activeTab === 11 ?
        <div style={{position: 'relative'}}>
          <MiniK nextSection={nextSection}/>
        </div>
        :activeTab === 12 ?
        <div style={{position: 'relative'}}>
          <MiniL nextSection={nextSection}/>
        </div>
        :activeTab === 13 ?
        <div style={{position: 'relative'}}>
          <MiniM nextSection={nextSection}/>
        </div>
        :activeTab === 14 ?
        <div style={{position: 'relative'}}>
          <MiniN nextSection={nextSection}/>
        </div>
        : null
        }
    </div>
  );
}

export default Mini