import React from 'react';
import { CategoryVoiceDataTypes } from '../../../Assets/enums';
import GeneralQuestionnairesParticipantData from '../GeneralQuestionnaires/GeneralQuestionnairesParticipantData';

const VoiceParticipantData = () => {
    return (
        <div>
            <GeneralQuestionnairesParticipantData categoriesQuestionnaireTypes={CategoryVoiceDataTypes}/>
        </div>
    )
}

export default VoiceParticipantData;