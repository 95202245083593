import React, { useEffect, useState } from "react";
import { useParams }from 'react-router-dom';
import ActiveMonitoring from "./ActiveMonitoring";
import axios from "axios";
import { frequencyQuestionnaire } from "../Patients";
const awsParticipantsClinitianInterfaceAPI = require('../../../config.json').awsParticipantsEndpoints.awsClinitianInterfaceAPI;
const debug = require('../../../config.json').debug;
var moment = require('moment'); // require

const MonitoringPatientData = (props) => {
    const { participantID } = useParams();
    const {patientInformation} = props;
    const [isLoading, setIsLoading] = useState(false)
    
    function getDatebyTimestamp(number) {
        return moment(number).format('YYYY-MM-DD').toString();
    };

    // some timestamp are in seconds others in milliseconds
    function sanitizeTimestamp(number) {
      let z = number.toString().length;
      if (z === 13) {
        return number
      }
      if (z === 10) {
        return number*1000
      }
    }

    const [participantData, setParticipantData] = useState([]);
    
    useEffect(() => {
        // Fetch the JSON data
        const fetchData = async () => {
          let k = []
          try {
            const response = await axios.get(awsParticipantsClinitianInterfaceAPI+'/participant/'+participantID);
            debug && console.log(response)
          
            response.data.forEach((d) => {
              if (d.category === "PGSGA Questionnaire" || d.category === "DEBQ Questionnaire" || d.category === "QLQ_C30 Questionnaire" || d.category === "HADS Questionnaire" || d.category === "PSQI Questionnaire" || d.category === "FFQ Questionnaire" || d.category === "QLQ_LC29 Questionnaire" || d.category === "QLQ_BR23 Questionnaire" || d.category === "Voice"){
                d.questionnaireAWScategory = d.category;
                if (d.category === "QLQ_LC29 Questionnaire" || d.category === "QLQ_BR23 Questionnaire"){
                  d.category = "QLS";
                } else if (d.category === "Voice") {
                  //Conditions for submitted Voice questionnaire
                  if(true){
                    d.status = "completed";
                  }
                } else {
                  d.category = d.category.split(' ')[0]
                  d.category = d.category.split('_')[0]
                  d.category = d.category.replace('HADS', 'HA')
                  d.category = d.category.replace('DEBQ', 'DEB')
                  d.category = d.category.replace('PGSGA', 'PG')
                }
                
                d.timestamp = sanitizeTimestamp(d.timestamp)
                k.push(d);
              }
            });
          
            if(k.length > 0){
              const firstMonth = moment(patientInformation.StartDate, 'DD/MM/YYYY').toString();
              k.forEach((r) => { // Sanitize timestamps
                 const x = getDatebyTimestamp(r.timestamp);
                 const diffDays = moment(x).diff(moment(firstMonth), 'days');
                 const questionnaireFrequency = frequencyQuestionnaire(r.questionnaireAWScategory)

                 if (r.category === 'DEB' || r.category === 'PSQI' || r.category === 'FFQ'){
                    r['actualMonth'] = moment(x).diff(moment(firstMonth), 'months');
                 } else {
                    r['actualMonth'] = Math.floor(diffDays/questionnaireFrequency)
                 }
                 r['convDate'] = x
              })
            }
          } catch (error) {
            console.log('Error fetching data:', error);
          }

          setParticipantData(k)
          setIsLoading(true)
        };
        fetchData();
  }, []);
  
  
  return (
    <div className="mt-3">
      {
        isLoading && <ActiveMonitoring participantData={participantData} patientInformation={patientInformation} />
      }
    </div>
  )
}

export default MonitoringPatientData;