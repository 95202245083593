import { LineChart, Line, CartesianGrid, XAxis, YAxis, Legend, ResponsiveContainer, Tooltip } from 'recharts';
import React from 'react';

const SleepChart = (props) => {
  const {data, maxValue} = props;
  const sleepData = data ? data.toReversed() : null;
  
  return (
    <div style={{overflow: "hidden", flexBasis: "80%" }}>
      <ResponsiveContainer aspect={1}>
        <LineChart data={sleepData}>
          <XAxis dataKey="x" tick={{ fill: '#C3CBD4' }} stroke="none" tickMargin={2} />
          <YAxis dataKey="sleep" tick={{ fill: '#C3CBD4' }} stroke="none" tickCount={6} tickMargin={2} domain={[0, maxValue]}/>
          <Legend verticalAlign="top" align="top" layout='vertical' iconType={'plainline'} margin={{ bottom: 5 }} />
          <Line type="monotone" dataKey="sleep" stroke="#8884d8"  strokeWidth={5} dot={false} />
          <Line type="monotone" dataKey="awake" stroke="#BBC6CF" strokeWidth={2} strokeDasharray="8 8" dot={false} />
          <Tooltip />
          <CartesianGrid stroke="#EDEDED" horizontal={true} vertical={false} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SleepChart;
