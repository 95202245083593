import React from 'react';
import Calendar from '../../../Assets/img/Calendar.png';


const InterviewsHADS = () => {
  return (
    <div className='middle-container p-1 mt-2' style={{width: '100%', boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 3px 0px'}}>
        <p className='xx-large dark-blue-txt'>Interviews - HADS</p>
        <p className='small blue-txt mb'>Interviews are collected by the doctor every three months. Check the planning in your Calendar.</p>
        <div className='flex-around'>
            <img src={Calendar} style={{width: '300px', height: '300px'}}/>
            <div className='center' style={{position: 'relative', top: '90px'}}>
                <p className='mb'>No day selected</p>
                <button className='grey-btn'>+ Add a new HAM-D</button>
            </div>

        </div>
    </div>
  )
}


export default InterviewsHADS