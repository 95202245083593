import React, {useEffect, useState} from 'react';
import { fetchUserAttributes } from 'aws-amplify/auth';

const User = () => {
  const [username, setUsername] = useState([]);

  useEffect(() => {
    const User_Authentication_Info = async () => {
      const userAttributes = await fetchUserAttributes();
      setUsername(userAttributes.name);
    }

      User_Authentication_Info();
  }, []);

  return (
    <div className="flex-end">
      <div className="flex-col">
        <p className="x-small">{username}</p>
      </div>
    </div>
  );
};

export default User;
