import React, {useEffect, useState, useCallback} from 'react';
import "survey-core/modern.min.css";
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import {surveyDemo} from '../../../Language/En2';
import {fetchData, postData} from '../../../Utilities/utils';
import { useParams }from 'react-router-dom';
import {demographic_CRF} from 'questionnaires';
const awsClinitianInterfaceAPI = require('../../../../config.json').awsParticipantsEndpoints.awsClinitianInterfaceAPI;
const participantsListUrl = require('../../../../config.json').awsParticipantsEndpoints.participantsList;
const participantQuestionnaireCRFDemographic = require('../../../../config.json').awsParticipantsEndpoints.participantQuestionnaireCRFDemographic;
const awsCRFsInterfaceAPI = require('../../../../config.json').awsQuestionnairesEndpoints.awsCRFsInterfaceAPI;
const demographicURL = require('../../../../config.json').awsQuestionnairesEndpoints.demographic;

const surveyDefaultDate = require('../../../../config.json').initialTrialDate;
const dltEndpoints = require('../../../../config.json').dltMiddlewareEndpoints;

StylesManager.applyTheme("modern");

const Demographic = ({ nextSection }) => {
  const { participantID } = useParams();
  const [surveyAnwserData, setSurveyAnwser] = useState(false);

  useEffect(() => {
    async function getDLTQuestionnaireResourceInformation(){
      try{
        const resourceToValidate = {
          ResourceInformation: {
            resourceIdentification: {
              questionnaire: "Demographic-CRF",
              FHIR: "6.0.0",
              language: "en"
            },
            resourceVersion: "2"
          },
          Questionnaire: demographic_CRF.surveyDemographic
        }

        console.log("resourceToValidate")
        console.log(resourceToValidate)
        console.log(`${dltEndpoints.faithDLTConnector}/${dltEndpoints.validateResourceURL}`)
        const dltQuestionnaireResource = await postData(`${dltEndpoints.faithDLTConnector}/${dltEndpoints.validateResourceURL}`, resourceToValidate);
        //const dltQuestionnaireResource = await fetchData(`${dltEndpoints.GRISAPI}/${dltEndpoints.eventURL}/${dltEndpoints.questionnaireResourceType}/filter?resourceIdentification=${JSON.stringify(resourceIdentification)}`);

        console.log("dltQuestionnaireResource")
        console.log(dltQuestionnaireResource)


      } catch (err) {
        console.log(err)

        console.log('Error while accessing DLT.')
      }

      //console.log( surveyDemo.pages[0])
      console.log( ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
    }

    getDLTQuestionnaireResourceInformation();
  }, []);

  useEffect(() => {
    async function getDemographic(){
      if(!surveyAnwserData){
        const response = await fetchData(`${awsClinitianInterfaceAPI}/${participantsListUrl}/${participantID}/${participantQuestionnaireCRFDemographic}`);

        surveyDemo.pages[0].elements.map(
          (data,index) => {
            if (response[data.name] !== undefined) {
              data.defaultValue = response[data.name]
            }
            else {
              data.defaultValue = '';
            }
          }
        )

        setSurveyAnwser(response)
      }
    }
    
   getDemographic()
  }, [participantID, surveyAnwserData])

  const survey = new Model(surveyDemo.pages[0]);
  survey.focusFirstQuestionAutomatic = false;

  // the date of the survey is allways the same in order to only have one survey type record on the database
  const saveResults = useCallback((sender) => {
    const data = {
      participantID: participantID,
      timestamp: surveyDefaultDate,
      data: sender.data
    }

    postData(`${awsCRFsInterfaceAPI}/${demographicURL}`, data, 'POST');

    nextSection(2);
  }, [nextSection, participantID]);

  survey.onComplete.add(saveResults);

  return (
    <div className='scroll' style={{height: '75vh',overflowY: 'scroll'}}>
      <Survey model={survey} />
    </div>
  );
}

export default Demographic