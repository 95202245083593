import React from 'react';
import Alert from '../../Header/Alert';
import { Link, useParams } from 'react-router-dom';

const QuestionnairesClinitianInterface = () => {
  const { participantID } = useParams();

  return (
    <>
      <article>
        <Link to='/'>
            <p className='main-txt small pointer'>&lt; Back to Patients List </p>
        </Link>
      </article>

      <Alert title={'Conduct CRFs interviews'} description='Add or update interview.' button_text='CRFs' button_link={`/patient/${participantID}/questionnaires/crf`} />

      {false && <Alert title='Conduct Hamilton Questionnaire' description='Add or update interview.' button_text='HDRS' button_link={`/patient/${participantID}/questionnaires/hdrs`}/>}
    </>
  )
};

export default QuestionnairesClinitianInterface;
