import React, {useEffect, useState} from 'react';
import { Link, useParams, useLocation }from 'react-router-dom';
import Menu from './Menu/Menu';
import MonitoringPatientData from './MonitoringData/MonitoringPatientData';
import { fetchData } from '../Utilities/utils';
import ActivityParticipantData from './Activity/ActivityParticipantData';
import SleepParticipantData from './Sleep/SleepParticipantData';
import NutritionParticipantData from './Nutrition/NutritionParticipantData';
import VoiceParticipantData from './Voice/VoiceParticipantData';
import GeneralQuestionnairesParticipantData from './GeneralQuestionnaires/GeneralQuestionnairesParticipantData';
import { Button } from '@aws-amplify/ui-react';
import PassiveMonitoring from './MonitoringData/PassiveMonitoring';
import Notes from './MonitoringData/Notes';
import { CancerTypes, CategoryGeneralQuestionnairesTypes } from '../../Assets/enums';

const awsParticipantsClinitianInterfaceAPI = require('../../config.json').awsParticipantsEndpoints.awsClinitianInterfaceAPI;
const participantsListUrl = require('../../config.json').awsParticipantsEndpoints.participantsList;
const participantDetailsInformation = require('../../config.json').awsParticipantsEndpoints.participantDetailsInformation;

const PatientsContainer = () => {
  const {pathname} = useLocation();
  const {participantID} = useParams();
  const [generalQuestionnaires, setGeneralQuestionnaires] = useState([]);
  const [participantInformation, setParticipantInformation] = useState([]);
  const [isPatientAvailable, setIsPatientAvailable] = useState(false);
  const [loading, setIsLoading] = useState(true);
  
  useEffect(() => {
    async function defineParticipantEmail(){
      setIsLoading(true);
      const participantInformation = await fetchData(`${awsParticipantsClinitianInterfaceAPI}/${participantsListUrl}/${participantID}/${participantDetailsInformation}`);
      setIsLoading(false)
      
      if (participantInformation && participantInformation.email){
        let generalQuestionnairesAttribution = CategoryGeneralQuestionnairesTypes;

        if (participantInformation.profile === CancerTypes.LUNG){
          generalQuestionnairesAttribution.push("QLQ-LC29");
        } else if (participantInformation.profile === CancerTypes.BREAST){
          generalQuestionnairesAttribution.push("QLQ-BR23");
        }

        setGeneralQuestionnaires(generalQuestionnairesAttribution);
        setParticipantInformation(participantInformation);
        setIsPatientAvailable(true);
      }
    };

    defineParticipantEmail();
  }, [participantID])

  const compToDisplay = () => {
    if (pathname === `/patient/${participantID}/sleep`){
        return <SleepParticipantData />;
    } else if (pathname === `/patient/${participantID}/activity`){
        return <ActivityParticipantData />;
    } else if (pathname === `/patient/${participantID}/nutrition`){
        return <NutritionParticipantData />;
    } else if (pathname === `/patient/${participantID}/voice`){
        return <VoiceParticipantData />;
    } else if (pathname === `/patient/${participantID}/general-questionnaires`){
        return <GeneralQuestionnairesParticipantData categoriesQuestionnaireTypes={generalQuestionnaires}/>;
    }
  }

  return (
    loading ?
    (
      <div className='mt'> 
        <p className='mt-3 bold'>Loading</p>
      </div>
    ) :
    (
      isPatientAvailable ?
        (
          <div className='mt'> 
            <article>
              <Link to='/'>
                  <p className='main-txt small pointer'>&lt; Back to Patients List </p>
              </Link>
            </article>
            
            <div className='flex-bet'>
                <article className='mb'>
                  <p className='medium-txt inline mr'>Patient </p>
                  <p className='small bold inline'>{participantInformation.email}</p>
                  <br></br>
                   <p className='small-txt inline mr'>Trial started</p>
                  <p className='small-txt inline'>{participantInformation.StartDate}</p>
                </article>
                <></>
                <article className='mb'>
                  <Link to = {`/patient/${participantID}/questionnaires`} >
                    <Button style={{'background': 'white'}} size="small">Questionnaires</Button>
                  </Link>
                </article>
            </div>

            <hr/>

            <MonitoringPatientData patientInformation={participantInformation}/>
            
            <hr/>

            <PassiveMonitoring patientTrialStartDate={participantInformation.StartDate}/>

            <hr/>

            {false && <Notes/>}

            <hr/>

            <br></br>
            
            <Menu email = {participantInformation.email}/>
            {compToDisplay()}
          </div>
        ) :
        (
          <div className='mt'> 
            <p className='mt-3 bold'>Patient information not availble</p>
          </div>
        )
    )
  )
};

export default PatientsContainer;
