import React, {useEffect, useState, useCallback} from 'react';
import "survey-core/modern.min.css";
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import {surveyConsent} from '../../../Language/En2';
import {fetchData, postData} from '../../../Utilities/utils';
import { useParams, useNavigate }from 'react-router-dom';
const awsClinitianInterfaceAPI = require('../../../../config.json').awsParticipantsEndpoints.awsClinitianInterfaceAPI;
const participantsListUrl = require('../../../../config.json').awsParticipantsEndpoints.participantsList;
const participantQuestionnaireCRFConsent = require('../../../../config.json').awsParticipantsEndpoints.participantQuestionnaireCRFConsent;
const awsCRFsInterfaceAPI = require('../../../../config.json').awsQuestionnairesEndpoints.awsCRFsInterfaceAPI;
const consentURL = require('../../../../config.json').awsQuestionnairesEndpoints.consent;

const surveyDefaultDate = require('../../../../config.json').initialTrialDate;

StylesManager.applyTheme("modern");

const Consent = () => {
  const { participantID } = useParams();
  const [surveyAnwserData, setSurveyAnwser] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function getConsent(){
      if(!surveyAnwserData){
        const response = await fetchData(`${awsClinitianInterfaceAPI}/${participantsListUrl}/${participantID}/${participantQuestionnaireCRFConsent}`);

        surveyConsent.pages[0].elements.map(
          (data,index) => {
            if (response[data.name] !== undefined) {
              data.defaultValue = response[data.name]
            }
            else {
              data.defaultValue = '';
            }
          }
        )

        setSurveyAnwser(response)
      }
    }
    
   getConsent()
  }, [participantID, surveyAnwserData])

  const survey = new Model(surveyConsent.pages[0]);
  survey.focusFirstQuestionAutomatic = false;

  // the date of the survey is allways the same in order to only have one survey type record on the database
  const saveResults = useCallback((sender) => {
    const data = {
      participantID: participantID,
      timestamp: surveyDefaultDate,
      data: sender.data
    }

    postData(`${awsCRFsInterfaceAPI}/${consentURL}`, data, 'POST');

    navigate(`/patient/${participantID}/questionnaires`);
  }, [participantID]);

  survey.onComplete.add(saveResults);

  return (
    <div className='scroll' style={{height: '75vh',overflowY: 'scroll'}}>
      <Survey model={survey} />
    </div>
  );
}

export default Consent