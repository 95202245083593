export const MenuItems = [
    {
        title: 'Sleep',
        url: 'sleep'
    },
    {
        title: 'Activity',
        url: 'activity'
    },
    {
        title: 'Nutrition',
        url: 'nutrition'
    },
    {
        title: 'Voice',
        url: 'voice'
    },
    {
        title: 'General Questionnaires',
        url: 'general-questionnaires'
    }
]