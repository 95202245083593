import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import Container from "./components/Layout/Container";
import SideContainer from "./components/Layout/SideContainer";
import { Amplify } from 'aws-amplify';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import awsExports from './aws-exports';
import Login from "./components/Login/Login";

Amplify.configure({
    Auth: {
      Cognito: {
        region: awsExports.REGION,
        userPoolId: awsExports.USER_POOL_ID,
        userPoolClientId: awsExports.USER_POOL_APP_CLIENT_ID
      }
  }
})

export default function App() {
  const history = createBrowserHistory({});
  const { authStatus } = useAuthenticator(context => [context.authStatus]);

  if(authStatus === 'authenticated') {
    return (
      <Authenticator.Provider>
        <BrowserRouter history={history}>
          <div className="flex">
            <SideContainer />
            <Container />
          </div>
        </BrowserRouter>
      </Authenticator.Provider>);
  } else {
    return (
      <Login></Login>
    );
  }

}
