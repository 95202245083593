import { useParams }from 'react-router-dom';
import React from 'react';
import { CategoryActivityDataTypes } from '../../../Assets/enums';
import GeneralQuestionnairesParticipantData from '../GeneralQuestionnaires/GeneralQuestionnairesParticipantData';
import ActivityInfo from './ActivityInfo';

const ActivityParticipantData = () => {
    const { participantID } = useParams();

    return (
        <div>
            <GeneralQuestionnairesParticipantData categoriesQuestionnaireTypes={CategoryActivityDataTypes}/>
            <ActivityInfo participantID={participantID}></ActivityInfo>
        </div>
    )
}

export default ActivityParticipantData;