import React, {useEffect, useState} from 'react';
import { Link  }from 'react-router-dom';
import record from '../../Assets/Icons/record-icon.svg';
import { fetchData } from '../../components/Utilities/utils';
import HospitalsRemainingTrials from './HospitalsRemainingTrials';
const awsParticipantsClinitianInterfaceAPI = require('../../config.json').awsParticipantsEndpoints.awsClinitianInterfaceAPI;
const globalOverviewInformation = require('../../config.json').awsParticipantsEndpoints.overview;

const Overview = () => {
    const [overviewParticipants, setOverviewParticipants] = useState(false);
    const [loading, setLoading] = useState(true);

    /*
    /* This was not included because this functionality will not be used during the trials but rather when analysing the trial data

    useEffect(() => {
        // since there could be unk states, here are the codes:
        // red - alert; green - correct; grey - unk
        const analyseOverviewParticipantInformations = (participantInformation) => {
            const colors = {red: "red", green: "green", grey: "grey"}
            const a = [{email: "asdasd", participantID: "participantIDExample", mentalState: colors.red, weightGain: colors.green, malnutrition: colors.grey, qualityLife: colors.green, voice: colors.green, activity: colors.red}]

            return a;
        }

        const getParticipants = async () => {
            if(!overviewParticipants){
                const participantInformation = await fetchData(`${awsParticipantsClinitianInterfaceAPI}/${globalOverviewInformation}`);

                const overviewPatients = analyseOverviewParticipantInformations(participantInformation);
                setOverviewParticipants(overviewPatients);
                //setLoading(false)             to allow when this functionality is approved bythe hospitals
            }
        };
        
        getParticipants();
    }, [overviewParticipants])
    */

  return (
    <div className='middle-container p-1'>
        <HospitalsRemainingTrials/>

      {
        /* This was not included because this functionality will not be used during the trials but rather when analysing the trial data
        /*
        <div className='table-intro flex-bet pt-2'>
            <h3 className='medium-bold-txt dark-blue-txt mb'>Global overview</h3>
            <div className="flex-double-center">
                <div className='table-intro flex-center ps'>
                    <article className={`x-green-circle`}></article>
                    <div className='p-1'>OK</div>
                </div>
                <div className='table-intro flex-center ps'>
                    <article className={`x-red-circle`}></article>
                    <div className='p-1'>Alarm</div>
                </div>
                <div className='table-intro flex-center ps'>
                    <article className={`x-grey-circle`}></article>
                    <div className='p-1'>Unknown</div>
                </div>
            </div>
        </div>
        {
          !loading ? (
            <div className='table-container'>
              <table className='zui-table'>
                <thead>
                    <tr>
                        <th className='underline'>
                            <div>Email</div>
                        </th>
                        <th>
                            <div>Mental State</div>
                        </th>
                        <th>
                            <div>Weight Gain</div>
                        </th>
                        <th>
                            <div>Malnutrition</div>
                        </th>
                        <th>
                            <div>Quality Life</div>
                        </th>
                        <th>
                            <div>Voice</div>
                        </th>
                        <th>
                            <div>Activity</div>
                        </th>
                        <th>
                            <div>See Records</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                {
                    (
                        overviewParticipants.map((participantOverview,index) => {
                          return(
                            <tr className='x-small-txt dark-grey-txt' key={index}>
                                <td className='dark-blue-txt underline'>
                                    <div style={{'wordBreak':'normal', 'textTransform': 'lowercase'}} className='blue-txt pointer'>{participantOverview.email}</div>
                                </td>

                                <td className='main-txt'>
                                    <div className='flex-double-center'>
                                        <article className={`x-${participantOverview.mentalState}-circle`} ></article>
                                    </div>
                                </td>

                               <td className='main-txt'>
                                    <div className='flex-double-center'>
                                        <article className={`x-${participantOverview.weightGain}-circle`} ></article>
                                    </div>
                                </td>

                               <td className='main-txt'>
                                    <div className='flex-double-center'>
                                        <article className={`x-${participantOverview.malnutrition}-circle`} ></article>
                                    </div>
                                </td>

                               <td className='main-txt'>
                                    <div className='flex-double-center'>
                                        <article className={`x-${participantOverview.qualityLife}-circle`} ></article>
                                    </div>
                                </td>

                               <td className='main-txt'>
                                    <div className='flex-double-center'>
                                        <article className={`x-${participantOverview.voice}-circle`} ></article>
                                    </div>
                                </td>

                               <td className='main-txt'>
                                    <div className='flex-double-center'>
                                        <article className={`x-${participantOverview.activity}-circle`} ></article>
                                    </div>
                                </td>
                                
                                <td>
                                    <div className='light-grey-txt flex-center underline' style={{display: 'flex', justifyContent: 'center'}}>
                                        <Link to = {`/patient/${participantOverview.participantID}`} state={{email: participantOverview.email}} >
                                            <img src={record} alt='record' className='icon-table'></img>
                                        </Link>
                                    </div> 
                                </td>
                            </tr>
                          )
                        })
                    )
                }
                </tbody>
              </table>
            </div>
            ) :
              <tr className='x-small-txt dark-grey-txt'>
                 <td className='dark-blue-txt underline' style={{position: 'relative', top: '10px'}}>
                     <p>Loading</p>
                 </td>
              </tr>
            }
        */
      }
    </div>
  )
};

export default Overview;
