import {CircularProgressbar, buildStyles } from "react-circular-progressbar";

const CircularProgressbarModule = (props) => {
    const {title, numberRegestries, total, description, caption} = props
    const percentage = Math.trunc(Number(numberRegestries/total*100));
    
    return (
               <div className='middle-container p-1 mt-1' style={{width: '23vw', boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 3px 0px'}}>
                <h4 className='medium-bold-txt txt-center'>{title}</h4>
                <p className='x-small blue-txt mb txt-center'>{description}</p>
                <div className='flex-around center p-0'>
                    <div style={{padding: '0 70px'}}>
                        <CircularProgressbar
                            value={percentage}
                            text={`${percentage}%`}
                            circleRatio={1}
                            styles={buildStyles({
                                rotation: 1 / 2 + 1 / 8,
                                textColor: '#CCCCCC',
                                pathColor: "#4fc3c3",
                                trailColor: "#C9CED4"
                            })}
                        />
                        <h5 className='xx-small-txt'>{caption}</h5>
                        <p className='x-small-bold-txt'><span className='main-txt'>{numberRegestries}</span> /{total}</p>
                    </div>
                </div>
            </div>
    )
}

export default CircularProgressbarModule;