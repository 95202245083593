class HttpRequestException {
  constructor(err) {
    this.timestamp = err.timestamp;
    this.status = err.status;
    this.error = err.error;
    this.message = err.message;
    this.path = err.path;
  }
}

// Not used at the moment
const GetDefaultHeaders = (json) => {
  const tokenString = localStorage.getItem("token");
  const token = JSON.parse(tokenString);

  //console.log("Token:" + token);
  const headers = {};
  headers["Content-Type"] = "application/json";
  if (token) {
    headers.Authorization = "Bearer " + token;
  }
  return headers;
};

const post = async (url, data, method = "POST", json = true) => {
  const options = {
    method: method,
    //mode: 'cors',
    body: JSON.stringify(data),
    //headers: GetDefaultHeaders(json),
  };
  return fetch(url, options)
    .then((response) => {
      if (response.ok) {
        return json ? response.json() : response;
      } else if (response.status >= 400) {
        return response.json();
      }
    })
    .then((result) => {
      if (result && result.message && result.error && result.status) {
        throw new HttpRequestException(result);
      } else {
        return result;
      }
    });
};

export const fetchData = async (url, json = true) => {
  const options = { headers: await GetDefaultHeaders(json) };
  return fetch(url, options)
    .then((response) => {
      if (response.ok) {
        return json ? response.json() : response;
      } else if (response.status >= 400) {
        return response.json();
      }
    })
    .then((result) => {
      if (result && result.message && result.error && result.status) {
        throw new HttpRequestException(result);
      } else {
        return result;
      }
    })
    .catch((e) => console.error("site unreachable"));
};

export const postData = (url, data = {}, readJson) =>
  post(url, data, "POST", readJson);

export const updateData = (url, data = {}, readJson) =>
  post(url, data, "PUT", readJson);

export const deleteData = (url, data = {}) => post(url, data, "DELETE", false);
