import React from 'react';
import { useNavigate } from 'react-router-dom';

const Alert = ({ title, description, button_text, button_link }) => {
  const navigate = useNavigate();

  const handle = () => {
    navigate(button_link)
  }

  if(!button_link)
    button_link='#'

  return (
    <div className="my_alert">
      <div>
        <h2>{title}</h2>
        <p>{description}</p>
        <button onClick={handle} className='azulmarinho-btn x-medium x-bold pointer' style={{'float':'right'}}><i className="bi bi-plus" style={{'fontSize':'xx-large'}}></i> <span style={{'verticalAlign': 'super'}}>{button_text}</span></button>
      </div>
    </div>
  )
};

export default Alert;
