import React, { useEffect, useState } from "react";
import { Date } from "core-js";         //validate if this is necessary
import SleepChart from "./SleepChart";
import { fetchData } from "../../Utilities/utils";
import moment from "moment";

const awsAPI = require('../../../config.json').awsParticipantsEndpoints;

const SleepInfo = ({participantID}) => {
  const oneMonth = 30;
  const threeMonths = 90;
  const oneYear = 365;
  const periodOptions = [{"One month": oneMonth}, {"Three months": threeMonths}, {"One year": oneYear}]
  const [patientSleepData, setPatientSleepData] = useState();
  const [dataGraph, setDataGraph] = useState();
  const [threshold, setThreshold] = useState(oneYear)
  const [lastdata, setLastData] = useState();
  const [maxSleepValue, setMaxSleepValue] = useState(14);

  useEffect(() => {
    const fetchDataSleep = async () => {
      try {
        setPatientSleepData(await fetchData(`${awsAPI.awsClinitianInterfaceAPI}/${awsAPI.participantsList}/${participantID}/${awsAPI.participantCategory.Sleep}`));
      } catch (error) {
          console.log('Error fetching data:', error);
      }
    }

    fetchDataSleep();
  },[])

  useEffect(() => {
    if(patientSleepData && patientSleepData.length>0){
        console.log(patientSleepData)
        let graph = [];
        let maxSleepValueAux = maxSleepValue;

        const startDateThreshold = new moment(patientSleepData[0].date).subtract(threshold, "days").valueOf();

        setLastData(patientSleepData[0]);

        patientSleepData.forEach((sleepEntry) => {
            const sleepEntryDate = new moment(sleepEntry.date).valueOf();

            if(sleepEntryDate > startDateThreshold){
                const sleepTimeEnd = new moment(sleepEntry.end_time);
                const sleepTimeStart = new moment(sleepEntry.start_time);
                const awake_hours = (sleepEntry.awake_duration / (60 * 60)).toFixed(1);
                const sleepDuration = Number(sleepTimeEnd.diff(sleepTimeStart, 'hours').toString());

                graph.push({
                 x: sleepEntry.date,
                 sleep: sleepDuration,
                 awake: awake_hours
                });
             
                if(sleepDuration > maxSleepValueAux){
                  maxSleepValueAux = sleepDuration + 3;
                }
            }
        });

        setDataGraph(graph);
        setMaxSleepValue(maxSleepValueAux);
    }
  }, [patientSleepData && threshold])

  // Function to handle sleep month selection
  const handleOptionClick = (option) => {
    setThreshold(option);
  };

  return (
    <div>
    {patientSleepData && patientSleepData.length>0 ? 
      <div className="flex">
          <div style={{flexGrow: 1, display: "flex", flexDirection: "column", overflowY: "hidden"}}>
            <div className="table-intro" style={{justifyContent: 'space-between'}}>
              <strong><p>Last {threshold} days of sleep</p></strong>
              <select onChange={(e) => handleOptionClick(e.target.value)} value={threshold}>
                <option value="">Select an option</option>
                  {periodOptions.map((option) => (
                    <option key={Object.keys(option)} value={Object.values(option)}>{Object.keys(option)}</option>
                  ))}
              </select>
            </div>
            <SleepChart data={dataGraph} maxValue={maxSleepValue}/>
          </div>
          <div style={{marginLeft: '50px', alignSelf: "center"}}>
              {
                  lastdata ? (
                    <>
                      <strong><p>Last Sleep:</p></strong>
                      <p><strong>Date:</strong> {lastdata.date}</p>
                      <p><strong>Start Time:</strong> {lastdata.start_time}</p>
                      <p><strong>End Time:</strong> {lastdata.end_time}</p>
                      <p><strong>Duration time: (h)</strong> {((new Date(lastdata.end_time).valueOf() - new Date(lastdata.start_time).valueOf()) / (1000 * 60 * 60)).toFixed(1)}</p>
                      <p><strong>Awake time (min):</strong> {lastdata.awake_duration}</p>
                      <p><strong>Sleep Interruptions:</strong> {lastdata.sleep_interruptions}</p>
                      <p><strong>Nap Duration:</strong> {lastdata.nap_duration}</p>
                    </>
                  ) :
                  (
                    <></>
                  )
              }
          </div>
      </div>
    : <></>
    }
    </div>
  )
}

export default SleepInfo;