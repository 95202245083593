import React from 'react';
const initialTrialDate = require('../../config.json').initialTrialDate;
const finalTrialDate = require('../../config.json').finalTrialDate;

const dateToDisplayString = (timestamp) => {
    return timestamp ? new Date(parseInt(timestamp)).toLocaleDateString() : '-';
}

//in percentage
const progress = () => {
    const currentDay = new Date();
    const totalTimeTrial = finalTrialDate - initialTrialDate;

    let currentProgressInEpoc = currentDay - initialTrialDate;

    if (currentProgressInEpoc > totalTimeTrial){
        currentProgressInEpoc = totalTimeTrial
    }
    
    return currentProgressInEpoc / totalTimeTrial*100;
};

const ProgressBar = (props) => {
  return (
    <>
        <div></div>
        {props.type === 'day'?
            <div className="progress-container">
                <div style={{margin: '10px'}}>
                    <div className="progress-bg">
                        <div className="progress progress-indicator5"></div>
                    </div>
                    <p className="x-small blue-txt mt">trial completion</p>
                </div>
            </div>
            :
            <div className="progress-container">
                <div style={{padding: '16px'}}>
                    <div style={{'marginBottom': '-16px'}}>
                        <h3>Data collection Trial</h3>
                        <p className="main-txt">Current date: {new Date().toLocaleString()}</p>
                    </div>
                    <div className="progress-bg">
                        <div className={`progress`} style={{width: `${progress()}%`}}></div>
                    </div>
                    <div style={{position: 'relative', display: 'flex',  'justifyContent': 'space-between'}}>
                        <div>{dateToDisplayString(initialTrialDate)}</div>
                        <div>{dateToDisplayString(finalTrialDate)}</div>
                    </div>
                </div>
            </div>
        }
    </>
  )
};

export default ProgressBar;
