import React from "react";
import '@aws-amplify/ui-react/styles.css';  // CSS AWS
import { Authenticator } from '@aws-amplify/ui-react'
import "./Login.css";

export default function Login() {
  return (
    <div className="outter">
      <div className="login-wrapper">
        <div className="logoN"></div>
        <div className="login-box">
          <div className="login-content">
            <div className="login-text">
              <h5>Welcome back</h5>
              <span>Enter your credentials to access your account</span>
            </div>
            <Authenticator hideSignUp={true}></Authenticator>
          </div>
        </div>
      </div>
    </div>
  )
}
