import React, {useCallback, useEffect, useState} from 'react';
import "survey-core/modern.min.css";
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import {surveyClinical} from '../../../Language/En2';
import {fetchData, postData} from '../../../Utilities/utils';
import { useParams }from 'react-router-dom';
const awsClinitianInterfaceAPI = require('../../../../config.json').awsParticipantsEndpoints.awsClinitianInterfaceAPI;
const participantsListUrl = require('../../../../config.json').awsParticipantsEndpoints.participantsList;
const participantQuestionnaireCRFClinical = require('../../../../config.json').awsParticipantsEndpoints.participantQuestionnaireCRFClinical;
const awsCRFsInterfaceAPI = require('../../../../config.json').awsQuestionnairesEndpoints.awsCRFsInterfaceAPI;
const clinical = require('../../../../config.json').awsQuestionnairesEndpoints.clinical;

const surveyDefaultDate = require('../../../../config.json').initialTrialDate;

StylesManager.applyTheme("modern");

/* TO VERIFY ENTIRE COMPONENT */
const Clinical = ({ nextSection }) => {
  const { participantID } = useParams();
  const [fetchCRFs, setCRFs] = useState(false);
  const [surveyAnwserData, setSurveyAnwser] = useState(false);
  const [surveyData, setSurvey] = useState(surveyClinical);

  useEffect(() => {
    async function getDemographic(){
console.log(`${awsClinitianInterfaceAPI}/${participantsListUrl}/${participantID}/${participantQuestionnaireCRFClinical}`)

      if(!surveyAnwserData){
        const response = await fetchData(`${awsClinitianInterfaceAPI}/${participantsListUrl}/${participantID}/${participantQuestionnaireCRFClinical}`);
console.log(`${awsClinitianInterfaceAPI}/${participantsListUrl}/${participantID}/${participantQuestionnaireCRFClinical}`)
        console.log("response")
        console.log(response)
        surveyClinical.pages[0].elements.map(
          (data,index) => {
            if (response[data.name] !== undefined) {
              data.defaultValue = response[data.name]
            }
            else {
              data.defaultValue = '';
            }
          }
        )

        setSurveyAnwser(response)
      }
    }
    
   getDemographic()
  }, [participantID, surveyAnwserData])

  useEffect(() => {
    setSurvey(...[]);
    console.log(surveyAnwserData)
    surveyClinical.cookieName = '';
    surveyClinical.pages[0].elements.map(
      (data,index) => {
        if(surveyAnwserData[data.name] !== undefined){
          data.defaultValue = surveyAnwserData[data.name]
        }
        else
          data.defaultValue = '';
      }
    )
   setSurvey({
    ...surveyClinical
   })
   console.log(surveyData)

  }, [surveyAnwserData])



 const survey = new Model(surveyData);
  survey.focusFirstQuestionAutomatic = false;

  const alertResults = useCallback((sender) => {
    const data = {
      participantID: participantID,
      timestamp: surveyDefaultDate,
      data: sender.data
    }

    postData(`${awsCRFsInterfaceAPI}/${clinical}`, data, 'POST');

    nextSection(3);
  }, []);


  survey.onComplete.add(alertResults);

  return (
    <div className='scroll' style={{height: '75vh',overflowY: 'scroll'}}>
      {      
      !fetchCRFs || typeof fetchCRFs.Items === "undefined" ? 

        (<p></p>): 
        (
          <div>
            {
              fetchCRFs.Items.length > 0 ?
              (<p>Previous answers:</p>):
              ("")
            }
            {fetchCRFs.Items.map((data,index) => {
                return(                          
                  <button className='grey-btn' key={index} onClick={() => setSurveyAnwser(data)}>{ new Date(data.timestamp).toLocaleDateString() } { new Date(data.timestamp).toLocaleTimeString() }</button>                         
                )
              })
            }
          </div>
        )
      }
      <Survey model={survey} />
    </div>
  );
}

export default Clinical