import React, {useEffect, useState} from 'react';
import { Link  }from 'react-router-dom';
import { fetchData } from '../../components/Utilities/utils';
import DataTable from 'react-data-table-component';
import moment from 'moment';
const awsParticipantsClinitianInterfaceAPI = require('../../config.json').awsParticipantsEndpoints.awsClinitianInterfaceAPI;
const alarmsInformation = require('../../config.json').awsParticipantsEndpoints.alarms;
const hadsAlarmsURLPATH = require('../../config.json').awsParticipantsEndpoints.hadsAlarms;

// -.-' the values from HADS questionnaire are stored from 1 - 4 in the database, but on the original questionnaire range from 0 - 3
const HADSQuestionnaireDataHandling = (questionnaire) => {
    let onlyQuestions = { ...questionnaire };
    const HADSQuestionnaireNonQuestionsFields =  ['created_at', 'participantID', 'status', 'sub', 'tempId', 'timestamp', 'category', 'updated_at', 'email']

    Object.keys(onlyQuestions).forEach((item) => {
      if(!HADSQuestionnaireNonQuestionsFields.includes(item) && !(onlyQuestions[item]===0 || !onlyQuestions[item])){
        const correctedValue = Number(onlyQuestions[item]);
        onlyQuestions[item] = correctedValue-1;
      }
    })

    return onlyQuestions;
}

const HADSQuestionnaireScore = (questionnaire) => {
    var sum = 0;
    let onlyQuestions = { ...questionnaire };

    delete onlyQuestions.created_at;
    delete onlyQuestions.participantID;
    delete onlyQuestions.status;
    delete onlyQuestions.sub;
    delete onlyQuestions.tempId;
    delete onlyQuestions.timestamp;
    delete onlyQuestions.category;
    delete onlyQuestions.updated_at;
    delete onlyQuestions.email;

    for(var questionResponse in onlyQuestions){
        if(!(questionResponse.split('_')[1]%2)){
          sum += Number(onlyQuestions[questionResponse])
        }
    }

    return sum;
}

const columns = [
	{
		center: "true",
        name: <div>{'Email'}</div>,
        cell: row => <div>
                         <Link to = {`/patient/${row.participantID}`} state={{email: row.email}} >
                            <div style={{'wordBreak':'normal', 'textTransform': 'lowercase'}} className='blue-txt pointer'>{row.email}</div>
                        </Link>
                    </div>
    },
    {
        center: "true",
        name: <div>Last HADS questionnaire</div>,
        selector: row => <div>
                            <article className={`x-${row.hads}-circle`} ></article>
                         </div>,
    },
    {
		center: "true",
        name: <div style={{}}>HADS Date</div>,
        selector: row => <div>{row.date}</div>,
    }
];

const customStyles = {
  headCells: {
    style: {
      backgroundColor: '#DDEFEF',
      color: '#336B6B',
      textShadow: '1px 1px 1px #fff',
      justifyContent: 'center',
      fontWeight: '600',
      fontSize: '14px'
    }
  },
  noData: {
    style: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#336B6B',
      backgroundColor: '#DDEFEF',
    },
  }
};

//currently the only alarm is for HADS questionnaire
const Alarms = () => {
  const [totalRows, setTotalRows] = useState(0);            //it is assumed the total number of alarms are unique for each patient, thus, the total number of alarms has the same number
  const [loading, setLoading] = useState(true);
  const [alarms, setAlarms] = useState([]);
  const [perPage, setPerPage] = useState(10);

  // since there could be unk states, here are the codes:
  // red - alert; green - correct; grey - unk
  const analyseHADSQuestionnaires = (participantsHADSInformation) => {
    const colors = {red: "red", green: "green", grey: "grey"}
    let patientsAlarms = [];
    
    for (const participantHADSquestionnaire of participantsHADSInformation){
        let patientAlarmInfo = {
            email: participantHADSquestionnaire.email,
            participantID: participantHADSquestionnaire.participantID,
        }

        if(participantHADSquestionnaire.status === 'unknown'){
            patientAlarmInfo.hads = colors.grey
        } else {
            const parsedHADSQuestionnaire = HADSQuestionnaireDataHandling(participantHADSquestionnaire);
            const hadsQuestionnaireScoreValue = HADSQuestionnaireScore(parsedHADSQuestionnaire);

            const hadsAlarmColor = hadsQuestionnaireScoreValue >= 11 ? colors.red : colors.green;
            patientAlarmInfo.hads = hadsAlarmColor;
            patientAlarmInfo.date = new moment(parsedHADSQuestionnaire.created_at).format('YYYY-MM-DD');
            patientAlarmInfo.score = hadsQuestionnaireScoreValue;
        }

        patientsAlarms.push(patientAlarmInfo);
    }
      return patientsAlarms;
  }

  useEffect(() => {
    async function getStartupPage(){
        const total = await fetchData(`${awsParticipantsClinitianInterfaceAPI}/numberParticipants`);
        setTotalRows(total);
        fetchParticipants(1);
    }

    getStartupPage();
  }, []);

  const fetchParticipants = async page => {
    setLoading(true);
    const hadsQuestionnaires = await fetchData(`${awsParticipantsClinitianInterfaceAPI}/${alarmsInformation}/${hadsAlarmsURLPATH}?pagenumber=${page}&size=${perPage}`);
    setAlarms(analyseHADSQuestionnaires(hadsQuestionnaires));
    setLoading(false);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const hadsQuestionnaires = await fetchData(`${awsParticipantsClinitianInterfaceAPI}/${alarmsInformation}/${hadsAlarmsURLPATH}?pagenumber=${page}&size=${newPerPage}`);
    setPerPage(newPerPage);
    setAlarms(analyseHADSQuestionnaires(hadsQuestionnaires));
    setLoading(false);
  }

  const handlePageChange = page => {
    fetchParticipants(page);
  };

  return (
    <div className='middle-container p-2'>
        <div className='table-intro flex-bet pt-2'>
            <h3 className='medium-bold-txt dark-blue-txt mb'>Trial Alarms</h3>
            <div className="flex-double-center">
                <div className='table-intro flex-center ps'>
                    <article className={`x-green-circle`}></article>
                    <div className='p-1'>OK</div>
                </div>
                <div className='table-intro flex-center ps'>
                    <article className={`x-red-circle`}></article>
                    <div className='p-1'>Alarm</div>
                </div>
                <div className='table-intro flex-center ps'>
                    <article className={`x-grey-circle`}></article>
                    <div className='p-1'>Unknown</div>
                </div>
            </div>
        </div>
       
        <DataTable
            columns={columns}
            data={alarms}
            pagination
            progressPending={loading}
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            customStyles={customStyles}
        />
    </div>
  )
};

export default Alarms;
