import { useEffect, useState } from "react";
import CircularProgressbarModule from "../Dashboard/DashboardDay/CircularProgressbar";
import { fetchData } from "../Utilities/utils";
const awsParticipantsClinitianInterfaceAPI = require('../../config.json').awsParticipantsEndpoints.awsClinitianInterfaceAPI;
const globalOverviewInformation = require('../../config.json').awsParticipantsEndpoints.overview;
const startPatientsTrialDiff = require('../../config.json').awsParticipantsEndpoints.patientsStartTrialDiff;

const HospitalsRemainingTrials = () => {
    const [overviewParticipants, setOverviewParticipants] = useState(false);
    const [cfTotal, setCfTotal] = useState(0);
    const [cfNumber, setCfNumber] = useState(0);
    const [hugmTotal, setHugmTotal] = useState(0);
    const [hugmNumber, setHugmNumber] = useState(0);

    useEffect(() => {
        const getParticipants = async () => {
            if(!overviewParticipants){
                let cfNumberAux = 0;
                let hugmNumberAux = 0;

                const participantInformation = await fetchData(`${awsParticipantsClinitianInterfaceAPI}/${globalOverviewInformation}/${startPatientsTrialDiff}`);

                console.log(participantInformation)

                for (const cfParticipant of participantInformation.cfPatients){
                    if (cfParticipant.diffDays > 365){
                        cfNumberAux++;
                    }
                }

                for (const hugmParticipant of participantInformation.hugmPatients){
                    if (hugmParticipant.diffDays > 365){
                        hugmNumberAux++;
                    }
                }

                setCfTotal(participantInformation.cfPatients.length);
                setHugmTotal(participantInformation.hugmPatients.length);
                setCfNumber(cfNumberAux);
                setHugmNumber(hugmNumberAux);
                setOverviewParticipants(true);
            }
        };
        
        getParticipants();
    }, [])

    return (
        <div className='flex-bet ps-6'>
            <CircularProgressbarModule title={'CF'} numberRegestries={cfNumber} total={cfTotal} description={'Trial participants from CF with more than 365 days'} caption={'Participants'}/>
            <CircularProgressbarModule title={'HUGM'} numberRegestries={hugmNumber} total={hugmTotal} description={'Trial participants from HUGM with more than 365 days'} caption={'Participants'}/>
        </div>
    )
}

export default HospitalsRemainingTrials;