import { useParams }from 'react-router-dom';
import React, { useState } from 'react';
import { CategoryGeneralQuestionnaireNames, QuestionnairesStatus } from '../../../Assets/enums';
import { useEffect } from 'react';
import { fetchData } from '../../Utilities/utils';
import { useTable } from 'react-table'
const debug = require('../../../config.json').debug;
const awsParticipantsClinitianInterfaceAPI = require('../../../config.json').awsParticipantsEndpoints.awsClinitianInterfaceAPI;
const participantsListUrl = require('../../../config.json').awsParticipantsEndpoints.participantsList;
const hadsURLPATH = require('../../../config.json').awsParticipantsEndpoints.participantCategory.hads;
const psqiURLPATH = require('../../../config.json').awsParticipantsEndpoints.participantCategory.psqi;
const debqURLPATH = require('../../../config.json').awsParticipantsEndpoints.participantCategory.debq;
const pgsgaURLPATH = require('../../../config.json').awsParticipantsEndpoints.participantCategory.pgsga;
const qlqc30URLPATH = require('../../../config.json').awsParticipantsEndpoints.participantCategory.qlqc30;
const qlqlc29URLPATH = require('../../../config.json').awsParticipantsEndpoints.participantCategory.qlqlc29;
const qlqbr23URLPATH = require('../../../config.json').awsParticipantsEndpoints.participantCategory.qlqbr23;
const sleepInitURLPATH = require('../../../config.json').awsParticipantsEndpoints.participantCategory.sleepInit;
const ffqURLPATH = require('../../../config.json').awsParticipantsEndpoints.participantCategory.ffq;
const sleepQualityURLPATH = require('../../../config.json').awsParticipantsEndpoints.participantCategory.sleepQuality;
const waterURLPATH = require('../../../config.json').awsParticipantsEndpoints.participantCategory.water;
const weightURLPATH = require('../../../config.json').awsParticipantsEndpoints.participantCategory.weight;
const voiceURLPATH = require('../../../config.json').awsParticipantsEndpoints.participantCategory.voice;
const activityURLPATH = require('../../../config.json').awsParticipantsEndpoints.participantCategory.Activity;
const sleepURLPATH = require('../../../config.json').awsParticipantsEndpoints.participantCategory.Sleep;

const columnsHADS = [
          {
            Header: "Date",
            accessor: "created_at",
            width: "200px"
          },
          {
            Header: "Score",
            accessor: "Score",
            width: "50px"
          },
          {
            Header: "Status",
            accessor: "status",
            width: "50px"
          },
          {
            Header: "Question 1",
            accessor: "question_1",
            width: "95px"
          },
          {
            Header: "Question 2",
            accessor: "question_2",
            width: "95px"
          },
          {
            Header: "Question 3",
            accessor: "question_3",
            width: "95px"
          },
          {
            Header: "Question 4",
            accessor: "question_4",
            width: "95px"
          },
          {
            Header: "Question 5",
            accessor: "question_5",
            width: "95px"
          },
          {
            Header: "Question 6",
            accessor: "question_6",
            width: "95px"
          },
          {
            Header: "Question 7",
            accessor: "question_7",
            width: "95px"
          },
          {
            Header: "Question 8",
            accessor: "question_8",
            width: "95px"
          },
          {
            Header: "Question 9",
            accessor: "question_9",
            width: "95px"
          },
          {
            Header: "Question 10",
            accessor: "question_10",
            width: "105px"
          },
          {
            Header: "Question 11",
            accessor: "question_11",
            width: "105px"
          },
          {
            Header: "Question 12",
            accessor: "question_12",
            width: "105px"
          },
          {
            Header: "Question 13",
            accessor: "question_13",
            width: "105px"
          },
          {
            Header: "Question 14",
            accessor: "question_14",
            width: "105px"
          },
];

const columnsPSQI = [
          {
            Header: "Date",
            accessor: "date",
            width: "200px"
          },
          {
            Header: "Score",
            accessor: "Score",
            width: "50px"
          },
          {
            Header: "Status",
            accessor: "status",
            width: "50px"
          },
          {
            Header: "Question 1",
            accessor: "question1",
            width: "95px"
          },
          {
            Header: "Question 2",
            accessor: "question2",
            width: "95px"
          },
          {
            Header: "Question 3",
            accessor: "question3",
            width: "95px"
          },
          {
            Header: "Question 4",
            accessor: "question4",
            width: "95px"
          },
          {
            Header: "Question 5 a",
            accessor: "question5_a",
            width: "110px"
          },
                    {
            Header: "Question 5 b",
            accessor: "question5_b",
            width: "110px"
          },
                    {
            Header: "Question 5 c",
            accessor: "question5_c",
            width: "110px"
          },
                    {
            Header: "Question 5 d",
            accessor: "question5_d",
            width: "110px"
          },
                    {
            Header: "Question 5 e",
            accessor: "question5_e",
            width: "110px"
          },          {
            Header: "Question 5 f",
            accessor: "question5_f",
            width: "110px"
          },
                    {
            Header: "Question 5 g",
            accessor: "question5_g",
            width: "110px"
          },
                    {
            Header: "Question 5 h",
            accessor: "question5_h",
            width: "110px"
          },          {
            Header: "Question 5 i",
            accessor: "question5_i",
            width: "110px"
          },
          {
            Header: "Question 5 j a",
            accessor: "question5_j_a",
            width: "120px"
          },
          {
            Header: "Question 5 j b",
            accessor: "question5_j_b",
            width: "120px"
          },
          {
            Header: "Question 6",
            accessor: "question6",
            width: "95px"
          },
          {
            Header: "Question 7",
            accessor: "question7",
            width: "95px"
          },
          {
            Header: "Question 8",
            accessor: "question8",
            width: "95px"
          },
          {
            Header: "Question 9",
            accessor: "question9",
            width: "95px"
          },
          {
            Header: "Question 10",
            accessor: "question10",
            width: "105px"
          },
          {
            Header: "Question 11 a",
            accessor: "question11_a",
            width: "115px"
          },
          {
            Header: "Question 11 b",
            accessor: "question11_b",
            width: "115px"
          },
          {
            Header: "Question 11 c",
            accessor: "question11_c",
            width: "115px"
          },
          {
            Header: "Question 11 d",
            accessor: "question11_d",
            width: "115px"
          },
          {
            Header: "Question 11 e a",
            accessor: "question11_e_a",
            width: "130px"
          },
          {
            Header: "Question 11 e b",
            accessor: "question11_e_b",
            width: "130px"
          }
];

const columnsDEBQ = [
          {
            Header: "Date",
            accessor: "created_at",
            width: "200px"
          },
          {
            Header: "Status",
            accessor: "status",
            width: "50px"
          },
          {
            Header: "Question 1",
            accessor: "question1",
            width: "95px"
          },
          {
            Header: "Question 2",
            accessor: "question2",
            width: "95px"
          },
          {
            Header: "Question 3",
            accessor: "question3",
            width: "95px"
          },
          {
            Header: "Question 4",
            accessor: "question4",
            width: "95px"
          },
          {
            Header: "Question 5",
            accessor: "question5",
            width: "95px"
          },
          {
            Header: "Question 6",
            accessor: "question6",
            width: "95px"
          },
          {
            Header: "Question 7",
            accessor: "question7",
            width: "95px"
          },
          {
            Header: "Question 8",
            accessor: "question8",
            width: "95px"
          },
          {
            Header: "Question 9",
            accessor: "question9",
            width: "95px"
          },
          {
            Header: "Question 10",
            accessor: "question10",
            width: "105px"
          },
                    {
            Header: "Question 11",
            accessor: "question11",
            width: "105px"
          },
                    {
            Header: "Question 12",
            accessor: "question12",
            width: "105px"
          },
                    {
            Header: "Question 13",
            accessor: "question13",
            width: "105px"
          },
                    {
            Header: "Question 14",
            accessor: "question14",
            width: "105px"
          },
                    {
            Header: "Question 15",
            accessor: "question15",
            width: "105px"
          },
                    {
            Header: "Question 16",
            accessor: "question16",
            width: "105px"
          },
                    {
            Header: "Question 17",
            accessor: "question17",
            width: "105px"
          },
                    {
            Header: "Question 18",
            accessor: "question18",
            width: "105px"
          },
                    {
            Header: "Question 19",
            accessor: "question19",
            width: "105px"
          },
                    {
            Header: "Question 20",
            accessor: "question20",
            width: "105px"
          },
          {
            Header: "Question 21",
            accessor: "question21",
            width: "105px"
          },
          {
            Header: "Question 22",
            accessor: "question22",
            width: "105px"
          },
          {
            Header: "Question 23",
            accessor: "question23",
            width: "105px"
          },
          {
            Header: "Question 24",
            accessor: "question24",
            width: "105px"
          },
          {
            Header: "Question 25",
            accessor: "question25",
            width: "105px"
          },
          {
            Header: "Question 26",
            accessor: "question26",
            width: "105px"
          },
          {
            Header: "Question 27",
            accessor: "question27",
            width: "105px"
          },
          {
            Header: "Question 28",
            accessor: "question28",
            width: "105px"
          },
          {
            Header: "Question 29",
            accessor: "question29",
            width: "105px"
          },
          {
            Header: "Question 30",
            accessor: "question30",
            width: "105px"
          },
          {
            Header: "Question 31",
            accessor: "question31",
            width: "105px"
          },
          {
            Header: "Question 32",
            accessor: "question32",
            width: "105px"
          },
          {
            Header: "Question 33",
            accessor: "question33",
            width: "105px"
          },
];

const columnsPGSGA = [
          {
            Header: "Date",
            accessor: "date",
            width: "200px"
          },
          {
            Header: "Status",
            accessor: "status",
            width: "50px"
          },
          {
            Header: "Question 1-1",
            accessor: "question1_1",
            width: "110px"
          },
          {
            Header: "Question 1-2",
            accessor: "question1_2",
            width: "110px"
          },
          {
            Header: "Question 1-3",
            accessor: "question1_3",
            width: "110px"
          },
          {
            Header: "Question 1-4",
            accessor: "question1_4",
            width: "110px"
          },
          {
            Header: "Question 1-5",
            accessor: "question1_5",
            width: "110px"
          },
          {
            Header: "Question 2-1",
            accessor: "question2_1",
            width: "110px"
          },
          {
            Header: "Question 2-2",
            accessor: "question2_2",
            width: "115px"
          },
          {
            Header: "Question 3-1",
            accessor: "question3_1",
            width: "110px"
          },
          {
            Header: "Question 3-2",
            accessor: "question3_2",
            width: "115px"
          },
          {
            Header: "Question 3-3",
            accessor: "question3_3",
            width: "115px"
          },
          {
            Header: "Question 4",
            accessor: "question4",
            width: "95px"
          }
];

const columnsQLQC30 = [
          {
            Header: "Date",
            accessor: "created_at",
            width: "200px"
          },
          {
            Header: "Status",
            accessor: "status",
            width: "50px"
          },
          {
            Header: "Question 1",
            accessor: "question1",
            width: "95px"
          },
          {
            Header: "Question 2",
            accessor: "question2",
            width: "95px"
          },
          {
            Header: "Question 3",
            accessor: "question3",
            width: "95px"
          },
          {
            Header: "Question 4",
            accessor: "question4",
            width: "95px"
          },
          {
            Header: "Question 5",
            accessor: "question5",
            width: "95px"
          },
          {
            Header: "Question 6",
            accessor: "question6",
            width: "95px"
          },
          {
            Header: "Question 7",
            accessor: "question7",
            width: "95px"
          },
          {
            Header: "Question 8",
            accessor: "question8",
            width: "95px"
          },
          {
            Header: "Question 9",
            accessor: "question9",
            width: "95px"
          },
          {
            Header: "Question 10",
            accessor: "question10",
            width: "105px"
          },
                    {
            Header: "Question 11",
            accessor: "question11",
            width: "105px"
          },
                    {
            Header: "Question 12",
            accessor: "question12",
            width: "105px"
          },
                    {
            Header: "Question 13",
            accessor: "question13",
            width: "105px"
          },
                    {
            Header: "Question 14",
            accessor: "question14",
            width: "105px"
          },
                    {
            Header: "Question 15",
            accessor: "question15",
            width: "105px"
          },
                    {
            Header: "Question 16",
            accessor: "question16",
            width: "105px"
          },
                    {
            Header: "Question 17",
            accessor: "question17",
            width: "105px"
          },
                    {
            Header: "Question 18",
            accessor: "question18",
            width: "105px"
          },
                    {
            Header: "Question 19",
            accessor: "question19",
            width: "105px"
          },
                    {
            Header: "Question 20",
            accessor: "question20",
            width: "105px"
          },
          {
            Header: "Question 21",
            accessor: "question21",
            width: "105px"
          },
          {
            Header: "Question 22",
            accessor: "question22",
            width: "105px"
          },
          {
            Header: "Question 23",
            accessor: "question23",
            width: "105px"
          },
          {
            Header: "Question 24",
            accessor: "question24",
            width: "105px"
          },
          {
            Header: "Question 25",
            accessor: "question25",
            width: "105px"
          },
          {
            Header: "Question 26",
            accessor: "question26",
            width: "105px"
          },
          {
            Header: "Question 27",
            accessor: "question27",
            width: "105px"
          },
          {
            Header: "Question 28",
            accessor: "question28",
            width: "105px"
          },
          {
            Header: "Question 29",
            accessor: "question29",
            width: "105px"
          },
          {
            Header: "Question 30",
            accessor: "question30",
            width: "105px"
          }
];

const columnsQLQLC29 = [
          {
            Header: "Date",
            accessor: "created_at",
            width: "200px"
          },
          {
            Header: "Status",
            accessor: "status",
            width: "50px"
          },
          {
            Header: "Question 31",
            accessor: "question31",
            width: "105px"
          },
                    {
            Header: "Question 32",
            accessor: "question32",
            width: "105px"
          },
                    {
            Header: "Question 33",
            accessor: "question33",
            width: "105px"
          },
                    {
            Header: "Question 34",
            accessor: "question34",
            width: "105px"
          },
                    {
            Header: "Question 35",
            accessor: "question35",
            width: "105px"
          },
                    {
            Header: "Question 36",
            accessor: "question36",
            width: "105px"
          },
                    {
            Header: "Question 37",
            accessor: "question37",
            width: "105px"
          },
                    {
            Header: "Question 38",
            accessor: "question38",
            width: "105px"
          },
                    {
            Header: "Question 39",
            accessor: "question39",
            width: "105px"
          },
                    {
            Header: "Question 40",
            accessor: "question40",
            width: "105px"
          },
          {
            Header: "Question 41",
            accessor: "question41",
            width: "105px"
          },
          {
            Header: "Question 42",
            accessor: "question42",
            width: "105px"
          },
          {
            Header: "Question 43",
            accessor: "question43",
            width: "105px"
          },
          {
            Header: "Question 44",
            accessor: "question44",
            width: "105px"
          },
          {
            Header: "Question 45",
            accessor: "question45",
            width: "105px"
          },
          {
            Header: "Question 46",
            accessor: "question46",
            width: "105px"
          },
          {
            Header: "Question 47",
            accessor: "question47",
            width: "105px"
          },
          {
            Header: "Question 48",
            accessor: "question48",
            width: "105px"
          },
          {
            Header: "Question 49",
            accessor: "question49",
            width: "105px"
          },
          {
            Header: "Question 50",
            accessor: "question50",
            width: "105px"
          },
          {
            Header: "Question 51",
            accessor: "question51",
            width: "105px"
          },
          {
            Header: "Question 52",
            accessor: "question52",
            width: "105px"
          },
          {
            Header: "Question 53",
            accessor: "question53",
            width: "105px"
          },
          {
            Header: "Question 54",
            accessor: "question54",
            width: "105px"
          },
          {
            Header: "Question 55",
            accessor: "question55",
            width: "105px"
          },
          {
            Header: "Question 56",
            accessor: "question56",
            width: "105px"
          },
          {
            Header: "Question 57",
            accessor: "question57",
            width: "105px"
          },
          {
            Header: "Question 58",
            accessor: "question58",
            width: "105px"
          },
          {
            Header: "Question 59",
            accessor: "question59",
            width: "105px"
          },
];

const columnsQLQBR23 = [
          {
            Header: "Date",
            accessor: "created_at",
            width: "200px"
          },
          {
            Header: "Status",
            accessor: "status",
            width: "50px"
          },
          {
            Header: "Question 31",
            accessor: "question31",
            width: "105px"
          },
                    {
            Header: "Question 32",
            accessor: "question32",
            width: "105px"
          },
                    {
            Header: "Question 33",
            accessor: "question33",
            width: "105px"
          },
                    {
            Header: "Question 34",
            accessor: "question34",
            width: "105px"
          },
                    {
            Header: "Question 35",
            accessor: "question35",
            width: "105px"
          },
                    {
            Header: "Question 36",
            accessor: "question36",
            width: "105px"
          },
                    {
            Header: "Question 37",
            accessor: "question37",
            width: "105px"
          },
                    {
            Header: "Question 38",
            accessor: "question38",
            width: "105px"
          },
                    {
            Header: "Question 39",
            accessor: "question39",
            width: "105px"
          },
                    {
            Header: "Question 40",
            accessor: "question40",
            width: "110px"
          },
          {
            Header: "Question 41",
            accessor: "question41",
            width: "105px"
          },
          {
            Header: "Question 42",
            accessor: "question42",
            width: "105px"
          },
          {
            Header: "Question 43",
            accessor: "question43",
            width: "105px"
          },
          {
            Header: "Question 44",
            accessor: "question44",
            width: "110px"
          },
          {
            Header: "Question 45",
            accessor: "question45",
            width: "105px"
          },
          {
            Header: "Question 46",
            accessor: "question46",
            width: "110px"
          },
          {
            Header: "Question 47",
            accessor: "question47",
            width: "105px"
          },
          {
            Header: "Question 48",
            accessor: "question48",
            width: "110px"
          },
          {
            Header: "Question 49",
            accessor: "question49",
            width: "110px"
          },
          {
            Header: "Question 50",
            accessor: "question50",
            width: "105px"
          },
          {
            Header: "Question 51",
            accessor: "question51",
            width: "105px"
          }
];

const columnsSleepInit = [
          {
            Header: "Date",
            accessor: "date",
            width: "200px"
          },
          {
            Header: "Bed Time",
            accessor: "bed_time",
            width: "95px"
          },
          {
            Header: "Awake Time",
            accessor: "awake_time",
            width: "50px"
          }
];

const columnsFFQ = [
          {
            Header: "Date",
            accessor: "date",
            width: "200px"
          },
          {
            Header: "Status",
            accessor: "status",
            width: "95px"
          }
];

const columnsSleepQuality = [
          {
            Header: "Date",
            accessor: "date",
            width: "200px"
          },
          {
            Header: "Drugs",
            accessor: "drugs",
            width: "95px"
          },
          {
            Header: "Nigthmares",
            accessor: "nigthmares",
            width: "95px"
          },
          {
            Header: "Sweating",
            accessor: "sweating",
            width: "95px"
          }
];

const columnsWater = [
          {
            Header: "Date",
            accessor: "date",
            width: "200px"
          },
          {
            Header: "Water",
            accessor: "water",
            width: "95px"
          }
];

const columnsWeight = [
          {
            Header: "Date",
            accessor: "date",
            width: "200px"
          },
          {
            Header: "Weight",
            accessor: "weight",
            width: "95px"
          }
];

const columnsVoice = [
          {
            Header: "Date",
            accessor: "created_at",
            width: "200px"
          },
          {
            Header: "Duration",
            accessor: "duration",
            width: "95px"
          },
          {
            Header: "Response type",
            accessor: "voice_response_type",
            width: "95px"
          }
];

const columnsActivity = [
          {
            Header: "Date",
            accessor: "record_date",
            width: "200px"
          },
          {
            Header: "Steps",
            accessor: "steps",
            width: "95px"
          },
          {
            Header: "Distance",
            accessor: "distance",
            width: "95px"
          },
          {
            Header: "Workout type",
            accessor: "workout_type",
            width: "95px"
          },
          {
            Header: "Duration",
            accessor: "duration",
            width: "95px"
          },
          {
            Header: "Active calories",
            accessor: "active_calories",
            width: "95px"
          },
          {
            Header: "Resting calories",
            accessor: "resting_calories",
            width: "95px"
          }
];

const columnsSleep = [
          {
            Header: "Date",
            accessor: "date",
            width: "200px"
          },
          {
            Header: "Start time",
            accessor: "start_time",
            width: "200px"
          },
          {
            Header: "End time",
            accessor: "end_time",
            width: "200px"
          },
          {
            Header: "Sleep interruptions",
            accessor: "sleep_interruptions",
            width: "95px"
          },
          {
            Header: "Awake duration",
            accessor: "awake_duration",
            width: "95px"
          }
];

const legendsHADS = () => {
  return (
    <div>
      <span
          style={{
            width: '15px',
            height: '15px',
            background: 'black',
            borderRadius: '5px',
            display: 'inline-block',
            marginRight: '5px',
          }}
      ></span>
      <span style={{ marginRight: '5px' }}>Normal (0 - 7)</span>
      <span
          style={{
            width: '15px',
            height: '15px',
            background: 'blue',
            borderRadius: '5px',
            display: 'inline-block',
            marginLeft: '15px',
            marginRight: '5px',
          }}
      ></span>
      <span style={{ marginRight: '5px' }}>Borderline abnormal (8 - 10)</span>
      <span
          style={{
            width: '15px',
            height: '15px',
            background: 'red',
            borderRadius: '5px',
            display: 'inline-block',
            marginLeft: '15px',
            marginRight: '5px',
          }}
      ></span>
      <span style={{ marginRight: '5px' }}>Abnormal (11 - 21)</span>
    </div>
    
  )
}

const legendsPSQI = () => {
  return (
    <div>
      <span style={{ marginRight: '5px' }}>0 indicates no difficulty and 21 indicates severe difficulties in all areas</span>
    </div>
  )
}


const HADSQuestionnaireScore = (questionnaire) => {
    var sum = 0;
    let onlyQuestions = {...questionnaire}

    delete onlyQuestions.created_at;
    delete onlyQuestions.participantID;
    delete onlyQuestions.status;
    delete onlyQuestions.sub;
    delete onlyQuestions.tempId;
    delete onlyQuestions.timestamp;
    delete onlyQuestions.category;
    delete onlyQuestions.updated_at;

    for(var questionResponse in onlyQuestions){
        if(!(questionResponse.split('_')[1]%2)){
          sum += Number(onlyQuestions[questionResponse])
        }
    }

    return sum;
}

const PSQIQuestionnaireScore = (questionnaire) => {
    //the time is in HH:mm format
    //if time of sleep if between 13 and 24 hours, it will return 1 because the patient slept during the night
    const correctDayWhenSleep = (time) => {
      if(time.split(':')[0]>=13){
        return '1'
      } else {
        return '2';
      }
    }

    let onlyQuestions = {...questionnaire}
    let component1 = 0;
    let component2 = 0;
    let component3 = 0;
    let component4 = 0;
    let component4_1 = 0;
    let component4_2 = 0;
    let component4_2_1 = 0;
    let component4_2_2 = 0;
    let component5 = 0;
    let component6 = 0;
    let component7 = 0;

    delete onlyQuestions.created_at;
    delete onlyQuestions.participantID;
    delete onlyQuestions.status;
    delete onlyQuestions.sub;
    delete onlyQuestions.tempId;
    delete onlyQuestions.timestamp;
    delete onlyQuestions.category;
    delete onlyQuestions.updated_at;
    delete onlyQuestions.date;

    for(var questionResponse in onlyQuestions){
      const value = onlyQuestions[questionResponse];          //some answers range from 1-4, instead of 0-3 as suggested in the score calculation
      
      switch (questionResponse.split('_')[0]) {
        case 'question6': component1 = value-1;
          break;
        case 'question2':
          if(Number(value) > 60){
            component2 += 3;
          } else if (Number(value) >= 31){
            component2 += 2;
          } else if (Number(value) >= 16){
            component2 += 1;
          }
          break;
        case 'question5':
          switch (questionResponse.split('_')[1]) {
            case 'a':
              component2 += Number(value-1);
              break;
            case 'b':
            case 'c':
            case 'd':
            case 'e':
            case 'f':
            case 'g':
            case 'h':
            case 'i':
              component5 += Number(value-1);
              break;
            case 'j':
              if(questionResponse==='question5_j_a'){
                component5 += Number(value-1);
              }
              break;
            default:
              break;
          }
          break;
        case 'question4':
          component4_1 = Number(value);
          if(Number(value) < 5){
            component3 = 3;
          } else if (Number(value) <= 6){
            component3 = 2;
          } else if (Number(value) <= 7){
            component3 = 1;
          }
          break;
        case 'question3':
          component4_2_1 = new Date(`01/${correctDayWhenSleep(value)}/1970 ${value}`);
          break;
        case 'question1':
          component4_2_2 = new Date(`01/${correctDayWhenSleep(value)}/1970 ${value}`);
          break;
        case 'question7':
          component6 = value-1;
          break;
        case 'question8':
        case 'question9':
          component7 += Number(value-1);
          break;
        default:
          break;
      }
    }
    
    //component2 final score
    if(component2>=5){
      component2 = 3;
    } else if (component2>=3){
      component2 = 2;
    } else if (component2>=1){
      component2 = 1;
    }

    //component4 final score
    component4_2 = (Math.abs(component4_2_1 - component4_2_2) / 36e5);
    component4 = component4_1/component4_2*100;

    if(component4 > 85){
      component4 = 0;
    } else if (component4 >= 75){
      component4 = 1;
    } else if (component4 >= 65){
      component4 = 2;
    } else {
      component4 = 3;
    }
    
    //component5 final score
    if(component5 >= 19){
      component5 = 3;
    } else if (component5 >= 10){
      component5 = 2;
    } else if (component5 >= 1){
      component5 = 1;
    } else {
      component5 = 0;
    }

    //component7 final score
    if(component7 >= 5){
      component7 = 3;
    } else if (component7 >= 3){
      component7 = 2;
    } else if (component7 >= 1){
      component7 = 1;
    } else {
      component7 = 0;
    }
    
    return (component1 + component2 + component3 + component4 + component5 + component6 + component7);
}

const PGSGAQuestionnaireScore = (questionnaire) => {
    let component1 = 0;
    let component1_1 = 0;
    let component1_4 = 0;

    let onlyQuestions = {...questionnaire}

    delete onlyQuestions.created_at;
    delete onlyQuestions.participantID;
    delete onlyQuestions.status;
    delete onlyQuestions.sub;
    delete onlyQuestions.tempId;
    delete onlyQuestions.timestamp;
    delete onlyQuestions.category;
    delete onlyQuestions.updated_at;
    delete onlyQuestions.date;

    for(var questionResponse in onlyQuestions){
        const value = onlyQuestions[questionResponse];          //some answers range from 1-4, instead of 0-3 as suggested in the score calculation

        switch (questionResponse.split('_')[0]) {
          case 'question1' :
            switch (questionResponse.split('_')[1]) {
            case '1':
              component1_1 = Number(value);
              break;
            case '4':
              component1_4 = Number(value);
              break;
            default:
              break;
          }
          break;
        default:
            break;
        }
    }

    //component1 final score
    component1 = Math.abs(((component1_4-component1_1)/component1_4)*100);

    if(component1 >= 20){
      component1 = 4;
    } else if (component1 >= 10){
      component1 = 3;
    } else if (component1 >= 6){
      component1 = 2;
    } else if (component1 >= 2){
      component1 = 1;
    } else {
      component1 = 0;
    }

    return component1;
}

const questionnairesScore = (questionnaire, questionnaire_type) => {
      switch (questionnaire_type) {
        case CategoryGeneralQuestionnaireNames.HADS:
            return HADSQuestionnaireScore(questionnaire);
        case CategoryGeneralQuestionnaireNames.PSQI:
            return PSQIQuestionnaireScore(questionnaire);
        case CategoryGeneralQuestionnaireNames.DEBQ:
            return "TODO";
        case CategoryGeneralQuestionnaireNames.PGSGA:
            return PGSGAQuestionnaireScore(questionnaire);
        case CategoryGeneralQuestionnaireNames.QLQC30:
            return "TODO";
        case CategoryGeneralQuestionnaireNames.QLQLC29:
            return "TODO";
        case CategoryGeneralQuestionnaireNames.QLQBR23:
            return "TODO";
        default:
            return 0;
      }
}

// -.-' the values from HADS questionnaire are stored from 1 - 4 in the database, but on the original questionnaire range from 0 - 3
const HADSQuestionnaireDataHandling = (questionnaire) => {
    let onlyQuestions = {...questionnaire}
    const HADSQuestionnaireNonQuestionsFields =  ['created_at', 'participantID', 'status', 'sub', 'tempId', 'timestamp', 'category', 'updated_at']
    
    Object.keys(onlyQuestions).forEach((item) => {
      if(!HADSQuestionnaireNonQuestionsFields.includes(item) && !(onlyQuestions[item]===0 || !onlyQuestions[item])){
        onlyQuestions[item]--;
      }
    })

    return onlyQuestions;
}

//the sleep initialization dates contain the date and time in the same string
const SleepInitDataHandling = (questionnaire) => {
    let parsedQuestion = {...questionnaire}
    const sleepInitFieldsDate =  ['awake_time', 'bed_time']
 
    parsedQuestion[sleepInitFieldsDate[0]] = new Date(parsedQuestion[sleepInitFieldsDate[0]]).toTimeString().split(' ')[0];
    parsedQuestion[sleepInitFieldsDate[1]] = new Date(parsedQuestion[sleepInitFieldsDate[1]]).toTimeString().split(' ')[0];

    return parsedQuestion;
}

const GeneralQuestionnaire = ({questionnaire_type}) => {
    const { participantID } = useParams();
    const [loading, setIsLoading] = useState(true);
    const [questionnaires, setQuestionnaires] = useState([]);
    const [headers, setHeaders] = useState([]);

    useEffect(() => {
        let questionnaire_url = null;
        async function getStartupPage(){
            switch (questionnaire_type) {
                case CategoryGeneralQuestionnaireNames.HADS:
                    questionnaire_url = hadsURLPATH;
                    setHeaders(columnsHADS)
                    break;
                case CategoryGeneralQuestionnaireNames.PSQI:
                    questionnaire_url = psqiURLPATH;
                    setHeaders(columnsPSQI)
                    break;
                case CategoryGeneralQuestionnaireNames.DEBQ:
                    questionnaire_url = debqURLPATH;
                    setHeaders(columnsDEBQ)
                    break;
                case CategoryGeneralQuestionnaireNames.PGSGA:
                    questionnaire_url = pgsgaURLPATH;
                    setHeaders(columnsPGSGA)
                    break;
                case CategoryGeneralQuestionnaireNames.QLQC30:
                    questionnaire_url = qlqc30URLPATH;
                    setHeaders(columnsQLQC30)
                    break;
                case CategoryGeneralQuestionnaireNames.QLQLC29:
                    questionnaire_url = qlqlc29URLPATH;
                    setHeaders(columnsQLQLC29)
                    break;
                case CategoryGeneralQuestionnaireNames.QLQBR23:
                    questionnaire_url = qlqbr23URLPATH;
                    setHeaders(columnsQLQBR23)
                    break;
                case CategoryGeneralQuestionnaireNames.SLEEPINIT:
                    questionnaire_url = sleepInitURLPATH;
                    setHeaders(columnsSleepInit)
                break;
                case CategoryGeneralQuestionnaireNames.FFQ:
                    questionnaire_url = ffqURLPATH;
                    setHeaders(columnsFFQ)
                break;
                case CategoryGeneralQuestionnaireNames.SLEEPQUALITY:
                    questionnaire_url = sleepQualityURLPATH;
                    setHeaders(columnsSleepQuality)
                break;
                case CategoryGeneralQuestionnaireNames.WATER:
                    questionnaire_url = waterURLPATH;
                    setHeaders(columnsWater)
                break;
                case CategoryGeneralQuestionnaireNames.WEIGHT:
                    questionnaire_url = weightURLPATH;
                    setHeaders(columnsWeight)
                break;
                case CategoryGeneralQuestionnaireNames.VOICE:
                    questionnaire_url = voiceURLPATH;
                    setHeaders(columnsVoice)
                break;
                case CategoryGeneralQuestionnaireNames.ACTIVITY:
                    questionnaire_url = activityURLPATH;
                    setHeaders(columnsActivity)
                break;
                case CategoryGeneralQuestionnaireNames.SLEEP:
                    questionnaire_url = sleepURLPATH;
                    setHeaders(columnsSleep)
                break;
                default:
                    break;
            }
            
            const questions = await fetchData(`${awsParticipantsClinitianInterfaceAPI}/${participantsListUrl}/${participantID}/${questionnaire_url}`);
            
            debug && console.log(questions);

            let parsedQuestions = [];
            for (const value in questions){
                var sum = 0;
                let clonedQuestion = {...questions[value]}

                //it is necessary to change the answers from HADS questionnaire because they were not stored as requested by the original questionnaire, i.e., the values range from 1 - 4 instead of 0 - 3
                if (questionnaire_type === CategoryGeneralQuestionnaireNames.HADS){
                  clonedQuestion = HADSQuestionnaireDataHandling(clonedQuestion);
                } else if(questionnaire_type === CategoryGeneralQuestionnaireNames.SLEEPINIT){
                  clonedQuestion = SleepInitDataHandling(clonedQuestion);
                }

                sum = questions[value].status === QuestionnairesStatus.COMPLETED ? questionnairesScore(clonedQuestion, questionnaire_type) : '-';

                clonedQuestion['Score'] = sum
                parsedQuestions.push(clonedQuestion)
            }
            debug && console.log(parsedQuestions);
            setQuestionnaires(parsedQuestions);
            setIsLoading(false)
        }

        getStartupPage();
	}, []);

const Legends = () => {
  switch (questionnaire_type) {
      case CategoryGeneralQuestionnaireNames.HADS:
          return legendsHADS();
      case CategoryGeneralQuestionnaireNames.PSQI:
          return legendsPSQI();
      default:
        return <></>;
  }
}

const ScoreFontColor = (HeaderColumnName, RowValue) => {
  if(HeaderColumnName === 'Score'){
    switch (questionnaire_type) {
        case CategoryGeneralQuestionnaireNames.HADS:
            if(RowValue > 10){
              return 'red';
            } else if (RowValue > 7){
              return 'blue';
            } else {
              return 'black';
            }
        default:
          return 'black';
    }
  }
}

function Table({ columns, data }) {
const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({
    columns,
    data
  });

  return (
    <div className='flex-bet' style={{lineHeight: '35px'}}>
        <div style={{overflow: 'auto', marginBottom: 5, boxShadow: '0px 2px 8px rgb(0 0 0 / 24%)', borderRadius: '10px'}}>
          <table style={{flex: 1}} {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps({
                      style: {
                          color: 'black',
                          textAlign: 'center',
                          backgroundColor: '#DEE3ED',
                          padding: 5,
                          flex: 1
                      }
                    })}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {
            <tbody {...getTableBodyProps()}>
              {rows.map((row, idx) => {
                  prepareRow(row);
                  return (
                  <tr key={"Questionnaire" + questionnaire_type + "A" + idx} id={"A"+idx} {...row.getRowProps()}>
                      {row.cells.map((cell, idxRow) => {
                        //score handling
                        const colorRowFont = ScoreFontColor(cell.column.Header, cell.value);

                        return (
                          <td  key={"Questionnaire" + questionnaire_type + "C" + idxRow} id={"C"+idxRow} {...cell.getCellProps({
                              style: {
                              minWidth: cell.column.width,
                              textAlign: 'center',
                              flex: 1,
                              color: colorRowFont
                            },
                          })}>
                              {cell.render("Cell", { value: cell.value})}
                          </td>
                        );
                      })}
                  </tr>
                  );
              })}
            </tbody>
            }
          </table>
        </div>
    </div>
  );
}

    return (
    <>
        {loading ?
            (
            <div className='mt'> 
                <p className='mt-3 bold'>Loading</p>
            </div>
            ) :
            (
            questionnaires.length !== 0 ?
                (
                <div>
                  <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', padding: 10 }}>
                  {
                    Legends()
                  }
                  </div>
                  
                  <div>
                    {
                        <Table columns={headers} data={questionnaires} />
                    } 
                  </div>
                </div>
                ) :
                (
                    <div style={{ display: 'flex', justifyContent: 'center'}}> 
                        <p>No entries</p>
                    </div>
                )

            )
        }
    </>
    )
}

export default GeneralQuestionnaire;