import React from 'react';
import User from './User';

const Header = () => {
  return (
    <>
      <div className="logo" style={{ margin: 'auto' }}></div>
      
      <User />
    </>
  )
};

export default Header;
