import React, { useEffect, useMemo, useState } from "react";
import { useSortBy, useTable } from "react-table";
import tw  from "twin.macro";
import "@fontsource/poppins";
import moment from "moment";
import { CancerTypes } from "../../../Assets/enums";
const debug = require('../../../config.json').debug;

const Table = tw.table`
`;

const TableHead = tw.thead`
`;

const TableRow = tw.tr`
`;

const TableHeader = tw.th`
  bg-tableHeaderPatientPage
  text-blueTextPatientPage
  py-3 px-6
  relative
  first:rounded-l-lg
  last:rounded-r-lg
  text-gray-800
  align-top
`;

const TableBody = tw.tbody`
`;

const TableData = tw.td`
  bg-white
  text-gray-800
  py-3 px-6
  border-gray-200
  text-center
  whitespace-nowrap
`;

const questionarieDataSample = [
  {
    "M0": "HA,GY,",
    "M1": "HA,GY,",
    "M2": "HA,GY,",
    "M3": "HA,GY,",
    "M4": "HA,GY,",
    "M5": "HA,GY,",
    "M6": "HA,GY,",
    "M7": "HA,GY,",
    "M8": "HA,GY,",
    "M9": "HA,GY,",
    "M10": "HA,GY,",
    "M11": "HA,GY,",
    "M12": "HA,GY,"
  },
  {
    "M0": "QLQ,GY,",
    "M1": "QLQ,GY,",
    "M2": "QLQ,GY,",
    "M3": "QLQ,GY,",
    "M4": "QLQ,GY,",
    "M5": "QLQ,GY,",
    "M6": "QLQ,GY,",
    "M7": "QLQ,GY,",
    "M8": "QLQ,GY,",
    "M9": "QLQ,GY,",
    "M10": "QLQ,GY,",
    "M11": "QLQ,GY,",
    "M12": "QLQ,GY,"
  },
    {
    "M0": "QLS,GY,",
    "M1": "QLS,GY,",
    "M2": "QLS,GY,",
    "M3": "QLS,GY,",
    "M4": "QLS,GY,",
    "M5": "QLS,GY,",
    "M6": "QLS,GY,",
    "M7": "QLS,GY,",
    "M8": "QLS,GY,",
    "M9": "QLS,GY,",
    "M10": "QLS,GY,",
    "M11": "QLS,GY,",
    "M12": "QLS,GY,"
  },
  {
    "M0": "PG,GY,",
    "M1": "PG,GY,",
    "M2": "PG,GY,",
    "M3": "PG,GY,",
    "M4": "PG,GY,",
    "M5": "PG,GY,",
    "M6": "PG,GY,",
    "M7": "PG,GY,",
    "M8": "PG,GY,",
    "M9": "PG,GY,",
    "M10": "PG,GY,",
    "M11": "PG,GY,",
    "M12": "PG,GY,"
  },
  {
    "M0": "Voice,GY,",
    "M1": "Voice,GY,",
    "M2": "Voice,GY,",
    "M3": "Voice,GY,",
    "M4": "Voice,GY,",
    "M5": "Voice,GY,",
    "M6": "Voice,GY,",
    "M7": "Voice,GY,",
    "M8": "Voice,GY,",
    "M9": "Voice,GY,",
    "M10": "Voice,GY,",
    "M11": "Voice,GY,",
    "M12": "Voice,GY,",
  },
  {
    "M0": "PSQI,GY,",
    "M1": ",,",
    "M2": ",,",
    "M3": "PSQI,GY,",
    "M4": ",,",
    "M5": ",,",
    "M6": "PSQI,GY,",
    "M7": ",,",
    "M8": ",,",
    "M9": "PSQI,GY,",
    "M10": ",,",
    "M11": ",,",
    "M12": "PSQI,GY,",
  },
  {
    "M0": "DEB,GY,",
    "M1": ",,",
    "M2": ",,",
    "M3": "DEB,GY,",
    "M4": ",,",
    "M5": ",,",
    "M6": "DEB,GY,",
    "M7": ",,",
    "M8": ",,",
    "M9": "DEB,GY,",
    "M10": ",,",
    "M11": ",,",
    "M12": "DEB,GY,"
  },
  {
    "M0": "FFQ,GY,",
    "M1": ",,",
    "M2": ",,",
    "M3": ",,",
    "M4": ",,",
    "M5": ",,",
    "M6": ",,",
    "M7": ",,",
    "M8": ",,",
    "M9": ",,",
    "M10": ",,",
    "M11": ",,",
    "M12": "FFQ,GY,",
  }
]

const lastMonthTrial = 12;

function getAtomStyle(prop) {
  switch (prop) {
    case "GR":              // Green
      return { backgroundColor: "#8AC926", color: "white" };
    case "GY":              // Grey
      return { backgroundColor: "#D9D9D9", color: "grey" };
    case "Y":               // Yellow
      return { backgroundColor: "#FFCA3A", color: "white" };
    case "R":
      return { backgroundColor: "#FF595E", color: "white" };
    case "UNK": {
      return { backgroundColor: "#3a3dff", color: "white" };
    }
    default:
      return {};
  }
}

export function ActiveMonitoring(props) {
  const {participantData, patientInformation} = props;
  const [questionarieData, setquestionarieData] = useState([]);
  const [dashboardQuestionnairesNotPresent, setDashboardQuestionnairesNotPresent] = useState([]);
  const [QLQtype, setQLQtype] = useState("QLS");

  function handleAtomColorsForTable(questionnaireStructData) {
    let color_data = []
    let q_data = []
    let d_array = []
   
    
    questionnaireStructData.forEach((obj) => {
      let color_data_arr = {};
      let q_data_array = {};
      let date_array = {};
      for (let prop in obj) {
        const parts = obj[prop].split(",");
        q_data_array[prop] = parts[0] === undefined ? "null" : parts[0];
        color_data_arr[prop] = parts[1] === undefined ? "null" : parts[1];
        date_array[prop] = parts[2] === undefined ? "null" : parts[2];
      }
      color_data.push(color_data_arr);
      q_data.push(q_data_array);
      d_array.push(date_array);
    });
  }

  useEffect(() => {
    let questionnaireStructData = questionarieDataSample.map(obj=> ({...obj}));           //copy the array without using the same reference
    let missingQuestionnaireAux = [];

    if (patientInformation.profile){
      const qlqType = patientInformation.profile === CancerTypes.BREAST ? "QBR" : "QLC";
      setQLQtype(qlqType);
    } else {
      setQLQtype('UNK');
    }

    !debug && console.log(participantData);

    //mark questionnaires as completed
    participantData.forEach((e, idx, arr) => {
      let registerQuestionnaire = false;          //control variable that verifies if the questionnaire was registered on the dashboard

      if (e.actualMonth >= 0){
        let m = 'M'+e.actualMonth;
        let questionnaire_month_margin = false;
        for (var k = 0; k < questionnaireStructData.length && e.actualMonth <= lastMonthTrial+1; k++){
          if(questionnaireStructData[k][m]){
            const questionnaireStructData_position_array = Array.from(questionnaireStructData[k][m].split(','));

            if (questionnaireStructData_position_array[0] === e.category){
              if(questionnaireStructData_position_array[1] === 'GR' && e.actualMonth!==0 && !questionnaire_month_margin){          //Verifies previous month if questionnaire type is already green
                k = 0;
                questionnaire_month_margin = true;
                m = 'M'+(e.actualMonth-1);
                debug && console.log(`Reduced month to ${m} for ${e.category}`);
              } else if (questionnaireStructData_position_array[1] === 'GY'){
                if (e.status === 'completed'){
                  questionnaireStructData_position_array[1] = 'GR'
                } else if (e.status === 'incompleted'){
                  questionnaireStructData_position_array[1] = 'Y'
                } else {
                  questionnaireStructData_position_array[1] = 'UNK'
                }
              
                questionnaireStructData_position_array[2] = e.convDate;
                questionnaireStructData[k][m] = questionnaireStructData_position_array.join(',');
                questionnaire_month_margin = false;
                registerQuestionnaire = true;
                break;
              }
            }
          }

          //some questionnaires that have periodicity of 3 months, are not really 3 months separated but 91 days. Thus, it is necessary
          //to verifify, if no questionnaireStructData was found, on the previous month
          if(k ===  questionnaireStructData.length-1 && !questionnaire_month_margin && m!=='M0'){
            k = 0;
            questionnaire_month_margin = true;
            m = 'M'+(e.actualMonth-1);
            debug && console.log(`Reduced month to ${m} for ${e.category}`);
          }
        }
      }

      if(!registerQuestionnaire){
        const missingQuestionnaireCategory = e.category !== 'QLS' ? e.category : e.questionnaireAWScategory;      //QLS are specifc for QLQ_BR or QLQ_LC
        console.log("Questionnaire not present on dashboard!!!");
        missingQuestionnaireAux.push([`${e.convDate} - ${missingQuestionnaireCategory} (${e.status})`]);
        debug && console.log(e);
      }
    })

    setDashboardQuestionnairesNotPresent(missingQuestionnaireAux);

    //mark as not responding
    const currentMonthM = moment().diff(moment(patientInformation.StartDate, 'DD/MM/YYYY'), 'months').toString();
  
    for (var m = 0; m < currentMonthM && m < lastMonthTrial; m++) {
      for (var questionnaire_struct_position in questionnaireStructData){
        let month = 'M'+m;
        let z = questionnaireStructData[questionnaire_struct_position][month].split(',')
        if (z[1] === 'GY'){
          z[1] = 'R';
        }
        questionnaireStructData[questionnaire_struct_position][month] = z.join(',');
      }
    }

    //handleAtomColorsForTable(questionnaireStructData);

    setquestionarieData(questionnaireStructData);
  }, []);

  const columns = useMemo(
    () =>
      questionarieData[0]
        ? Object.keys(questionarieData[0])
            .filter((key) => key !== "rating")
            .map((key, idx) => {
              if (key === "image")
                return {
                  Header: key,
                  accessor: key,
                  Cell: ({ value }) => <img key={"D" + idx} src={value} />,
                  maxWidth: 70,
                  key: ({idx}) => ("D" + idx)
                };

              return { Header: key, accessor: key, key: ({idx}) => ("D" + idx) };
            })
        : [],
    [questionarieData]
  );

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [...columns,]);
  };

  const tableInstance = useTable(
    {
      columns: columns,
      data: questionarieData,
    },
    tableHooks,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = tableInstance;

  const commonAtomStyle = {
    width: "44px",
    height: "37px",
    borderRadius: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight:0.8,
    overflow: "hidden",
    textOverflow: ""
  };

  const dateStatusStyle = {
    fontSize: "8px"
  };

  return (
  <>
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
  <div style={{ textAlign: 'left', fontSize: '21px' }}>Active monitoring: questionnaires</div>
  <div style={{ textAlign: 'right', display: 'inline-block' }}>
  <span
    style={{
      width: '15px',
      height: '15px',
      background: '#8AC926',
      borderRadius: '5px',
      display: 'inline-block',
      marginRight: '5px',
    }}
  ></span>
  <span style={{ marginRight: '5px' }}>Submitted on time</span>
  <span
    style={{
      width: '15px',
      height: '15px',
      background: '#FFCA3A',
      borderRadius: '5px',
      display: 'inline-block',
      marginLeft: '15px',
      marginRight: '5px',
    }}
  ></span>
  <span style={{ marginRight: '5px' }}>Incompleted</span>
  <span
    style={{
      width: '15px',
      height: '15px',
      background: '#FF595E',
      borderRadius: '5px',
      display: 'inline-block',
      marginLeft: '15px',
      marginRight: '5px',
    }}
  ></span>
  <span style={{ marginRight: '5px' }}>Not submitted</span>

    </div>
    </div>
    <Table style={{width: '100%', marginTop: '10px',  marginBottom: '30px', boxShadow: '0px 2px 8px rgb(0 0 0 / 14%)', borderRadius: '16px'}} {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup, idx) => (
            <TableRow key={"A"+idx} id={"A"+idx} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, idxHeader) => (
                <TableHeader key={"Header1"+idxHeader} id={"A"+idxHeader}>
                  {column.render("Header")}
                  {column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}
                </TableHeader>
              ))}
            </TableRow>
          ))}
        </TableHead>

        <TableBody {...getTableBodyProps()}>
          {rows.map((row, idx) => {
            prepareRow(row);
        
            return (
              <TableRow key={"B"+idx} id={"B"+idx} {...row.getRowProps()}>
                {row.cells.map((cell, idxRow) => {
                  const cellValue = cell.value.toString(); // Convert the cell value to string
                  let [infoStatus, colorStatus, dateStatus] = cellValue.split(","); // Split the cell value
                  let modifiedValue = infoStatus.split(" ").map((string) => {
                    if (string === 'QLS'){
                      return(
                        <div key={"Header4"+idxRow} id={"A"+idxRow}>
                          <span>
                            {QLQtype}
                            <br/>
                            {<span style={dateStatusStyle}>{dateStatus}</span>}
                          </span>
                        </div>
                      )
                    }
                    return(
                      <div key={"Header4"+idxRow} id={"A"+idxRow}>
                        <span>
                          {infoStatus}
                          <br/>
                          {<span style={dateStatusStyle}>{dateStatus}</span>}
                        </span>
                      </div>
                      );

                  });
                  
                  return (
                    <TableData key={"C"+idx} id={"C"+idx} {...cell.getCellProps()}>
                    
                      <span style={{...commonAtomStyle, ...getAtomStyle(colorStatus)}}>
                        {cell.render("Cell", { value: modifiedValue})} {/* Pass the modified value to the Cell renderer */}
                      </span>
                    </TableData>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {
        dashboardQuestionnairesNotPresent.length>0 && 
        <div>
          <div>The dashboard does not display the following questionnaires:</div>
          {dashboardQuestionnairesNotPresent.map((missingQuestionnaire, idx) => (
            <div key={"MissingQuestionnaire"+idx} id={"Q"+idx}>
             {missingQuestionnaire}
            </div>
          ))}
        </div>
      }
    </>
  );
  }

export default ActiveMonitoring