import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, Tooltip } from 'recharts';

import React from 'react';

const ActivityChart = (props) => {
  const {data} = props;
  const sleepData = data ? data.toReversed() : null;

  return (
    <div style={{overflow: "hidden", flexBasis: "80%", margin: '0 auto'}}>
        <ResponsiveContainer aspect={1}>
            <BarChart data={sleepData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" tick={{ fill: '#C3CBD4' }} tickCount={25} stroke="none" tickMargin={2}/>
                <YAxis dataKey="steps" tick={{ fill: '#C3CBD4' }} stroke="none" tickCount={6} tickMargin={2}/>
                <Tooltip />
                <Legend verticalAlign="top" align="top" layout='vertical' iconType={'plainline'} margin={{ bottom: 5 }}/>
                <Bar dataKey="steps" type="monotone" fill="#82ca9d" stroke="#7467F0"/>
            </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ActivityChart;