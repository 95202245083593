import { useState } from 'react';
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { MenuItems } from './MenuContent';

const Menu = (props) => {
  //  SET ACTIVE
  const { participantID } = useParams();
  const {pathname} = useLocation();
  const {email} = props;
  const navigate = useNavigate();

  const setActive = (location, index) =>{
    if(location === pathname && (index !== 4 || index !== 5 || index !== 6)){
        return 'active'
    }
  }

  //  CSS
  const [display, setDisplay] = useState(false);
  const visibility = display === false ? 'no-display' : '';
  const visibilityRev = display === true ? 'no-display' : '';

  //usar setdisplay tambem em useeffect com o pathname
  //quando clicar dar position absolute ao menu e com o seet time deslizar até à esquerda
  //e aparecem os outros

  //  SUB MENU
  const displayMenu = (index) => {
    let url = index === 4 ? 'quest/nutrition' :
              index === 5 ? 'tracking/activity':
              'coll_data/interviews'

    
    navigate(`/patient/${participantID}/${url}`);
    //setDisplay(!display);
  }

  return (
  <div className=''>
      <ul className={`${display ? 'flex-around' : 'flex-bet'} p-0 dark-txt menu-hor`}>
        {MenuItems.map((item, index)=> {
          return(
            <li key={index}
              /* classNames */
                className={ index === 5 || index === 6 ?
                  `${visibility} inline txt-center pointer ${setActive(item.url, index)}`
                :
                  `${item.title !== 'Questionnaires' ? `${visibilityRev}` : display ? '' : 'li-border'} inline txt-center pointer ${setActive(item.url, index)}`
                } 
                /* onclick */
                onClick={ index === 6 ?
                    () => displayMenu(index)
                  :
                    (() => navigate(`/patient/${participantID}/${item.url}`, {state: {email: email}}))
                }
                >
              {item.title}
            </li>
          )
        })}
      </ul>
  </div>
  )
};

export default Menu;