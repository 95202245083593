import React from 'react';
import GeneralQuestionnaire from '../GeneralQuestionnaires/GeneralQuestionnaire';

const GeneralQuestionnairesParticipantData = ({categoriesQuestionnaireTypes}) => {
    return (
    <>
        <div className='Sleep'>
        {
            categoriesQuestionnaireTypes.map((sleepEntry, i) => (
                <div className='sleep_quests' key={sleepEntry}>
                    <div className='quest'>
                        <p className='quest_title'>{sleepEntry}</p>
                        <GeneralQuestionnaire questionnaire_type={sleepEntry}/>
                        {/*
                            //displays the questionnaire date
                            <p className='quest_date'>{psqi.date}</p>
                            // arrow and points to the questionsCompletes/availableQuestions
                            import arrowright from '../../../Assets/Icons/arrow-right.svg';
                            <img src={arrowright}/> <p className='quest_value'>{processQuest(sleepEntry)}</p>
                        */}
                       
                    </div>
                </div>
            ))
        } 
        </div>

        <style>{`
            .Sleep{
                margin: 40px 20px 20px 20px;
            }

            .sleep_quests{
                margin-top: 20px;
                margin-bottom: 10px;
            }

            .quest{
                box-shadow: 0px 1px 5px 1px gray;
            }

            .quest_title{
                font-weight: bold;
                margin-left: 20px;
                margin-top: 10px;
                width: 200px;
            }

            .quest_date{
                margin-left: auto;
                margin-right: auto;
                margin-top: 10px;
                padding-right: 100px;
            }

            .quest_value{
                font-weight: bold;
                margin-top: 10px;
                align-self: center;
                margin-right: 20px;

            }

            .quest img {
                height: 16px;
                filter: invert(54%) sepia(0%) saturate(0%) hue-rotate(13deg) brightness(94%) contrast(89%);
                width: 30px;
                margin-right: 20px;
                align-self: center;
            }
        `}</style>
        </>
    )
}

export default GeneralQuestionnairesParticipantData;

/*
    //TO validate
    const processQuest = (data) => {
        let questions = 0;
        let invalid_questions = 0;

        Object.keys(data).map((e) => {
            const text =  "question";

            if(e.includes(text)){
                questions++;
                    //console.log(`key=${e}  value=${obj[e]}`)
                if(data[e] == null)
                    invalid_questions++;
            }
        });

        const result = `${questions-invalid_questions}/${questions}`;
        
        return result;
    }
*/