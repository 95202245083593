const CategoryTableHeaderParticipants = [
  'Participant Email',
  'Activity',
  'Sleep Basal',
  'DEBQ Questionnaire',
  'FFQ Questionnaire',
  'HADS Questionnaire',
  'PGSGA Questionnaire',
  'PSQI Questionnaire',
  'QLQ_BR23 Questionnaire',
  'QLQ_C30 Questionnaire',
  'QLQ_LC29 Questionnaire',
  'Sleep',
  'Sleep Initialization',
  'Voice',
  'Water intake',
  'Weight'
];

const CategoryTypesDatabase =
  {
    'Activity': 'Activity',
    'Basal': 'Sleep Quality',
    'DEBQ%20Questionnaire': 'DEBQ Questionnaire',
    'FFQ%20Questionnaire': 'FFQ Questionnaire',
    'HADS%20Questionnaire': 'HADS Questionnaire',
    'PGSGA%20Questionnaire': 'PGSGA Questionnaire',
    'PSQI%20Questionnaire': 'PSQI Questionnaire',
    'QLQ_BR23%20Questionnaire': 'QLQ_BR23 Questionnaire',
    'QLQ_C30%20Questionnaire': 'QLQ_C30 Questionnaire',
    'QLQ_LC29%20Questionnaire': 'QLQ_LC29 Questionnaire',
    'Sleep': 'Sleep',
    'Sleep%20Initialization': 'Sleep Initialization',
    'Voice': 'Voice',
    'Water%20intake': 'Water intake',
    'Weight': 'Weight'
}

const CategorySleepTypes = [
  {'Name': 'Sleep Basal', 'Database': 'Basal'},
  {'Name': 'PSQI Questionnaire', 'Database': 'PSQI%20Questionnaire'},
  {'Name': 'Sleep', 'Database': 'Sleep'},
  {'Name': 'Sleep Initialization', 'Database': 'Sleep%20Initialization'},
]

const CategoryActivityTypes = [
  {'Name': 'Activity', 'Database': 'Activity'},
]

const CategoryNutritionTypes = [
  {'Name': 'FFQ Questionnaire', 'Database': 'FFQ%20Questionnaire'},
  {'Name': 'Water intake', 'Database': 'Water%20intake'},
  {'Name': 'Weight', 'Database': 'Weight'},
  {'Name': 'DEBQ Questionnaire', 'Database': 'DEBQ%20Questionnaire'},
  {'Name': 'PGSGA Questionnaire', 'Database': 'PGSGA%20Questionnair'},
]

const CategoryVoiceTypes = [
  {'Name': 'Voice', 'Database': 'Voice'},
]

// the  "QLQ-LC29", "QLQ-BR23" are attributed accordingly to the patient cancer type
const CategoryGeneralQuestionnairesTypes = [
   "HADS", "QLQ-C30"
]

const CategorySleepDataTypes = [
  "PSQI", "Sleep Initialization", "Sleep Quality", "Sleep"
]

const CategoryActivityDataTypes = [
  "Activity"
]

const CategoryNutritionDataTypes = [
  "DEBQ",  "PGSGA", "FFQ", "Water Intake", "Weight"
]

const CategoryVoiceDataTypes = [
  "Voice"
]

const CancerTypes = {
  BREAST: "breast",
  LUNG: "lung"
}

const CategoryGeneralQuestionnaireNames = {
  HADS: "HADS",
  PSQI: "PSQI",
  DEBQ: "DEBQ",
  PGSGA: "PGSGA",
  QLQC30: "QLQ-C30",
  QLQLC29: "QLQ-LC29",
  QLQBR23: "QLQ-BR23",
  SLEEPINIT: "Sleep Initialization",
  FFQ: "FFQ",
  SLEEPQUALITY: "Sleep Quality",
  WATER: "Water Intake",
  WEIGHT: "Weight",
  VOICE: "Voice",
  ACTIVITY: "Activity",
  SLEEP: "Sleep"
} 

const QuestionnairesStatus = {
  COMPLETED: "completed",
  INCOMPLETED: "incompleted"
}

module.exports.CategoryTableHeaderParticipants = CategoryTableHeaderParticipants;
module.exports.CategoryTypesDatabase = CategoryTypesDatabase;
module.exports.CategorySleepTypes = CategorySleepTypes;
module.exports.CategoryActivityTypes = CategoryActivityTypes;
module.exports.CategoryNutritionTypes = CategoryNutritionTypes;
module.exports.CategoryVoiceTypes = CategoryVoiceTypes;
module.exports.CategoryGeneralQuestionnairesTypes = CategoryGeneralQuestionnairesTypes;
module.exports.CategorySleepDataTypes = CategorySleepDataTypes;
module.exports.CategoryActivityDataTypes = CategoryActivityDataTypes;
module.exports.CategoryNutritionDataTypes = CategoryNutritionDataTypes;
module.exports.CategoryVoiceDataTypes = CategoryVoiceDataTypes;
module.exports.CategoryGeneralQuestionnaireNames = CategoryGeneralQuestionnaireNames;
module.exports.QuestionnairesStatus = QuestionnairesStatus;
module.exports.CancerTypes = CancerTypes;
