import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams }from 'react-router-dom';
import Demographic from './Demographic';
import Clinical from './Clinical';
import Mini from './Mini/Mini';
import Consent from './Consent';
import { fetchData } from '../../../Utilities/utils';
const awsParticipantsEndpoints = require('../../../../config.json').awsParticipantsEndpoints.awsClinitianInterfaceAPI;
const participantsListUrl = require('../../../../config.json').awsParticipantsEndpoints.participantsList;
const participantDetailsInformation = require('../../../../config.json').awsParticipantsEndpoints.participantDetailsInformation;

const Crf = () => {
  const { participantID } = useParams();
  const [loading, setIsLoading] = useState(true);
  const [participantEmail, setParticipantEmail] = useState([]);
  const [isPatientAvailable, setIsPatientAvailable] = useState(false);

  useEffect(() => {
    async function defineParticipantEmail(){
      setIsLoading(true);
      const participantEmail = await fetchData(`${awsParticipantsEndpoints}/${participantsListUrl}/${participantID}/${participantDetailsInformation}`);
      setIsLoading(false)
      
      if (participantEmail && participantEmail.email){
        setParticipantEmail(participantEmail.email);
        setIsPatientAvailable(true);
      }
    };

    defineParticipantEmail();
  }, [participantID])

    const data = useLocation(); 
    const [ activeTab, setActiveTab ] = useState(1);

    const tabsEn = [
        {
            title: 'Demographic',
            id: 1
        },
        {
            title: 'Clinical',
            id: 2
        },
        {
            title: 'M.I.N.I.',
            id: false
        },
        {
            title: 'Consent',
            id: 4
        },
    ]


    const nextSection = (data) => {
        setActiveTab(data); 
    }

    console.log(activeTab)
    
  return (
    <div className='ps-6 pt-1 width no-scroll'>
        <Link to = {`/patient/${participantID}/questionnaires`}>
            <p className='main-txt small pointer'>&lt; Back</p>
        </Link>
        <div className='flex-bet'>
            <div>
                <h2 className='title dark-blue-txt large x-bold mt-1' style={{lineHeight: '35px'}}>CRF</h2>  
            </div>
            <div>
                <p className='title dark-blue-txt x-large x-bold mt-1'>{participantEmail}</p>  
            </div>
        </div>
        <div style={{paddingBottom: '10px', borderBottom: '1px solid #D9D9D9'}}>
            <ul className='flex-bet mt-2' style={{maxWidth: '600px'}} >
                {tabsEn.map((item, index) => {
                    return(
                        <li className={item.id === activeTab ? 'medium x-bold pointer' : 'medium x-bold pointer light-grey-txt tab-hover'} key={index} onClick={() => setActiveTab(item.id)} style={{borderRight: '1px solid #D9D9D9', paddingRight: '6%'}}>{item.title}</li>
                    )
                })}
            </ul>
        </div>
        {
            (
            loading ?
                (
                  <div className='mt'> 
                    <p className='mt-3 bold'>Loading</p>
                  </div>
                ) : isPatientAvailable ? 
                (
                    activeTab === 1 ?
                        <div style={{position: 'relative'}}>
                            <Demographic participantID={(data.search).split('?')[1]} nextSection={nextSection}/>
                            {/* <button className='uppercase blue-btn' style={{position: 'absolute', right: '50px', bottom: '0px'}} disabled={btnActive} onClick={() => {nextSection()}}>Next Section</button> */}
                        </div>
                    : activeTab === 2 ?
                        <div style={{position: 'relative'}}>
                            <Clinical participantID={(data.search).split('?')[1]} nextSection={nextSection}/>
                        </div>
                    : activeTab === 3 ?
                        <div style={{position: 'relative'}}>
                            <Mini participantID={(data.search).split('?')[1]}/>
                        </div>
                    : activeTab === 4 ?
                        <div style={{position: 'relative'}}>
                            <Consent participantID={(data.search).split('?')[1]}/>
                        </div>
                    :null
                ) :
                (
                  <div className='mt'> 
                    <p className='mt-3 bold'>Patient information not availble</p>
                  </div>
                )
            )
        }
    </div>
  )
}

export default Crf