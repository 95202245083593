import React from 'react';
import { CategoryNutritionDataTypes } from '../../../Assets/enums';
import GeneralQuestionnairesParticipantData from '../GeneralQuestionnaires/GeneralQuestionnairesParticipantData';

const NutritionParticipantData = () => {
    return (
        <div>
            <GeneralQuestionnairesParticipantData categoriesQuestionnaireTypes={CategoryNutritionDataTypes}/>
        </div>
    )
}

export default NutritionParticipantData;