import React, {useEffect, useState, useCallback} from 'react';
import "survey-core/modern.min.css";
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { surveyMiniH} from '../../../../Language/En2';
import {fetchData, postData} from '../../../../Utilities/utils';


StylesManager.applyTheme("modern");

const MiniH = ({ participantID, nextSection }) => {

  const surveyfile = surveyMiniH;

  const [fetchCRFs, setCRFs] = useState(false);
  const [surveyAnwserData, setSurveyAnwser] = useState(false);
  const [surveyData, setSurvey] = useState(surveyfile);


  useEffect(() => {
     async function getDemographic(){
    if(!fetchCRFs){
      const fetchurl = "https://cfp2ku9nkh.execute-api.eu-west-1.amazonaws.com/crf/mini/h/"+participantID;
      const response = await fetchData(fetchurl)
      setCRFs(response);
    }
  }
  getDemographic();
  }, [fetchCRFs])

  useEffect(() => {
    setSurvey(...[]);
    console.log(surveyAnwserData)
    surveyfile.cookieName = '';
    surveyfile.pages[0].elements.map(
      (data,index) => {
        if(surveyAnwserData[data.name] !== undefined){
          data.defaultValue = surveyAnwserData[data.name]
          //console.log(data.name + ":"+ surveyAnwserData[data.name])
        }
        else
          data.defaultValue = '';
      }
    )
   setSurvey({
    ...surveyfile
   })
   console.log(surveyData)

  }, [surveyAnwserData])

 const survey = new Model(surveyData); 
  survey.focusFirstQuestionAutomatic = false;

  const alertResults = useCallback((sender) => {

    const url = "https://cfp2ku9nkh.execute-api.eu-west-1.amazonaws.com/crf/mini/h";

    const data = new Object();

      data.participantID = participantID;
      data.timestamp = Date.now();
      data.data = sender.data;

    const response = postData(url, data, 'POST');

    console.log("Resposta " + response);

    nextSection(9);

  }, []);

  survey.onComplete.add(alertResults);

  return (
    <div className='scroll' style={{height: '75vh',overflowY: 'scroll'}}>
      {
      !fetchCRFs || typeof fetchCRFs.Items === "undefined" ? 
        (<p>Loading</p>)
        : 
        (
          <div>
            {
              fetchCRFs.Items.length > 0 ?
              (<p>Previous answers:</p>):
              ("")
            }
            {fetchCRFs.Items.map((data,index) => {
                return(                          
                    <button className='grey-btn' key={index} onClick={() => setSurveyAnwser(data)}>{ new Date(data.timestamp).toLocaleDateString() } { new Date(data.timestamp).toLocaleTimeString() }</button>
                )
              })
            }
          </div>
        )
      }
      <h4 className='mt-3'>Posttraumatic Stress Disorder</h4>
      <p className='mt-1'>All the fields are mandatory.</p>

      <Survey model={survey} />
    </div>
  );
}

export default MiniH