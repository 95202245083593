import edit from '../../../Assets/Icons/pencil-edit.svg';
import close from '../../../Assets/Icons/close.svg';
import add_note from '../../../Assets/Icons/add-note-icon.svg';

const Notes = () => {
  return (
    <>
    <div className="Notes">
      
      <div style={{ textAlign: 'left', fontSize: '21px' }}>Notes </div>

      <div className="note">
        <div className="note_head">
          <p className="note_date"> 07 November 2023 - 21:45h</p>
          <div className="note_commands">
            <button>
              <img src={edit}/>
              <span>Edit</span>
            </button>
            <button>
              <img src={close}/>
              <span>Delete</span>
            </button>
          </div>
        </div>
        <p className="note_content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est odio consequat, id leo non integer id risus lectus. Ornare laoreet enim faucibus in id eleifend accumsan urna, tristique. consectetur adipiscing elit. Est odio consequat, id leo non integer id risus lectus. Ornare laoreet enim faucibus in id eleifend accumsan urna, tristique.</p>
      </div>
      
      <div className="note">
        <div className="note_head">
          <p className="note_date"> 25 November 2023 - 20:45h</p>
          <div className="note_commands">
            <button>
              <img src={edit}/>
              <span>Edit</span>
            </button>
            <button>
              <img src={close}/>
              <span>Delete</span>
            </button>
          </div>
        </div>
        <p className="note_content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est odio consequat, id leo non integer id risus lectus. Ornare laoreet enim faucibus in id eleifend accumsan urna, tristique. consectetur adipiscing elit. Est odio consequat, id leo non integer id risus lectus. Ornare laoreet enim faucibus in id eleifend accumsan urna, tristique.</p>
      </div>

      <div className='add_note'>
        <button>
              <img src={add_note}/>
              <span>Add new</span>
        </button>
      </div>
    </div>

    <style>{`
      .Notes{
        margin-top: 30px;
      }
      .note{
        margin-top: 30px;
      }
      .note_head{
        display: flex;
      }
      .note_date{
        display: flex;
        font-weight: bold;
      }
      .note_commands{
        margin-left: auto; 
        margin-right: 0;
      }
      .note_commands button{
        display: inline-flex;
        margin-left: 15px;
        background: none;
      }

      .note_commands button:hover {
        border-bottom-style: solid;
      }
      .note_commands img {
        height: 16px;
        filter: invert(54%) sepia(0%) saturate(0%) hue-rotate(13deg) brightness(94%) contrast(89%);
        margin-right: 1px;
        align-self: center;
      }


      .add_note{
        margin-top: 30px;
        display: flex;
      }
      .add_note button{
        margin-left: auto; 
        margin-right: 0;
        background: none;
        font-weight: bold;
      }
      .add_note button:hover {
        border-bottom-style: solid;
      }
      .add_note img {
        height: 25px;
        filter: invert(0%) sepia(0%) saturate(7465%) hue-rotate(241deg) brightness(0%) contrast(101%);
        align-self: center;
      }
    `}</style>
    </>
  )
}

export default Notes;