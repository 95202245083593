import { useParams }from 'react-router-dom';
import SleepInfo from './SleepInfo';
import { CategorySleepDataTypes } from '../../../Assets/enums';
import GeneralQuestionnairesParticipantData from '../GeneralQuestionnaires/GeneralQuestionnairesParticipantData';

const SleepParticipantData = () => {
    const { participantID } = useParams();

    return (
        <div>
            <GeneralQuestionnairesParticipantData categoriesQuestionnaireTypes={CategorySleepDataTypes}/>
            <SleepInfo participantID={participantID}/>
        </div>
    )
}

export default SleepParticipantData;